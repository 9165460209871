import React from 'react';
import PaymentTotal from '../../../components/molecules/payment_total';
import styled from 'styled-components';
import { PaymentType } from '../../../../data/entity/PaymentType';

export class OrderHistoryHeaderComponent extends React.Component<IProps, {}> {
  render() {
    let servicePrice = 0;
    this.props.feeAmounts.forEach(feeAmount => {
      servicePrice += feeAmount.amount;
    });
    const subTotal = this.props.paidAmount - this.props.taxAmount - servicePrice;
    const isPresentOnePersonPrice =
      this.props.paymentType === PaymentType.All && this.props.numPeople !== undefined;
    const onePersonPrice = Math.ceil(
      this.props.numPeople ? this.props.paidAmount / this.props.numPeople : 0,
    );
    return (
      <div>
        <TotalSection>
          <PaymentTotal
            isPresentOnePersonPrice={isPresentOnePersonPrice}
            width="100%"
            subtotal="小計"
            subtotalPrice={subTotal}
            service="サービス料"
            servicePrice={servicePrice}
            tax="消費税"
            taxPrice={this.props.taxAmount}
            total="合計"
            totalPrice={this.props.paidAmount}
            onePersonPrice={onePersonPrice}
          />
        </TotalSection>
      </div>
    );
  }
}

interface IProps {
  taxAmount: number;
  feeAmounts: { amount: number }[];
  paidAmount: number;
  numPeople?: number;
  paymentType?: string;
}

const TotalSection = styled.div``;
