import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { Routes } from './routes';

// NOTE: See thid document ☞ https://reacttraining.com/react-router/web/guides/scroll-restoration
const restoreScrollBlackListPaths = [Routes.home];
export const ScrollRestoration: React.FC<{}> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (restoreScrollBlackListPaths.includes(pathname as any)) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};
