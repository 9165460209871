/* eslint sort-keys-fix/sort-keys-fix: "error" */
import { MessageKey } from './types';

const ja_JP: Record<MessageKey, string> = {
  'self.AppContainer.RedirectError': 'リダイレクトエラー',
  'self.AppContainer.RedirectProcessingFailed': 'リダイレクト処理に失敗しました',
  'self.AppContainer.checkingUserInfo': 'ユーザ情報を確認しています',
  'self.accounting': '会計中...',
  'self.atoms.dropdown.selectPeople': '{name}名',
  'self.atoms.loginSmallText.agreement': 'に同意します',
  'self.atoms.loginSmallText.and': 'および',
  'self.atoms.loginSmallText.privacyPolicy': 'プライバシーポリシー',
  'self.atoms.loginSmallText.smallLoginText': 'ログインすることで',
  'self.atoms.loginSmallText.termsOfService': '利用規約',
  'self.atoms.myaccount_menu_element.contactUs': 'お問い合わせ',
  'self.atoms.myaccount_menu_element.logout': 'ログアウト',
  'self.atoms.myaccount_menu_element.privacyPolicy': 'プライバシーポリシー',
  'self.atoms.myaccount_menu_element.termsOfService': '利用規約',
  'self.auth_boundary': '認証エラーが発生しました',
  'self.auth_boundary.please_check_network':
    'ネットワーク状況をご確認いただき、QRコードを読み込み直してください。',
  'self.cardAuthentication': 'カード認証中...',
  'self.checkAfterOrder': '注文後に会計を行ってください',
  'self.checkingOrder': '注文を確認中...',
  'self.connecting': '通信中...',
  'self.home.alreadyDoneCashPayment': 'お会計済みです。ご利用ありがとうございました。',
  'self.home.cartSheet.cartTitle': '注文を送信しますか？',
  'self.infra.errors.stripeDeclineErrorMessage': '別のカードをご使用ください',
  'self.infra.errors.stripeDeclineErrorTitle': 'お客様のカードはご利用いただけません',
  'self.infra.errors.unexpectedErrorTitle': '予期せぬエラーが発生しました。',
  'self.infra.firebaseClient.authError': '認証エラー',
  'self.infra.firebaseClient.check': 'ご確認お願いします',
  'self.infra.firebaseClient.correctError': '正しいメールアドレスを入力してください',
  'self.infra.firebaseClient.login': 'ログインしてください',
  'self.infra.firebaseClient.mailFormatError': 'メールアドレスの形式が正しくありません',
  'self.infra.firebaseClient.passwordError': 'メールアドレスかパスワードが間違っています',
  'self.infra.firebaseClient.registeredError': '登録済みのアドレスです',
  'self.infra.firebaseClient.userInfoError': 'ユーザーの認証情報の取得に失敗しました',
  'self.loading': '読み込み中',
  'self.molecules.alertModal.ok': '確認',
  'self.molecules.card_info.buttonText.all': 'このカードで会計する',
  'self.molecules.card_info.buttonText.each': 'このカードで注文する',
  'self.molecules.card_info.cardNumber': 'カード番号',
  'self.molecules.card_info.description':
    'デビットカード追加時100円の決済確認が行われる場合があります。決済確認分は10分以内に返金されます。',
  'self.molecules.card_info.expirationDate': '有効期限',
  'self.molecules.card_info.securityCode': 'セキュリティコード',
  'self.molecules.confirmChoosePeopleModal.accountingWithCard': 'クレジットカードで会計をする',
  'self.molecules.confirmChoosePeopleModal.accountingWithCash': 'レジで会計する',
  'self.molecules.confirmChoosePeopleModal.description':
    '現金決済や、ポイントカード・商品券等をご利用の場合にはレジ会計を選択してください',
  'self.molecules.confirmChoosePeopleModal.inTheCase': 'の場合　￥',
  'self.molecules.confirmChoosePeopleModal.perPeople': '/人',
  'self.molecules.confirmChoosePeopleModal.remark': 'レジ会計のみキャッシュレス還元対応',
  'self.molecules.confirmChoosePeopleModal.topTitle': 'お会計をしますか？',
  'self.molecules.confirmModal.cancel': 'キャンセル',
  'self.molecules.confirmModal.yes': 'はい',
  'self.molecules.home_action_button.account': '会計',
  'self.molecules.home_action_button.callStaff': '呼出',
  'self.molecules.home_action_button.history': '履歴',
  'self.molecules.lastOrder': '{name} 残り {leftTimeInMinutes}分',
  'self.molecules.last_order.drink': 'ドリンク',
  'self.molecules.last_order.food': 'フード',
  'self.molecules.last_order.lastOrder': 'ラストオーダー',
  'self.molecules.mailLoginButtonSet.signinButton': 'ログイン',
  'self.molecules.mailLoginButtonSet.signupButton': '新規登録',
  'self.molecules.mailLoginButtonSet.title': 'メールアドレスで新規登録/ログイン',
  'self.molecules.mailLoginForm.buttonText': 'ログインして注文確定',
  'self.molecules.mailLoginForm.description': 'パスワードは6文字以上です',
  'self.molecules.mailLoginForm.noMailError': 'メールアドレスを入力してください',
  'self.molecules.mailLoginForm.nopassError': 'パスワードを入力してください',
  'self.molecules.mailSignupForm.bottomDescription': 'パスワードは6文字以上で設定してください',
  'self.molecules.mailSignupForm.leastPassError': 'パスワードは6字以上でを入力してください',
  'self.molecules.menuItem.soldOut': '【売切】',
  'self.molecules.myaccount_list.mailAdrress': 'メールアドレス',
  'self.molecules.myaccount_list.name': 'ユーザ名',
  'self.molecules.myaccount_list.phoneNumber': '電話番号',
  'self.molecules.myaccount_list.user': 'ユーザー',
  'self.molecules.numPeopleSelector.selectPeople': '{name}名',
  'self.molecules.numPeopleSelector.selectText': '人数を選択してください',
  'self.molecules.numPeopleSelector.updating': '人数を更新しています',
  'self.molecules.payment_total.perPerson': '(１人あたり￥{name})',
  'self.molecules.payment_total.service': 'サービス料',
  'self.molecules.payment_total.subTotal': '小計',
  'self.molecules.payment_total.tax': '消費税',
  'self.molecules.payment_total.total': '合計',
  'self.molecules.planItem.minutes': '{name}分間',
  'self.molecules.planItem.startPlan': '{name}をスタートする',
  'self.molecules.pleaseCallStaffModal.message': 'スタッフをお呼びください',
  'self.molecules.pleaseCallStaffModal.title': '会計は座席にて行います',
  'self.noOrder': '注文がありません',
  'self.noPlan': 'プランが選択されていません',
  'self.order.complete.navigateToOrderHistory': '注文履歴はこちら',
  'self.order.complete.navigateToReceipt': '領収書はこちら',
  'self.order.complete.thankText': 'ご利用ありがとうございます\nまたのお越しをお待ちしております',
  'self.ordering': '注文中...',
  'self.organisms.accountCompletePopup.completedAccounting': '会計完了',
  'self.organisms.accountMenu.other': 'その他',
  'self.organisms.accountUserInfo.userInfo': 'ユーザー情報',
  'self.organisms.addCard.checkCardInfo': 'カード情報を確認しています',
  'self.organisms.addCardFailed': 'カード登録に失敗しました',
  'self.organisms.addCardFailedText': '対応しているカードはVisa/MasterCard/American Expressです',
  'self.organisms.add_card.cardInfo': 'カード情報',
  'self.organisms.add_card.text': '登録したカードで以降のお会計はスムーズに♪',
  'self.organisms.appBar.FAQ': 'よくある質問',
  'self.organisms.appBar.aboutMobileOrder': 'モバイルオーダーって？',
  'self.organisms.appBar.addCard': 'カード追加',
  'self.organisms.appBar.checkIn': 'チェックイン',
  'self.organisms.appBar.error': 'エラー',
  'self.organisms.appBar.logout': 'ログアウト',
  'self.organisms.appBar.myAccount': 'マイアカウント',
  'self.organisms.callStaffCompletePopup.completedCallStaff': '店員呼び出し完了',
  'self.organisms.cardPayment.changeCard': 'カード変更はこちら',
  'self.organisms.cardPayment.enterCard': 'カード情報を入力してください',
  'self.organisms.cardPayment.useThisCard': 'このカードを使用する',
  'self.organisms.cardRegisterCompletePopup.completedCardRegister': 'カード登録完了',
  'self.organisms.cartButton.buttonText': '注文を確認',
  'self.organisms.cartConfirm.updateCart': 'カートを更新しています',
  'self.organisms.cashPaymentCompleteModal.cashPaymentCompleted':
    '伝票をレジまでお持ちください\nまたのご来店をお待ちしております',
  'self.organisms.chooseNumPeopleModal.enter': 'チェックインする',
  'self.organisms.chooseNumPeopleModal.enterDescription':
    'チェックインすることで注文することができます',
  'self.organisms.chooseNumPeopleModal.howMany': '何名さまでお越しでしょうか？',
  'self.organisms.confirmCallStaffModal.callStaff': '店員呼び出し',
  'self.organisms.confirmCallStaffModal.message': '店員を呼び出しますか？',
  'self.organisms.confirmCheckInModal.message': 'チェックインするとメニューを注文できます',
  'self.organisms.confirmCheckInModal.title': 'チェックインします',
  'self.organisms.confirmPaymentModal.message': 'お会計を行いますか？',
  'self.organisms.confirmPaymentModal.payment': 'お会計',
  'self.organisms.homePaymentSection.titleDescription': 'レジにて、この画面をスタッフに',
  'self.organisms.homePaymentSection.titleText.ja': 'お見せください',
  'self.organisms.homeTopSection.checkoutMessage': 'ご注文ありがとうございました',
  'self.organisms.homeTopSection.checkoutTitle': 'お会計済みです',
  'self.organisms.homeTopSection.noOrder': '注文がありません',
  'self.organisms.homeTopSection.signinBeforeCheckMessage': '会計前にログインを行ってください',
  'self.organisms.homeTopSection.signinBeforeCheckTitle': 'ログインを行ってください',
  'self.organisms.logoutConfirm.cancelButton': 'キャンセル',
  'self.organisms.logoutConfirm.description':
    '次回以降のスムーズなお食事のためにログアウトされないことをおすすめします',
  'self.organisms.logoutConfirm.firstCheck': '次回利用時、再度ログインが必要になります',
  'self.organisms.logoutConfirm.logoutButton': 'ログアウトする',
  'self.organisms.logoutConfirm.secondCheck': 'カード情報が初期化され、再度登録が必要になります',
  'self.organisms.logoutConfirm.topTitle': '本当にログアウトしますか？',
  'self.organisms.mailSignUp.createUser': 'ユーザーを作成しています',
  'self.organisms.mailSignin.title': 'メールアドレスでログイン',
  'self.organisms.mailSignup.here': 'こちら',
  'self.organisms.mailSignup.loginText': 'ログインは',
  'self.organisms.mailSignup.title': 'メールアドレスで新規登録',
  'self.organisms.mailSignupForm.buttonText': '登録して注文確定',
  'self.organisms.menuDetail.addCart': '追加する',
  'self.organisms.menuDetail.remark': '備考',
  'self.organisms.menuDetail.requiredLabel': '(必須)',
  'self.organisms.orderCompletePopup.completedOrder': '注文完了',
  'self.organisms.paymentComleted.paidAmountText': 'ご注文金額',
  'self.organisms.paymentComleted.paymentMethod': 'お支払い方法',
  'self.organisms.paymentComleted.receiptAddress': '領収書の送付先',
  'self.organisms.paymentComleted.sendToThisEmailAddress': 'このメールアドレスに送る',
  'self.organisms.planDetail.availableTime': '注文可能時間',
  'self.organisms.planDetail.description':
    ' こちらの商品をご注文後、{name}のメニューを選択できるようになります',
  'self.organisms.planDetail.menuContentLabel': 'メニュー内容',
  'self.organisms.planDetail.modalMessage': 'プランをスタートしますか？',
  'self.organisms.planDetail.modalTitle': 'プラン',
  'self.organisms.planDetail.nonTaxable': '(非課税)',
  'self.organisms.planDetail.people': '{name}名分注文されます',
  'self.organisms.planDetail.pleaseSelectAtLeastNItem': '{minChoiceNum}種類まで選択いただけます',
  'self.organisms.planDetail.pleaseSelectAtMostNItem':
    '選択肢を{maxChoiceNum}種類まで減らしてください',
  'self.organisms.planDetail.startText': 'スタート',
  'self.organisms.planDetail.withoutTax': '(税別)',
  'self.organisms.receiptEmailRegisterCompletePopup': '後ほど領収書をお送りいたします',
  'self.organisms.signIn.cantUseSMS': 'SMSが使えない方は',
  'self.organisms.signIn.checkPhoneNumber': '電話番号を確認しています',
  'self.organisms.signIn.clickHere': 'こちら',
  'self.organisms.signInCompletePopup.completedLogin': 'ログイン完了',
  'self.organisms.signOutCompletePopup.completedLogout': 'ログアウト完了',
  'self.pages.aboutMo.boldText': '「スタッフを呼び、注文を伝える」',
  'self.pages.aboutMo.credit': 'クレジット決済',
  'self.pages.aboutMo.lastText': 'モバイルオーダーでスムーズなお食事を。',
  'self.pages.aboutMo.leftText': 'そのまま',
  'self.pages.aboutMo.passText': '面倒な会計もパスできます。',
  'self.pages.aboutMo.rightText': 'もできるため',
  'self.pages.aboutMo.secondText': 'という行為を、',
  'self.pages.aboutMo.serviceText': 'で済ませることができるサービスです',
  'self.pages.aboutMo.smartPhone': 'スマホ一台',
  'self.pages.aboutMo.titleText': 'あなたのスマホが注文端末になる！',
  'self.pages.aboutMo.topText': 'モバイルオーダーとは、従来の',
  'self.pages.add_card_page.cardInfo': 'カード情報',
  'self.pages.add_card_page.changeCard': 'カードの変更はこちら',
  'self.pages.add_card_page.registeredCardInformation': '登録中のカード情報',
  'self.pages.cartConfirm.orderAdvice': '{leftMinutes}分後に次の注文を行えます',
  'self.pages.cartConfirm.orderGuide': '次のご注文は{orderInterval}分後になります',
  'self.pages.checkIn.again': 'もう一度チェックインする',
  'self.pages.checkIn.close': '閉じる',
  'self.pages.default.authenticating': '認証情報を確認しています…',
  'self.pages.drawer.FAQ': 'よくある質問',
  'self.pages.drawer.account': '会計',
  'self.pages.drawer.addCard': 'クレジットカード登録',
  'self.pages.drawer.callStaff': '店員呼出',
  'self.pages.drawer.mobileOrderDescription': 'モバイルオーダーって？',
  'self.pages.drawer.myaccount': 'マイアカウント',
  'self.pages.drawer.orderHistory': '注文履歴',
  'self.pages.drawer.receipt': '領収書',
  'self.pages.errorPage.description': '電波状況を確認してQRコードを読み込み直してください',
  'self.pages.errorPage.linkError': 'ページが見つかりません',
  'self.pages.errorPage.notOpenError': '現在ご利用時間外です',
  'self.pages.errorPage.unknownError': '不具合が発生しました',
  'self.pages.faq.fifthAnswer': '呼び出しボタンを押して、店員にその旨をお伝え下さい',
  'self.pages.faq.fifthQuestion':
    '間違えてオーダーしてしまったのですが、どうすればいいですか?',
  'self.pages.faq.firstAnswer':
    ' 前回と異なるログイン方法だとログインが上手くできない場合がございます。それぞれのログイン方法をもう一度お試しください。',
  'self.pages.faq.firstQuestion': 'ログインができません',
  'self.pages.faq.fourthAnswer':
    '一度マイページからログアウト後、前回と同様の方法でログインし、登録情報をご確認ください。',
  'self.pages.faq.fourthQuestion':
    '前回クレジットカードを登録したはずなのに、今回ログインしたらカード情報が消えています。大丈夫なのですか?',
  'self.pages.faq.secondAnswer':
    'ログイン画面に「パスワード再設定」のボタンがあるのでこちらをタップし、パスワードを再設定してください。',
  'self.pages.faq.secondQuestion':
    '前回登録したパスワードを忘れてしまったのですが、どうすればいいですか?',
  'self.pages.faq.sixthAnswer':
    '同じQRコードから注文した場合、最後に会計ボタンを押した人がそれぞれの注文をまとめて決済します。お会計を割り勘したい場合は、レジ会計を選択すると便利です。',
  'self.pages.faq.sixthQuestion':
    '2人で同じQRから注文した場合はどのように支払いがされるのですか?',
  'self.pages.faq.thirdAnswer':
    'クレジットカード以外での支払いは、直接レジにて、ご注文・会計をお願いいたします。',
  'self.pages.faq.thirdQuestion': 'クレジットカード以外の支払い方法はありませんか?',
  'self.pages.faq.topFourthAnswer': '前回とログイン方法が異なっている可能性があります。',
  'self.pages.faq.topThirdAnswer': '現状、クレジットカード以外での支払いはできません。',
  'self.pages.history.numberOfPeople': '人数',
  'self.pages.home.cartSnackbar.added': '{name}を追加しました',
  'self.pages.home.close': '閉じる',
  'self.pages.home.confirmCart': '注文内容を確認',
  'self.pages.home.confirmCartCount': '点',
  'self.pages.home.order': '注文する',
  'self.pages.home.orderSnackbar.ordered': '{name}を注文しました',
  'self.pages.launchScreen.checkIn': 'チェックイン中',
  'self.pages.order_history_page.backToHome': 'ホームに戻る',
  'self.pages.order_history_page.changeTheNumberOfpeople': '人数を変更する',
  'self.pages.search.app_bar.search': '商品名で検索',
  'self.pages.search.menu.noResultsFound': '検索結果がありません',
  'self.pages.signIn.signIn': 'ログイン',
  'self.pages.signUp.alreadySignInText': '登録済みの方はこちら♪',
  'self.pages.signUp.liffSignIn': 'LINEでログイン',
  'self.pages.signUp.passwordLimitation': 'パスワードは最低6文字以上で設定してください',
  'self.pages.signUp.signIn': 'ログイン画面へ',
  'self.pages.signUp.signUp': '新規登録',
  'self.paymentProcessing': '決済中...',
  'self.receipt.download': '領収書をダウンロード',
  'self.receipt.none': 'まだ領収書がございません',
  'self.receipt.rawPriceLabel': '税抜金額',
  'self.receipt.subTitle': '内訳',
  'self.receipt.taxPriceLabel': '消費税額',
  'self.receipt.title': '領収書',
  'self.registeringCard': 'カード登録中',
  'self.reorder': '再注文',
  'self.signIn.title': 'ログイン',
  'self.signUp.title': '新規ユーザ登録',
  'self.updating': '更新中...',
  'self.word.soldout': '売切',
};

export default ja_JP;
