import { injector } from '../../../data/injector';
import { handleError } from '../../../errors';
import * as H from 'history';
import React, { Dispatch } from 'react';
import { MailSignIn } from './component';
import { useHistory, useLocation } from 'react-router';
import { Action } from '../../../store/action';
import { useDispatch } from 'react-redux';
import { usersRef } from '../../../libs/firebaseApp';
import { Profile } from '../../../data/entity/Profile';
import { Routes } from '../../routes';

const updateProfile = async (profile: Profile) => {
  await usersRef(profile.userId).set(profile, {
    merge: true,
  });
};

const mailSignIn = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  email: string,
  password: string,
  search: string,
) => {
  try {
    const user = await injector.firebaseClient.signInWithEmail(email, password);

    if (user) {
      await updateProfile({ userId: user.uid });
      history.replace(`${Routes.checkIn}${search}`);
    }
  } catch (e) {
    await handleError(e, dispatch, history);
  }
};

export const MailSignInPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <MailSignIn
      mailSignIn={(email, password) =>
        mailSignIn(dispatch, history, email, password, location.search)
      }
    />
  );
};
