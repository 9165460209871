import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrders, selectShop, selectTableUser } from '../../store/selecter';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';

// NOTE: 注文時間制限実装方針 https://dinii.kibe.la/notes/2081
export const useCanOrder = () => {
  const [currentTime, setCurrentTime] = useState(moment());
  const { formatMessage: t } = useIntl();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, []);

  const orderInterval = useSelector(selectShop).orderIntervalSeconds ?? 0;
  const activatedAt = useSelector(selectTableUser).activatedAt ?? 0;
  const lastOrderedAt = Math.max(0, ...useSelector(selectOrders).map(order => order.orderedAt));
  // NOTE: お通しを考慮して、チェックインから10秒以内の注文は考慮しない
  const lastOrderIsAppetizer = lastOrderedAt - activatedAt < 10;
  const nextOrderableTime = lastOrderIsAppetizer
    ? currentTime
    : moment(lastOrderedAt * 1000).add(orderInterval, 's');
  const leftMinutes = nextOrderableTime.diff(currentTime, 'minute') + 1;
  const canOrder = currentTime.isSameOrAfter(nextOrderableTime);

  const alertMessage =
    orderInterval === 0 || canOrder
      ? undefined
      : t({ id: 'self.pages.cartConfirm.orderAdvice' }, { leftMinutes });

  const guideMessage =
    orderInterval === 0 || canOrder
      ? undefined
      : t(
          { id: 'self.pages.cartConfirm.orderGuide' },
          { orderInterval: Math.ceil(orderInterval / 60) },
        );

  return {
    canOrder,
    leftMinutes,
    alertMessage,
    guideMessage,
  };
};
