import React, { useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import theme from '../../theme';

type SnackbarContext = {
  render: (children: React.ReactNode, key?: null | string) => React.ReactPortal | null;
  snackbars: string[];
  createSnackbar: (id: string) => string | null;
  deleteSnackbar: (id: string) => void;
};

const SnackbarContext = React.createContext<SnackbarContext>({
  render: () => null,
  snackbars: [],
  createSnackbar: () => null,
  deleteSnackbar: () => {},
});

export const useSnackbar = () => React.useContext(SnackbarContext);

type Snackbars<T> = [string, T][];

export const useSnackbars = <T extends any>() => {
  const { createSnackbar: create } = React.useContext(SnackbarContext);

  const [snackbars, setSnackbars] = useState<Snackbars<T>>([]);
  const createSnackbar = useCallback(
    (id: string, props: T) => {
      if (create(id)) {
        setSnackbars([...snackbars, [id, props]]);
        return id;
      }
      return null;
    },
    [create, setSnackbars, snackbars],
  );
  const deleteSnackbar = useCallback(
    (id: string) => {
      if (snackbars.map(([id]) => id).includes(id)) {
        setSnackbars(snackbars.filter(snackbar => snackbar[0] !== id));
      }
    },
    [snackbars, setSnackbars],
  );

  return { snackbars, createSnackbar, deleteSnackbar };
};

const useQueue = () => {
  const [queue, setQueue] = useState<string[]>([]);

  const enqueue = useCallback(
    (id: string) => {
      setQueue([...queue, id]);
      return id;
    },
    [queue, setQueue],
  );
  const dequeue = useCallback(() => {
    const [id, ...newQueue] = queue;
    setQueue(newQueue);
    return id;
  }, [queue, setQueue]);

  return { queue, enqueue, dequeue };
};

type Props = {
  className?: string;
  anchorOrigin: {
    horizontal: 'left' | 'right';
    vertical: 'top' | 'bottom';
  };
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  maxStack?: number;
  children?: React.ReactNode;
};

const Snackbar: React.FC<Props> = props => {
  const { className, anchorOrigin, top, right, bottom, left, maxStack, children } = props;
  const { vertical } = anchorOrigin;
  const reverseColumns = vertical === 'bottom';

  const snackbarRef = useRef<HTMLDivElement>(null);
  const render = useCallback(
    (children: React.ReactNode, key?: null | string) =>
      snackbarRef.current ? ReactDOM.createPortal(children, snackbarRef.current, key) : null,
    [],
  );

  const { queue, enqueue, dequeue } = useQueue();
  const [snackbars, setSnackbars] = useState<string[]>([]);
  const [ignored] = useState<string[]>([]);
  const pushSnackbar = useCallback((id: string) => setSnackbars([...snackbars, id]), [
    snackbars,
    setSnackbars,
  ]);
  const createSnackbar = useCallback(
    (id: string) => {
      if ([...ignored, ...snackbars, ...queue].includes(id)) {
        return null;
      }
      if (maxStack === undefined || snackbars.length < maxStack) {
        pushSnackbar(id);
      } else {
        enqueue(id);
      }
      return id;
    },
    [ignored, snackbars, queue, maxStack, pushSnackbar, enqueue],
  );
  const deleteSnackbar = useCallback(
    (id: string) => {
      if (snackbars.includes(id)) {
        setSnackbars([...snackbars.filter(snackbar => snackbar !== id), dequeue()].filter(Boolean));
      }
    },
    [snackbars, setSnackbars, dequeue],
  );

  return (
    <SnackbarContext.Provider value={{ render, snackbars, createSnackbar, deleteSnackbar }}>
      {children}
      <StyledSnackbar
        ref={snackbarRef}
        className={className}
        top={top}
        right={right}
        bottom={bottom}
        left={left}
        reverseColumns={reverseColumns}
      />
    </SnackbarContext.Provider>
  );
};

type StyledSnackbarProps = {
  reverseColumns: boolean;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

const StyledSnackbar = styled.div<StyledSnackbarProps>`
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  flex-direction: column;
  z-index: ${theme.zIndex.snackbar};
  height: auto;
  width: auto;
  transition: ${theme.transitions.create(['top', 'right', 'bottom', 'left'], { easing: 'ease' })};
  ${({ top }) =>
    top !== undefined &&
    css`
      top: ${top}px;
    `}
  ${({ bottom }) =>
    bottom !== undefined &&
    css`
      bottom: ${bottom}px;
    `}
  ${({ left }) =>
    left !== undefined &&
    css`
      left: ${left}px;
    `}
  ${({ right }) =>
    right !== undefined &&
    css`
      right: ${right}px;
    `}
  ${({ reverseColumns }) =>
    reverseColumns &&
    css`
      flex-direction: column-reverse;
    `};
`;

export default React.memo(Snackbar);
