import { useMemo, useState } from 'react';
import { StartPlanRequest } from '../../data/api/request/StartPlanRequest';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectCategoryPlans } from '../../store/selecter';

export const useEditPlan = (planId: number, planCategoryId: number) => {
  const history = useHistory();
  const categoryPlans = useSelector(selectCategoryPlans);

  const plan = useMemo(() => {
    if (!planCategoryId && !planId) {
      history.push('/error/no_menu');
      return undefined;
    }
    const targetPlanCategories = categoryPlans.filter(
      p => p.planCategoryId === Number(planCategoryId),
    );
    if (targetPlanCategories.length > 0) {
      const targetPlans = targetPlanCategories[0].plans.filter(p => p.planId === Number(planId));
      if (targetPlans.length > 0) {
        return targetPlans[0];
      }
    }
    alert('プランが存在しない、もしくは選択できなくなりました');
    history.goBack();
    return undefined;
  }, [categoryPlans, planCategoryId, planId, history]);

  const [planChoices, setPlanChoices] = useState<StartPlanRequest | undefined>(
    plan
      ? {
          planId: plan.planId,
          options:
            plan.options && plan.options.length > 0
              ? plan.options.map(o => ({
                  optionId: o.optionId,
                  choices: o.choices.map(c => ({ choiceId: c.choiceId, quantity: 0 })),
                }))
              : [],
        }
      : undefined,
  );

  const price = useMemo(() => {
    let ret = 0;
    planChoices?.options?.forEach((o, optionIndex) => {
      o.choices.forEach((c, choiceIndex) => {
        ret += c.quantity * (plan?.options[optionIndex]?.choices[choiceIndex]?.price || 0);
      });
    });
    return ret;
  }, [plan, planChoices]);

  const disabled = useMemo(() => {
    let ret = true;
    planChoices?.options?.forEach(o => {
      o.choices.forEach(c => {
        if (c.quantity > 0) {
          ret = false;
        }
      });
    });
    return ret;
  }, [planChoices]);

  const decrementOptionQuantity = (optionIndex: number, choiceIndex: number) =>
    planChoices &&
    setPlanChoices({
      planId: planChoices.planId,
      options: planChoices.options.map((o, i) =>
        i === optionIndex
          ? {
              optionId: o.optionId,
              choices: o.choices.map((c, i) =>
                i === choiceIndex
                  ? {
                      choiceId: c.choiceId,
                      quantity: Math.max(c.quantity - 1, 0),
                    }
                  : c,
              ),
            }
          : o,
      ),
    });

  const incrementOptionQuantity = (optionIndex: number, choiceIndex: number) =>
    planChoices &&
    setPlanChoices({
      planId: planChoices.planId,
      options: planChoices.options.map((o, i) =>
        i === optionIndex
          ? {
              optionId: o.optionId,
              choices: o.choices.map((c, i) =>
                i === choiceIndex
                  ? {
                      choiceId: c.choiceId,
                      quantity: c.quantity + 1,
                    }
                  : c,
              ),
            }
          : o,
      ),
    });

  return {
    plan,
    price,
    disabled,
    startPlanRequest: planChoices,
    incrementOptionQuantity,
    decrementOptionQuantity,
  };
};
