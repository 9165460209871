import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectLocale, selectOrders } from '../../../../store/selecter';
import { HomeSnackbarComponent, Snackbar } from '../home_snackbar/component';

const OrderSnackbar: React.FC = () => {
  const locale = useSelector(selectLocale);
  const orders = useSelector(selectOrders);
  const { formatMessage: f } = useIntl();

  const items: Snackbar[] = useMemo(
    () =>
      orders.map(item => ({
        id: item.orderId,
        user: {
          id: item.userId,
          image: item.userProfileImage,
        },
        message: f(
          { id: 'self.pages.home.orderSnackbar.ordered' },
          { name: item.meta?.i18n[locale].name ?? item.name ?? '' }, // NOTE: Orderにはmetaが存在しない場合がある
        ),
        createdAt: item.orderedAt,
      })),
    [f, locale, orders],
  );

  return <HomeSnackbarComponent items={items} />;
};

export default OrderSnackbar;
