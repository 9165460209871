import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from '../../../../store/app/state';
import CompletePopup from '../complete_popup/container';

const SignOutCompletePopup: React.FC = () => (
  <CompletePopup
    name={Popup.SignOutComplete}
    text={<FormattedMessage id="self.organisms.signOutCompletePopup.completedLogout" />}
  />
);

export default SignOutCompletePopup;
