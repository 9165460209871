import { AccountUserInfoComponent } from './component';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import State from '../../../../store/state';
import { injector } from '../../../../data/injector';

const AccountUserInfo: React.FC = () => {
  const profile = useSelector((state: State) => state.userState.profile);

  const user = injector.firebaseClient.getUser();
  const firebaseUser = useMemo(() => user?.phoneNumber || user?.displayName, [user]);

  return <AccountUserInfoComponent profile={profile} firebaseUser={firebaseUser} />;
};
export default AccountUserInfo;
