import React from 'react';
import AccountUserInfo from './account_user_info/container';
import AccountMenu from './account_menu/component';
import { Page } from '../Page';

export default class MyAccount extends React.Component {
  render() {
    return (
      <Page>
        <div style={{ paddingBottom: '100px' }}>
          <AccountUserInfo />
          <AccountMenu />
        </div>
      </Page>
    );
  }
}
