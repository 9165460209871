import React, { useEffect } from 'react';
import { ZIndexSettings } from '../../../config/ZIndexSettings';
import styled from 'styled-components';
import { Fade } from '@material-ui/core';
import { Popup as PopupType } from '../../../../store/app/state';
import { selectPopup } from '../../../../store/selecter';
import { useDispatch, useSelector } from 'react-redux';
import { updatePopupAction } from '../../../../store/app/actions';

const Popup: React.FC<IProps> = props => {
  const popup = useSelector(selectPopup);
  const dispatch = useDispatch();

  const isShow = popup === props.name;

  useEffect(() => {
    if (isShow) {
      setTimeout(() => dispatch(updatePopupAction(PopupType.None)), 1500);
    }
  }, [dispatch, isShow]);

  return (
    <Fade in={isShow} unmountOnExit={true} mountOnEnter={true}>
      <ContentWrapper
        style={{
          zIndex: ZIndexSettings.POP_UP,
        }}
      >
        {props.children}
      </ContentWrapper>
    </Fade>
  );
};

interface IProps {
  name: PopupType;
}

const ContentWrapper = styled.div`
  position: fixed;
  z-index: ${ZIndexSettings.LOADER};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default Popup;
