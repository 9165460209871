import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    textField: {
      padding: '8',
      margin: ' 8px 0',
      width: '100%',
    },
    resizeFont: {
      fontSize: '16px',
    },
    loginButton: {
      width: '100%',
      margin: '30px auto 0',
    },
  }),
);

interface Props {
  mailSignIn: (email: string, password: string) => void;
}

export const MailSignIn: React.FC<Props> = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isInputComplete, setIsInputComplete] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // firebase auth側でvalidateされるので最小限の判定にとどめる
  const validateEmail = (email: string) => {
    const re = /^.+@.+\..+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setIsInputComplete(!(validateEmail(email) && password.length > 5));
  }, [email, password]);

  return (
    <Wrapper>
      <Typography variant={'h1'} style={{ margin: '20px' }}>
        <FormattedMessage id={'self.signIn.title'} />
      </Typography>
      <InputSection>
        <TextField
          type="email"
          name="email"
          label="メールアドレス/Email"
          variant="outlined"
          className={classes.textField}
          InputProps={{
            classes: {
              input: classes.resizeFont,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.resizeFont,
              focused: classes.resizeFont,
            },
          }}
          onChange={handleEmailChange}
        />
        <TextField
          type="password"
          name="password"
          label="パスワード/Password"
          variant="outlined"
          className={classes.textField}
          InputProps={{
            classes: {
              input: classes.resizeFont,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.resizeFont,
              focused: classes.resizeFont,
            },
          }}
          onChange={handlePasswordChange}
        />
      </InputSection>
      <ButtonSection>
        <Button
          name="sign-in"
          disabled={isInputComplete}
          onClick={() => props.mailSignIn(email, password)}
          color={'primary'}
          variant={'contained'}
          className={classes.loginButton}
        >
          <FormattedMessage id={'self.pages.signIn.signIn'} />
        </Button>
      </ButtonSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonSection = styled.div`
  width: 65%;
`;

const InputSection = styled.div`
  width: 65%;
`;
