import React from 'react';

import { ErrorModal } from '../error_modal/container';
// Modals
import ConfirmCallStaffModal from '../confirm_call_staff_modal/container';
import ConfirmPaymentModal from '../confirm_payment_modal/container';
import ConfirmCheckInModal from '../confirm_check_in_modal/container';
// Popups
import SignInCompletePopup from '../sign_in_complete_popup/component';
import SignOutCompletePopup from '../sign_out_complete_popup/component';
import AccountCompletePopup from '../account_complete_popup/component';
import CallStaffCompletePopup from '../call_staff_complete_popup/component';
import CardRegisterCompletePopup from '../card_register_complete_popup/component';
import OrderCompletePopup from '../order_complete_popup/component';
import ReceiptEmailRegisterCompletePopup from '../receipt_email_register_complete_popup/component';
import PleaseCallStaffPopup from '../please_call_staff_modal/component';

const ModalManager: React.FC = () => {
  return (
    <>
      <ErrorModal />
      {/* Modals */}
      <ConfirmCallStaffModal />
      <ConfirmPaymentModal />
      <ConfirmCheckInModal />
      {/* Popups */}
      <AccountCompletePopup />
      <CallStaffCompletePopup />
      <CardRegisterCompletePopup />
      <OrderCompletePopup />
      <ReceiptEmailRegisterCompletePopup />
      <SignInCompletePopup />
      <SignOutCompletePopup />
      <PleaseCallStaffPopup />
    </>
  );
};

export default ModalManager;
