import React from 'react';
import styled from 'styled-components';
import { Plan } from '../../../data/entity/menu';
import { ZIndexSettings } from '../../config/ZIndexSettings';
import CircleIcon from '@material-ui/icons/AccessTimeOutlined';
import {
  Button,
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  fade,
  makeStyles,
  Theme,
  Typography,
  Chip,
} from '@material-ui/core';
import NoImg from '../../../assets/images/no_image.svg';
import plusButton from '../../../assets/images/plusButton.svg';
import minusButton from '../../../assets/images/minusButton.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfirmStartPlanModal } from '../../components/organisms/confirm_start_plan_modal/container';
import { Page } from '../Page';
import { StartPlanRequest } from '../../../data/api/request/StartPlanRequest';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffSharpIcon from '@material-ui/icons/Close';
import { TaxMethod } from '../../../data/entity/Shop';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'; // NOTE: 左上の戻るボタン

interface Props {
  plan: Plan;
  price: number;
  disabled: boolean;
  planChoices: StartPlanRequest;
  incrementOptionQuantity: (optionIndex: number, choiceIndex: number) => void;
  decrementOptionQuantity: (optionIndex: number, choiceIndex: number) => void;
  onClickStartPlan: () => void;
  onClickBack: () => void;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      backgroundColor: fade('#fff', 0.7),
      borderRadius: '16px',
      margin: '16px',
      color: '#333',
      zIndex: 10000,
      top: 0,
      right: 0,
    },
    backIcon: {
      position: 'absolute',
      backgroundColor: fade('#fff', 0.7),
      borderRadius: '16px',
      margin: '16px',
      color: '#333',
      zIndex: 10000,
      top: 0,
      left: 0,
    },
    choiceChip: {
      fontSize: '1.6rem',
      borderRadius: 4,
      padding: '12px 18px',
      marginRight: 8,
      marginBottom: 8,
    },
  }),
);

export const PlanDetailComponent: React.FC<Props> = (props: Props) => {
  const { formatMessage: t } = useIntl();
  const classes = useStyles();
  let validSelection = true;

  return (
    <Page>
      <HighlightOffSharpIcon
        onClick={props.onClickBack}
        fontSize={'large'}
        className={classes.closeButton}
      />
      {/*// NOTE: 左上に戻るボタン*/}
      {/*<ArrowBackIcon onClick={props.onClickBack} fontSize={'large'} className={classes.backIcon} />*/}
      <ConfirmStartPlanModal startPlanRequest={props.planChoices} planId={props.plan.planId} />
      <Root>
        <ImageWrapper src={props.plan.imageUrl || NoImg} />

        <TopContent>
          <Title>
            <Typography variant={'h1'}>{props.plan.name}</Typography>{' '}
          </Title>
        </TopContent>

        {props.plan.planTime && (
          <TimeContent>
            <Typography variant="body1">
              <FormattedMessage id="self.organisms.planDetail.availableTime" />
            </Typography>
            <CircleIcon style={{ fontSize: '16px', margin: '2.5px 4px 0' }} />
            <Typography variant={'body1'}>
              <FormattedMessage
                id={'self.molecules.planItem.minutes'}
                values={{ name: props.plan.planTime }}
              />
            </Typography>
          </TimeContent>
        )}

        <PlanDescription>
          <Typography variant={'body2'}>
            {props.plan.description ? (
              props.plan.description
            ) : (
              <FormattedMessage
                id={'self.organisms.planDetail.description'}
                values={{ name: props.plan.name }}
              />
            )}
          </Typography>
        </PlanDescription>

        <PlanMenus>
          <ExpansionPanel
            style={{ backgroundColor: 'transparent', boxShadow: 'none', margin: 0, padding: 0 }}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h5'}>
                <FormattedMessage id="self.organisms.planDetail.menuContentLabel" />
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography variant={'h5'}>{props.plan.planMenusText}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </PlanMenus>

        {props.plan.options.map((o, optionIndex) => {
          // NOTE: Whether if the option does not have the quantity restriction
          const isSwitch = o.maxChoiceNum < o.choices.length;

          const choiceList = o.choices.map((c, choiceIndex) => {
            if (isSwitch) {
              const selected =
                props.planChoices.options[optionIndex].choices[choiceIndex].quantity !== 0;

              return (
                <Chip
                  key={c.choiceId}
                  classes={{ colorPrimary: '' }}
                  className={classes.choiceChip}
                  label={`${c.name}${c.price ? ` ¥${c.price}` : ''}${
                    c.isSoldout ? ` （${t({ id: 'self.word.soldout' })}）` : ''
                  }`}
                  clickable
                  color={selected ? 'primary' : 'default'}
                  onClick={() => {
                    if (c.isSoldout) return;

                    if (selected) {
                      props.decrementOptionQuantity(optionIndex, choiceIndex);
                    } else {
                      props.incrementOptionQuantity(optionIndex, choiceIndex);
                    }
                  }}
                />
              );
            }

            return (
              <ChoiceWrapper key={c.choiceId}>
                <ChoiceContentWrapper>
                  <ChoiceTitle>
                    <Typography variant={'h4'}>{c.name}</Typography>
                  </ChoiceTitle>
                  <Spacer />
                  <ChoicePrice>
                    <Typography variant={'h4'}>{`￥${c.price}`}</Typography>
                  </ChoicePrice>
                  {c.isSoldout ? (
                    <Typography variant="body2" style={{ marginLeft: 16 }}>
                      <FormattedMessage id="self.word.soldout" />
                    </Typography>
                  ) : (
                    <QuantityForm>
                      <QuantityButton
                        src={minusButton}
                        onClick={() => {
                          if (c.isSoldout) return;

                          props.decrementOptionQuantity(optionIndex, choiceIndex);
                        }}
                      />
                      {props.planChoices.options[optionIndex].choices[choiceIndex].quantity}
                      <QuantityButton
                        src={plusButton}
                        onClick={() => {
                          if (c.isSoldout) return;

                          props.incrementOptionQuantity(optionIndex, choiceIndex);
                        }}
                      />
                    </QuantityForm>
                  )}
                </ChoiceContentWrapper>
                <ChoiceDescription>{c.description}</ChoiceDescription>
              </ChoiceWrapper>
            );
          });

          const choiceValidationResult = (() => {
            const selectedChoices = props.planChoices.options[optionIndex].choices.filter(
              c => c.quantity > 0,
            ).length;

            if (o.maxChoiceNum < selectedChoices)
              return 'self.organisms.planDetail.pleaseSelectAtMostNItem';
            if (o.minChoiceNum > selectedChoices)
              return 'self.organisms.planDetail.pleaseSelectAtLeastNItem';

            return '';
          })();

          if (choiceValidationResult) {
            validSelection = false;
          }

          return (
            <OptionSection key={o.optionId}>
              <OptionTitle>
                <Typography variant={'h5'}>{o.name}</Typography>
              </OptionTitle>
              <OptionContent>
                {choiceList}
                {choiceValidationResult ? (
                  <Typography variant="body1">
                    <FormattedMessage
                      id={choiceValidationResult}
                      values={{ maxChoiceNum: o.maxChoiceNum, minChoiceNum: o.minChoiceNum }}
                    />
                  </Typography>
                ) : null}
              </OptionContent>
            </OptionSection>
          );
        })}
      </Root>

      <BottomWrapper>
        <ButtonWrapper>
          <Button
            disabled={props.disabled || Boolean(!validSelection)}
            onClick={props.onClickStartPlan}
            color={'primary'}
            variant={'contained'}
            size={'small'}
            style={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography variant={'h3'}>
              <FormattedMessage id="self.organisms.planDetail.startText" />
            </Typography>
            <div style={{ position: 'absolute', right: '16px' }}>
              <span style={{ fontSize: '16px', letterSpacing: '5%' }}>¥{props.price}</span>
              {props.plan.taxMethod === TaxMethod.Excluded && (
                <span style={{ fontSize: '1.0rem', letterSpacing: '5%' }}>
                  <FormattedMessage id="self.organisms.planDetail.withoutTax" />
                </span>
              )}
              {props.plan.taxMethod === TaxMethod.NonTaxable && (
                <span style={{ fontSize: '1.0rem', letterSpacing: '5%' }}>
                  <FormattedMessage id="self.organisms.planDetail.nonTaxable" />
                </span>
              )}
            </div>
          </Button>
        </ButtonWrapper>
      </BottomWrapper>
    </Page>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(180px + env(safe-area-inset-bottom));
`;
const ImageWrapper = styled.img`
  height: 100vw;
  width: 100vw;
  overflow: hidden;
  object-fit: cover;
  background-color: transparent;
`;

const TopContent = styled.div`
  margin: 10px 14px;
`;

const Title = styled.div`
  margin: 0;
`;

const TimeContent = styled.div`
  display: flex;
  margin: 4px 14px;
`;

const PlanDescription = styled.div`
  margin: 4px 14px;
`;

const PlanMenus = styled.div`
  font-size: 1.4rem;
  white-space: pre;
  margin: 14px;
  padding: 2px 14px;
  border-radius: 8px;
  background-color: #f6f6f8;
  color: #5d5d5d;
`;

const BottomWrapper = styled.div`
  background-color: white;
  align-items: center;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  border-top: #e9e9e9 1px solid;
  z-index: ${ZIndexSettings.MODAL};
  padding-top: 10px;
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
  bottom: 0;
  width: 100%;
`;
const OptionSection = styled.div``;
const OptionTitle = styled.div`
  color: #5d5d5d;
  padding: 6px 14px;
  border-radius: 2px;
  background-color: #f1f1f1;
`;
const OptionContent = styled.div`
  margin: 20px 14px;
`;
const ChoiceTitle = styled.div``;
const ChoiceWrapper = styled.div``;
const ChoiceContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ChoiceDescription = styled.div`
  color: #5d5d5d;
  height: 14px;
`;
const ChoicePrice = styled.div``;
const Spacer = styled.div`
  flex-grow: 1;
`;
const QuantityForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  font-size: 18px;
  max-width: 100px;
  min-width: 100px;
`;
const QuantityButton = styled.img`
  width: 25px;
  height: 25px;
`;
const ButtonWrapper = styled.div`
  min-width: 200px;
  width: 100%;
  flex-grow: 1;
  margin: 0 20px;
`;
