import React from 'react';
import styled from 'styled-components';
import NoImg from '../../../assets/images/no_image.svg';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const descriptionLabels: string[] = [];
const labelColors = ['#F46F46', '#0F4578', '#4A8829'];

const getDescriptionLabelColor = (text: string) => {
  if (!descriptionLabels.find(val => val === text)) {
    descriptionLabels.push(text);
  }

  return labelColors[descriptionLabels.findIndex(val => val === text) % 3];
};

const MenuItem = ({
  className,
  name,
  imageUrl,
  isSoldOut,
  isPlan,
  price,
  description,
  onClick,
  grayOut = false,
  type,
  descriptionLabelText,
}: Props) => {
  const canShowPrice = !isPlan && price;
  const canShowDescription = !isPlan && description;
  const labelColor = descriptionLabelText
    ? getDescriptionLabelColor(descriptionLabelText)
    : undefined;

  if (type === 'small') {
    return (
      <Wrapper className={className} onClick={onClick}>
        <SmallImageWrapper>
          <SmallImage src={imageUrl ? imageUrl + '?fit=crop&w=160&h=160&auto=compress' : NoImg} />
        </SmallImageWrapper>
        <SmallTitle>
          {descriptionLabelText ? <Label color={labelColor}>{descriptionLabelText}</Label> : null}
          {name}
          {isSoldOut && (
            <span style={{ color: 'red' }}>
              <FormattedMessage id="self.molecules.menuItem.soldOut" />
            </span>
          )}
        </SmallTitle>
        {canShowPrice && <SmallPrice>¥{price}</SmallPrice>}
      </Wrapper>
    );
  }

  if (type === 'large') {
    return (
      <Wrapper className={className} onClick={onClick}>
        <BigImageWrapper>
          <BigImage src={imageUrl ? imageUrl + '?fit=crop&w=500&h=320&auto=compress' : NoImg} />
        </BigImageWrapper>
        <BigTitle>
          {descriptionLabelText ? <Label color={labelColor}>{descriptionLabelText}</Label> : null}
          {name}
          {isSoldOut && (
            <span style={{ color: 'red' }}>
              <FormattedMessage id="self.molecules.menuItem.soldOut" />
            </span>
          )}
        </BigTitle>
        {canShowDescription && <BigDescription>{description}</BigDescription>}
        {canShowPrice && <BigPrice>¥{price}</BigPrice>}
      </Wrapper>
    );
  }

  const maxNameLines = isSoldOut || description ? 2 : 3;
  return (
    <Wrapper className={className} onClick={onClick}>
      <Image src={imageUrl ? imageUrl + `?w=160&h=160&auto=compress` : NoImg} />
      <Contents color={grayOut ? 'gray' : 'black'}>
        <Name variant={'h5'} maxlines={maxNameLines}>
          {name}
        </Name>
        {isSoldOut && (
          <Typography variant={'h5'} style={{ color: 'red' }}>
            <FormattedMessage id="self.molecules.menuItem.soldOut" />
          </Typography>
        )}
        {canShowDescription && (
          <DescriptionWrapper>
            <Description variant="body2">{description}</Description>
          </DescriptionWrapper>
        )}
        {canShowPrice && (
          <Typography
            style={{
              color: '#333',
            }}
            variant={'h4'}
          >
            ¥{price}
          </Typography>
        )}
      </Contents>
    </Wrapper>
  );
};

interface Props {
  className?: string;
  name: string;
  imageUrl?: string;
  isSoldOut: boolean;
  price: string | null;
  isPlan: boolean;
  description: string;
  onClick?: () => void;
  grayOut?: boolean;
  type?: 'small' | 'large';
  descriptionLabelText?: string;
}

const Wrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
`;

const SmallImageWrapper = styled.div`
  flex: 1;

  /* 画像の内側に影を落として白い背景の画像でも境界がわかりやすいように */
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`;

const SmallImage = styled.img`
  width: 100%;
  border-radius: 4px;
`;

const SmallTitle = styled.p`
  margin-top: 6px;
  flex: 1;
  font-size: 1.3rem;
  font-weight: bolder;
`;

const SmallPrice = styled.p`
  flex: 1;
  text-align: right;
  font-size: 1.4rem;
  font-weight: bolder;
`;

const BigImageWrapper = styled.div`
  flex: 1;

  /* 画像の内側に影を落として白い背景の画像でも境界がわかりやすいように */
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`;

const BigImage = styled.img`
  width: 100%;
  border-radius: 4px;
`;

const BigTitle = styled.p`
  margin-top: 10px;
  flex: 1;
  font-size: 1.3rem;
  font-weight: bolder;
`;

const BigDescription = styled.p`
  margin-top: 6px;
  font-size: 1rem;
  color: #999;
`;

const BigPrice = styled.p`
  flex: 1;
  text-align: right;
  font-size: 1.4rem;
  font-weight: bolder;
`;

const Image = styled.img`
  overflow: hidden;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 8px;
  object-fit: cover;
`;

const Contents = styled.div<{ color: string }>`
  padding-left: 8px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ color }) => color};
`;

const Name = styled(Typography)<{ maxlines: number }>`
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxlines }) => maxlines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #a0a0a0;
`;

const Label = styled.span<{ color?: string }>`
  background-color: ${props => props.color || '#f46f46'};
  color: white;
  font-size: 1rem;
  border-radius: 2px;
  padding: 4px 8px;
  margin-right: 12px;
`;

export default MenuItem;
