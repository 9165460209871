import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import WarningImg from '../../../assets/images/warning.svg';
import ModalTitle from '../../components/atoms/modal_title';
import { ErrorDisplayType } from './container';
import { FormattedMessage } from 'react-intl';
import { Shop } from '../../../data/entity/Shop';
import { Locale } from '../../../i18n';
import { Button } from '@material-ui/core';
import { Page } from '../Page';

export class ErrorPageComponent extends React.Component<IProps> {
  render() {
    const type = this.props.match.params.type;
    let title = this.props.locale === Locale.Ja ? 'エラーが発生しました' : 'An error has occurred';
    let message =
      this.props.locale === Locale.Ja
        ? '通信環境をご確認いただき、QRコードを読み込み直してください'
        : 'Please check your internet connection and reload QR';

    switch (type) {
      case ErrorDisplayType.NO_MENU: {
        title = this.props.locale === Locale.Ja ? '該当のメニューがありません' : 'No such menu';
        message = '';
        break;
      }
      case ErrorDisplayType.NOT_OPEN: {
        title = this.props.locale === Locale.Ja ? '現在ご利用時間外です' : 'Not open now';
        message =
          this.props.shop && this.props.shop.open && this.props.shop.close
            ? this.props.locale === Locale.Ja
              ? `開店時間は${this.props.shop.open}, 閉店時間は${this.props.shop.close}になります`
              : `Open at ${this.props.shop.open} and close at ${this.props.shop.close}`
            : '';
        break;
      }
      case ErrorDisplayType.NO_PAGE: {
        title = this.props.locale === Locale.Ja ? 'ページが見つかりませんでした' : 'Page not found';
        message =
          this.props.locale === Locale.Ja
            ? '通信環境をご確認いただき、QRコードを読み込み直してください'
            : 'Please check your internet connection and reload QR';
        break;
      }
    }

    return (
      <Page>
        <div>
          <img src={WarningImg} style={{ width: '100%', height: '146px', objectFit: 'cover' }} />
          <TextSection>
            <ModalTitle>{title}</ModalTitle>
            <Text>{message}</Text>
          </TextSection>
          <ButtonWrapper>
            {this.props.checkInPath ? (
              <Button
                variant={'contained'}
                color={'primary'}
                style={{
                  width: '100%',
                  height: '45px',
                }}
                onClick={() => this.props.backToHome(this.props.checkInPath || '')}
              >
                <FormattedMessage id="self.pages.order_history_page.backToHome" />
              </Button>
            ) : (
              <div>
                {type === ErrorDisplayType.NOT_OPEN ? (
                  <div />
                ) : (
                  <FormattedMessage id="self.pages.errorPage.description" />
                )}
              </div>
            )}
          </ButtonWrapper>
        </div>
      </Page>
    );
  }
}

interface IProps
  extends RouteComponentProps<{
    type: string;
  }> {
  checkInPath?: string;
  shop?: Partial<Shop>;
  locale: string;
  backToHome: (checkInPath: string) => void;
}

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 18px auto;
`;
const TextSection = styled.div`
  text-align: center;
  margin: 32px auto;
`;

const ButtonWrapper = styled.div`
  padding: 30px 50px;
`;
