import { Errors } from '../../errors';

export interface AppState {
  loadingFlagText: string | null;
  isShowingDrawer: boolean | undefined;
  modal: Modal;
  popup: Popup;
  error: { type: Errors; title?: string; message?: string } | undefined;
}

export enum Modal {
  None = 'none',
  ConfirmCallStaff = 'confirmCallStaff',
  ConfirmPayment = 'confirmPayment',
  ConfirmStartPlan = 'confirmStartPlan',
  CartCheck = 'cartCheck',
  ConfirmCheckIn = 'confirmCheckIn',
  PleaseCallStaff = 'pleaseCallStaff',
}

export enum Popup {
  None = 'none',
  AccountComplete = 'accountComplete',
  CallStaffComplete = 'callStaffComplete',
  CardRegistorComplete = 'cardRegistorComplete',
  OrderComplete = 'orderComplete',
  ReceiptEmailRegisterComplete = 'receiptEmailRegisterComplete',
  SignInComplete = 'signInComplete',
  SignOutComplete = 'signOutComplete',
}

export const initialState: AppState = {
  isShowingDrawer: false,
  modal: Modal.None,
  popup: Popup.None,
  loadingFlagText: null,
  error: undefined,
};
