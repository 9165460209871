import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CheckIn } from './pages/check_in/container';
import Home from './pages/home/container';
import MenuDetailPage from './pages/menu_detail/container';
import MyAccount from './pages/my_account/component';
import AddCardPage from './pages/add_card/container';
import { AboutMoPage } from './pages/about_mo/about_mo';
import FAQComponent from './pages/faq/component';
import { OrderHistory } from './pages/order_history/container';
import { Routes } from './routes';
import Receipt from './pages/receipt/container';
import { PlanDetail } from './pages/plan_detail/container';
import OldCheckIn from './pages/old_check_in/container';
import OtherCheckIn from './pages/other_check_in/container';
import { ErrorPage } from './pages/error_page/container';
import { AuthBoundarySwitch } from './AuthBoundarySwitch';
import { SelectNumberOfTableUserPage } from './pages/select_number_of_table_user_page/container';
import { SelectPaymentMethodPage } from './pages/select_payment_method/container';
import RegisterCardPage from './pages/register_payment_card/container';
import { PaymentCompleted } from './pages/payment_completed/component';
import { OrderCompletedPage } from './pages/order_completed/container';
import { SearchPage } from './pages/search/container';
import { MailSignInPage } from './pages/mail_sign_in/container';
import { MailSignUpPage } from './pages/mail_sign_up/container';
import { NoTableInfo } from './pages/no_table_info/component';
import { DefaultPage } from './pages/default/container';

export const Router = () => (
  <Switch>
    <Route exact path={Routes.default} component={DefaultPage} />
    <Route exact path={Routes.oldCheckIn} component={OldCheckIn} />
    <Route exact path={Routes.otherCheckIn} component={OtherCheckIn} />
    <Route exact path={Routes.checkIn} component={CheckIn} />
    <Route exact path={Routes.mailSignIn} component={MailSignInPage} />
    <Route exact path={Routes.mailSignUp} component={MailSignUpPage} />
    <Route exact path={Routes.noTableInfo} component={NoTableInfo} />
    <Route exact path={Routes.paymentCompleted} component={PaymentCompleted} />

    <AuthBoundarySwitch>
      <Route exact path={Routes.menuDetail} component={MenuDetailPage} />
      <Route exact path={Routes.planDetail} component={PlanDetail} />
      <Route exact path={Routes.home} component={Home} />
      <Route exact path={Routes.account} component={MyAccount} />
      <Route exact path={Routes.orderHistory} component={OrderHistory} />
      <Route exact path={Routes.addCard} component={AddCardPage} />
      <Route exact path={Routes.aboutMobileOrder} component={AboutMoPage} />
      <Route exact path={Routes.receipt} component={Receipt} />
      <Route exact path={Routes.faq} component={FAQComponent} />
      <Route exact path={Routes.selectNumberOfTableUser} component={SelectNumberOfTableUserPage} />
      <Route exact path={Routes.selectPaymentMethod} component={SelectPaymentMethodPage} />
      <Route exact path={Routes.registerCard} component={RegisterCardPage} />
      <Route exact path={Routes.orderCompleted} component={OrderCompletedPage} />
      <Route exact path={Routes.search} component={SearchPage} />
      <Route exact path={Routes.error} component={ErrorPage} />
    </AuthBoundarySwitch>
  </Switch>
);
