import React, { useCallback } from 'react';
import { CartSheet as DumbCartSheet } from './CartSheet';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCartItems,
  selectPaymentType,
  selectTableInfo,
  InformativeCartItem,
} from '../../../../store/selecter';
import { useLoader } from '../../../hooks/useLoader';
import { useIntl } from 'react-intl';
import { injector } from '../../../../data/injector';
import { menuActions } from '../../../../store/ducks/menu';
import { useHandleError } from '../../../hooks/useHandleError';
import { PaymentType } from '../../../../data/entity/PaymentType';
import { useCanOrder } from '../../../hooks/useCanOrder';

interface Props {
  open: boolean;
  onOpen(): void;
  onClose(): void;
}

const useUpdateItemAmount = () => {
  const dispatch = useDispatch();
  const tableInfo = useSelector(selectTableInfo);
  const { showLoader, dismissLoader } = useLoader();
  const { formatMessage: f, locale } = useIntl();
  const handleError = useHandleError();

  const updateItemAmount = useCallback(
    (cartItemId: number, quantity: number) => {
      (async () => {
        if (!tableInfo) return;

        try {
          showLoader(f({ id: 'self.organisms.cartConfirm.updateCart' }));
          const token = await injector.firebaseClient.getIdToken();
          await injector.apiClient.updateCart(token, tableInfo, locale, cartItemId, quantity);
        } catch (e) {
          try {
            const token = await injector.firebaseClient.getIdToken();
            const { categoryMenus, categoryPlans } = await injector.apiClient.getUserAppInfo(
              token,
              tableInfo,
              locale,
            );
            dispatch(menuActions.updateAllMenus({ categoryMenus: categoryMenus }));
            dispatch(menuActions.updateAllCategoryPlans({ categoryPlans: categoryPlans }));
          } catch (e) {
            console.log(e);
          }
          handleError(e);
        } finally {
          dismissLoader();
        }
      })();
    },
    [dismissLoader, dispatch, f, handleError, locale, showLoader, tableInfo],
  );

  const increaseItem = useCallback(
    (cartItem: InformativeCartItem) => {
      updateItemAmount(cartItem.cartItemId, cartItem.quantity + 1);
    },
    [updateItemAmount],
  );

  const decreaseItem = useCallback(
    (cartItem: InformativeCartItem) => {
      updateItemAmount(cartItem.cartItemId, cartItem.quantity - 1);
    },
    [updateItemAmount],
  );

  return { increaseItem, decreaseItem };
};

const CartSheet: React.FC<Props> = ({ open, onOpen, onClose }) => {
  const cartItems = useSelector(selectCartItems);
  const paymentType = useSelector(selectPaymentType);
  const { increaseItem, decreaseItem } = useUpdateItemAmount();
  const { alertMessage, guideMessage } = useCanOrder();

  return (
    <DumbCartSheet
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      cartItems={cartItems}
      shouldShowAvatar={paymentType === PaymentType.All}
      onClickIncreaseButton={increaseItem}
      onClickDecreaseButton={decreaseItem}
      title={alertMessage}
      subTitle={guideMessage}
    />
  );
};

export default CartSheet;
