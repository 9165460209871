import React from 'react';
import styled from 'styled-components';
import { Button, makeStyles, Typography } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { FormattedMessage } from 'react-intl';
import { HomeActionType } from '../home_header/component';

interface Props {
  type: HomeActionType;
  clickable: boolean;
  onClick: () => void;
  mini?: boolean;
}

const useStyles = makeStyles({
  wrap: {
    minWidth: 52,
    height: 40,
    borderRadius: 8,
    padding: '0 14px',
  },
  mini: {
    borderRadius: 8,
    minWidth: 52,
    height: 30,
    padding: '0 7px',
  },
});

const HomeActionButton: React.FC<Props> = props => {
  const classes = useStyles();
  switch (props.type) {
    case HomeActionType.History:
      return (
        <Button
          className={props.mini ? classes.mini : classes.wrap}
          size={'small'}
          onClick={props.onClick}
          style={{ backgroundColor: '#B7B6B2' }}
        >
          <ContentWrapper>
            <RestoreIcon
              style={{
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            <ActionName variant={'h5'}>
              <FormattedMessage id="self.molecules.home_action_button.history" />
            </ActionName>
          </ContentWrapper>
        </Button>
      );
    case HomeActionType.CallStaff:
      return (
        <Button
          className={props.mini === true ? classes.mini : classes.wrap}
          onClick={props.onClick}
          style={{ backgroundColor: '#F1C335' }}
        >
          <ContentWrapper>
            <NotificationsIcon
              style={{
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            <ActionName variant={'h5'}>
              <FormattedMessage id="self.molecules.home_action_button.callStaff" />
            </ActionName>
          </ContentWrapper>
        </Button>
      );
    case HomeActionType.Account:
      return (
        <Button
          className={props.mini === true ? classes.mini : classes.wrap}
          onClick={props.onClick}
          style={{ backgroundColor: '#FA7952' }}
        >
          <ContentWrapper>
            <CreditCardIcon
              style={{
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            <ActionName variant={'h5'}>
              <FormattedMessage id="self.molecules.home_action_button.account" />
            </ActionName>
          </ContentWrapper>
        </Button>
      );
  }
};

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #ffffff;
`;

const ActionName = styled(Typography)`
  text-align: center;
  white-space: nowrap;
  width: 100%;
  margin: 4px;
`;

export default HomeActionButton;
