import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { DisplayReceipt } from '../../../data/entity/DisplayReceipt';
import { Page } from '../Page';

const useStyles = makeStyles(() =>
  createStyles({
    receiptWrapper: {
      margin: '16px',
      padding: '24px 22px',
      boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
    },
    totalPrice: {
      fontSize: '26px',
      textAlign: 'center',
      backgroundColor: '#f1f1f1',
      padding: '12px 0',
      margin: '20px auto 18px',
    },
    detail: {
      width: '50%',
      margin: '22px auto 34px',
    },
    detailPrice: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrapper: {
      margin: '20px 40px',
      textAlign: 'center',
    },
    downLoadButton: {
      width: '100%',
      fontSize: '14px',
    },
  }),
);

export const ReceiptComponent: React.FC<Props> = (props: Props) => {
  const styles = useStyles();

  return (
    <Page>
      <div className={styles.receiptWrapper}>
        <Typography variant="h3" style={{ textAlign: 'center', marginBottom: '14px' }}>
          <FormattedMessage id="self.receipt.title" />
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          No.{props.receipt.id}
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'right' }}>
          {' '}
          {props.receipt.timestampText}
        </Typography>
        <div className={styles.detail}>
          <Typography
            variant="body1"
            style={{
              textAlign: 'left',
              borderBottom: '1px solid #333',
              marginBottom: '1px',
            }}
          >
            <FormattedMessage id="self.receipt.subTitle" />
          </Typography>
          <div className={styles.detailPrice}>
            <Typography variant="body1">
              <FormattedMessage id="self.receipt.rawPriceLabel" />
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'right' }}>
              ¥{props.receipt.rawAmount}-
            </Typography>
          </div>
          <div className={styles.detailPrice}>
            <Typography variant="body1">
              <FormattedMessage id="self.receipt.taxPriceLabel" />
            </Typography>
            <Typography variant="body1">¥{props.receipt.taxAmount}-</Typography>
          </div>
        </div>
        <Typography variant="body2" style={{ textAlign: 'right', whiteSpace: 'pre-wrap' }}>
          {props.issuer}
        </Typography>
        <br />
        <Typography variant="body2" style={{ textAlign: 'right', whiteSpace: 'pre-wrap' }}>
          {props.contactInfo}
        </Typography>
      </div>
    </Page>
  );
};

interface Props {
  receipt: DisplayReceipt;
  issuer: string;
  contactInfo: string;
}
