export enum TableUserStateValue {
  Active = 'active',
  Inactive = 'inactive',
  RegisterCheckInProgress = 'registerCheckInProgress',
  TableCheckRequested = 'tableCheckRequested',
  TableCheckInProgress = 'tableCheckInProgress',
  Seated = 'seated',
}

export const isInPaymentProcess = (state: TableUserStateValue) =>
  [
    TableUserStateValue.RegisterCheckInProgress,
    TableUserStateValue.TableCheckRequested,
    TableUserStateValue.TableCheckInProgress,
  ].includes(state);
