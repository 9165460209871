import React, { useCallback, ChangeEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { AppBar, Toolbar, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, Theme, makeStyles, fade } from '@material-ui/core/styles';
import { color } from '../../../../assets/colors';

type Props = {
  keyword: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
    search: {
      display: 'flex',
      position: 'relative',
      borderRadius: 32,
      backgroundColor: fade(theme.palette.common.black, 0.1),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.15),
      },
      width: '100%',
      height: 32,
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      width: '100%',
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
    },
  }),
);

export const AppBarComponent: React.FC<Props> = ({ keyword, onChange }) => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  }, []);

  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        <SearchField>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={f({ id: 'self.pages.search.app_bar.search' })}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={keyword}
            onChange={onChange}
            onKeyDown={handleKeyDown}
          />
        </SearchField>
      </Toolbar>
    </AppBar>
  );
};

const SearchField = styled.div`
  display: flex;
  position: relative;
  border-radius: 32px;
  background-color: ${color.greyLight};
  width: 100%;
  height: 32px;
`;
