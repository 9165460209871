import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectLocale, selectShop } from '../store/selecter';
import { Locale } from '../i18n';
import { Routes } from './routes';

// TODO: React Helmet とかでページ内からタイトルを書き換えたほうが良さそう
export const HistorySubscriber: React.FC = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const shop = useSelector(selectShop);
  const locale = useSelector(selectLocale);

  useEffect(() => {
    let title = shop.name;
    switch (pathname) {
      case Routes.orderHistory:
        title = locale === Locale.Ja ? '注文一覧' : 'Order History';
        break;
      default:
        break;
    }
    document.title = title ?? '';
  }, [history.location, shop, locale, pathname]);

  return <>{children}</>;
};
