import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ReactComponent as TranslateIcon } from '../../../../assets/images/translateIcon.svg';
import { Locale } from '../../../../i18n';

interface Props {
  updateLocale: (locale: Locale) => void;
  localeList: LocaleList[];
  locale: string;
}

interface LocaleList {
  value: Locale;
  text: string;
}

export const LocaleSelectComponent: React.FC<Props> = ({ updateLocale, localeList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <LocaleSelectWrapper>
      <LocaleSelectButton ref={anchorRef} onClick={() => setIsOpen(true)}>
        <StyledTranslateIcon width="20px" />
        <ArrowDropDownIcon />
      </LocaleSelectButton>
      <Menu open={isOpen} anchorEl={anchorRef.current} onClose={() => setIsOpen(false)} keepMounted>
        {localeList.map((lang, index) => (
          <MenuItem
            key={index}
            value={lang.value}
            onClick={() => {
              setIsOpen(false);
              updateLocale(lang.value);
            }}
          >
            <Typography variant={'body2'}>{lang.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </LocaleSelectWrapper>
  );
};

const LocaleSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
`;

const LocaleSelectButton = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTranslateIcon = styled(TranslateIcon)`
  fill: currentColor;
`;
