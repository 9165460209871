import React from 'react';
import styled from 'styled-components';
import { Avatar, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PaymentType } from '../../../data/entity/PaymentType';
import OrderCompletedImage from '../../../assets/images/order_completed_image.svg';
import OrderCompletedImageEn from '../../../assets/images/order_completed_image_en.svg';
import EnableReOrderImage from '../../../assets/images/ic_able_to_reorder.svg';
import { Locale } from '../../../i18n';
import { Page } from '../Page';
import { shadow } from '../../styles/shadow';
import HomeActionButton from '../home/home_action_button';
import { HomeActionType } from '../home/home_header/component';

interface Props {
  orders: ComponentOrder[];
  backToHome: () => void;
  paymentType: PaymentType;
  search: string;
  locale: Locale;
}

export interface ComponentOrder {
  userProfileImage: string;
  time: string;
  name: string;
  optionText: string;
  quantity: number;
  unitPrice: number;
}

export class OrderCompleted extends React.Component<Props> {
  render() {
    return (
      <Page>
        <div>
          <TopImage
            src={this.props.locale === Locale.Ja ? OrderCompletedImage : OrderCompletedImageEn}
          />

          <Description>
            {this.props.locale === Locale.Ja ? (
              <div>
                <EnableReorderImage src={EnableReOrderImage} />
                ホーム画面の
                <HistoryButtonWrapper />
                <HomeActionButton
                  mini={true}
                  clickable={true}
                  onClick={() => {}}
                  type={HomeActionType.History}
                />
                <HistoryButtonWrapper />
                から再注文が可能です
              </div>
            ) : (
              <div>
                <EnableReorderImage src={EnableReOrderImage} />
                You can reorder from
                <HistoryButtonWrapper />
                <HomeActionButton
                  mini={true}
                  clickable={true}
                  onClick={() => {}}
                  type={HomeActionType.History}
                />
                <HistoryButtonWrapper />
                button in home.
              </div>
            )}
          </Description>

          <Wrapper>
            <div>
              {this.props.orders.map((orderItem, index) => {
                return (
                  <OrderDetailWrapper key={index}>
                    <Avatar
                      src={orderItem.userProfileImage}
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '8px',
                      }}
                    />
                    <Name>
                      {orderItem.name}
                      <br />
                      <Option>{orderItem.optionText}</Option>
                    </Name>
                    <Price>
                      ¥{orderItem.unitPrice}&nbsp;&nbsp;×{orderItem.quantity}
                    </Price>
                  </OrderDetailWrapper>
                );
              })}
            </div>
          </Wrapper>
          <ButtonWrapper>
            <ShadowButton
              variant={'contained'}
              fullWidth={true}
              color={'primary'}
              onClick={() => this.props.backToHome()}
            >
              <FormattedMessage id="self.pages.order_history_page.backToHome" />
            </ShadowButton>
          </ButtonWrapper>
        </div>
      </Page>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 10px;
  padding-bottom: 100px;
  margin: 0 16px;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  left: 7.5%;
  width: 85%;
  text-align: center;
  bottom: calc(30px + env(safe-area-inset-bottom));
`;

const TopImage = styled.img`
  width: 100%;
`;

const EnableReorderImage = styled.img`
  width: 33px;
  height: 33px;
  margin-right: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  vertical-align: bottom;
  display: inline-block;
`;

const OrderDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 20px 4px;
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
`;
const Name = styled.div`
  flex-grow: 1;
`;
const Price = styled.div`
  width: 20%;
  margin-right: 8px;
  text-align: right;
`;
const Option = styled.span`
  font-size: 14px;
  color: #a0a0a0;
  margin-top: 6px;
  white-space: pre-wrap;
`;
const ShadowButton = styled(Button)`
  ${shadow(2)}
`;
const Description = styled.div`
  margin: 8px;
  font-size: 14px;
  text-align: center;
`;
const HistoryButtonWrapper = styled.div`
  display: inline-block;
  width: 8px;
`;
