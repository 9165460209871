import State from './state';
import { TableUserStateValue } from '../data/entity/TableUserState';
import { PaymentType } from '../data/entity/PaymentType';
import { createSelector } from '@reduxjs/toolkit';
import { CartItem } from '../data/entity/Cart';
import { Shop } from '../data/entity/Shop';
import { TableUser } from '../data/entity/TableUser';
import { ActivePlan } from '../data/entity/ActivePlan';
import { Locale } from '../i18n';
import { ShopMaintenanceInfo } from '../data/entity/ShopMaintenance';

export const selectPaymentType = (state: State) => state.shop.paymentType!;
export const selectModal = (state: State) => state.app.modal;
export const selectPopup = (state: State) => state.app.popup;
export const selectIsLoading = (state: State) => state.app.loadingFlagText !== null;
export const selectCards = (state: State) => state.userState.cards;
export const selectTableInfo = (state: State) => state.tableInfo;
export const selectProfile = (state: State) => state.userState.profile;
export const selectLocale = (state: State) => state.userState.locale;
export const selectMenus = (state: State) => state.menu.categoryMenus;
export const selectCategoryPlans = (state: State) => state.menu.categoryPlans;

export interface InformativeActivePlan extends ActivePlan {
  name: string;
}

export interface InformativeTableUser extends TableUser {
  activePlans?: InformativeActivePlan[];
}

export const selectTableUser = createSelector(
  (state: State) => state.tableUserState.tableUser,
  selectLocale,
  (tableUser, locale): InformativeTableUser => ({
    ...tableUser,
    activePlans: tableUser.activePlans?.map(
      (activePlan): InformativeActivePlan => ({
        ...activePlan,
        name: activePlan.meta?.i18n[locale]?.name,
      }),
    ),
  }),
);

export const selectActivePlans = createSelector(
  selectTableUser,
  // NOTE: activePlans の中に有効期限ぎれのプランが有るためそれは除く
  tableUser =>
    tableUser.activePlans?.filter(plan => {
      const isUnlimitedPlan = !plan.endAt;
      const isInTimePlan = new Date().getTime() < (plan.endAt ?? 0) * 1000;
      return isUnlimitedPlan || isInTimePlan;
    }) ?? [],
);

export interface InformativeShop extends Partial<Shop>, Partial<ShopMaintenanceInfo> {}

export const selectShop = createSelector(
  (state: State) => state.shop,
  selectLocale,
  (shop, locale): InformativeShop => ({
    ...shop,
    name: (locale === Locale.Ja ? shop.name : shop.nameEn) ?? '',
    maintenanceText: (locale === Locale.Ja ? shop.maintenanceText : shop.maintenanceTextEn) ?? '',
  }),
);

export interface InformativeCartItem extends CartItem {
  menuName: string;
  optionText: string;
}

export const selectCartItems = createSelector(
  (state: State) => state.tableUserState.cartItems,
  selectLocale,
  (cartItems: CartItem[], locale): InformativeCartItem[] =>
    cartItems.map(cartItem => ({
      ...cartItem,
      menuName: cartItem.meta?.i18n[locale].name ?? '',
      optionText: cartItem.meta?.i18n[locale].optionText ?? '',
    })),
);

export const selectOrders = (state: State) => state.tableUserState.orders;

export const selectShouldInputNumberOfTableUser = createSelector(
  selectPaymentType,
  selectIsLoading,
  selectTableUser,
  (paymentType, isLoading, tableUser) =>
    paymentType === PaymentType.All &&
    tableUser.tableUserState === TableUserStateValue.Active &&
    !tableUser.numPeople &&
    !isLoading,
);

export const selectUser = (state: State) => state.userState;
export const selectUid = (state: State) => state.firebaseUser.user?.uid;
export const selectIsLoggedIn = (state: State) => Boolean(state.firebaseUser.user);
export const selectIsUserInitialized = (state: State) => state.firebaseUser.initialized;

export const selectOrderCount = createSelector(
  selectCartItems,
  selectUid,
  (cartItems: CartItem[], uid) => {
    const selfOrderCount =
      cartItems.filter(item => item.userId === uid).reduce((sum, item) => item.quantity + sum, 0) ||
      0;
    const otherOrderCount =
      cartItems.filter(item => item.userId !== uid).reduce((sum, item) => item.quantity + sum, 0) ||
      0;

    return {
      selfOrderCount,
      otherOrderCount,
    };
  },
);
