import React from 'react';
import { Modal } from '../../../../store/app/state';
import ConfirmModal from '../confirm_modal/confirm_modal';
import { FormattedMessage } from 'react-intl';

interface Props {
  onClose: () => void;
  onClickConfirm: () => void;
}

export class ConfirmPaymentModalComponent extends React.Component<Props> {
  render() {
    return (
      <ConfirmModal
        name={Modal.ConfirmPayment}
        title={<FormattedMessage id={'self.organisms.confirmPaymentModal.payment'} />}
        message={<FormattedMessage id={'self.organisms.confirmPaymentModal.message'} />}
        onClickConfirm={this.props.onClickConfirm}
        onClickCancel={this.props.onClose}
      />
    );
  }
}
