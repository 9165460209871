import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CompletePopupComponent } from './component';
import { Popup as PopupType } from '../../../../store/app/state';
import { closePopupAction } from '../../../../store/app/actions';

const CompletePopup: React.FC<IProps> = ({ name, text }) => {
  const dispatch = useDispatch();
  const closePopup = useCallback(() => dispatch(closePopupAction()), [dispatch]);

  return <CompletePopupComponent name={name} text={text} closePopup={closePopup} />;
};

interface IProps {
  name: PopupType;
  text: string | JSX.Element;
}

export default CompletePopup;
