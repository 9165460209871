import { Modal, Popup } from './state';
import { Errors } from '../../errors';

export const UPDATE_MODAL = 'UPDATE_MODAL';
export const UPDATE_LOADING_FLAG_TEXT = 'UPDATE_LOADING_FLAG_TEXT';
export const UPDATE_CURRENT_ERROR = 'UPDATE_CURRENT_ERROR';
export const DELETE_CURRENT_ERROR = 'DELETE_CURRENT_ERROR';
export const UPDATE_POPUP_MODAL = 'UPDATE_POPUP_MODAL';
export const RESET_PLAN_CART = 'RESET_PLAN_CART';

interface UpdateIsLoadingAction {
  type: typeof UPDATE_LOADING_FLAG_TEXT;
  loadingFlagText: string | null | JSX.Element;
}

export interface UpdateModalAction {
  type: typeof UPDATE_MODAL;
  modal: Modal;
}

interface UpdateCurrentErrorAction {
  type: typeof UPDATE_CURRENT_ERROR;
  currentError: { type: Errors; title?: string; message?: string };
}

export interface DeleteCurrentErrorAction {
  type: typeof DELETE_CURRENT_ERROR;
}

interface UpdatePopupAction {
  type: typeof UPDATE_POPUP_MODAL;
  popup: Popup;
}

interface ResetPlanCart {
  type: typeof RESET_PLAN_CART;
}

export type AppActionTypes =
  | UpdateModalAction
  | UpdateCurrentErrorAction
  | DeleteCurrentErrorAction
  | UpdateIsLoadingAction
  | UpdatePopupAction
  | ResetPlanCart;
