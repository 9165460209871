import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

export default class MyAccountMenuElement extends React.Component<IProps> {
  render() {
    return (
      <div onClick={() => this.props.onClick && this.props.onClick()}>
        <Title>{this.translateText(this.props.title)}</Title>
      </div>
    );
  }
  private translateText(text: string) {
    switch (text) {
      case 'お問い合わせ':
        return <FormattedMessage id="self.atoms.myaccount_menu_element.contactUs" />;
      case '利用規約':
        return <FormattedMessage id="self.atoms.myaccount_menu_element.termsOfService" />;
      case 'プライバシーポリシー':
        return <FormattedMessage id="self.atoms.myaccount_menu_element.privacyPolicy" />;
      case 'ログアウト':
        return <FormattedMessage id="self.atoms.myaccount_menu_element.logout" />;
      default:
        return text;
    }
  }
}

interface IProps {
  title: string;
  onClick?: () => void;
}

const Title = styled.div`
  padding: 16px 14px;
  border-bottom: 1px solid #f1f1f1;
  text-align: left;
`;
