import React from 'react';
import styled from 'styled-components';
import { Button, Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import welcomeDinnerImg from '../../../assets/images/welcome_dinner.svg';
import { Page } from '../Page';

interface Props {
  confirmNumberOfTableUser(): void;
  numberOfTableUser?: number;
  setNumberOfTableUser(n: number): void;
  numberOfTableUserOptions: number[];
}

export const SelectNumberOfTableUser: React.FC<Props> = ({
  confirmNumberOfTableUser,
  numberOfTableUser,
  setNumberOfTableUser,
  numberOfTableUserOptions,
}: Props) => {
  const options = numberOfTableUserOptions.map(option => (
    <Option
      key={`cell:${option}`}
      isSelected={option === numberOfTableUser}
      onClick={() => setNumberOfTableUser(option)}
    >
      <FormattedMessage
        id="self.molecules.numPeopleSelector.selectPeople"
        values={{ name: option }}
      />
    </Option>
  ));

  return (
    <Page>
      <Eyecatch>
        <img width="320" height="200" src={welcomeDinnerImg} />
      </Eyecatch>

      <Content>
        <StyledTitleBox textAlign="center" mb={2.4}>
          <FormattedMessage id="self.organisms.chooseNumPeopleModal.howMany" />
        </StyledTitleBox>

        <OptionWrapper>{options}</OptionWrapper>

        <Box mt={2.4}>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={!numberOfTableUser}
            onClick={confirmNumberOfTableUser}
          >
            <FormattedMessage id="self.organisms.chooseNumPeopleModal.enter" />
          </StyledButton>

          <CheckInDescription>
            <Typography variant="body2">
              <FormattedMessage id="self.organisms.chooseNumPeopleModal.enterDescription" />
            </Typography>
          </CheckInDescription>
        </Box>
      </Content>
    </Page>
  );
};

const Eyecatch = styled.div`
  background-color: #fda014;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
`;

const Content = styled.div`
  padding: 36px;
`;

const StyledTitleBox = styled(Box)`
  font-size: 1.6rem;
`;

const Option = styled.div`
  border: 1px solid;
  border-radius: 4px;
  color: #c4c4c4;
  padding: 16px 0;
  text-align: center;
  font-size: 1.6rem;
  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    `
    background-color: #f46f46;
    border: 1px #f46f46 solid;
    color: white;
  `}
`;

const OptionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 1.6rem;
`;

const CheckInDescription = styled.div`
  text-align: center;
  margin-top: 12px;
`;
