import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTableInfo, selectIsLoggedIn } from '../../store/selecter';
import { useAppDispatch } from './useAppDispatch';
import { operations } from '../../store/operations';

export const useSubscribeCart = () => {
  const dispatch = useAppDispatch();
  const { shopId, tableUserId } = useSelector(selectTableInfo);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn && shopId && tableUserId) {
      return dispatch(operations.subscribeCart({ shopId, tableUserId }));
    }
  }, [dispatch, isLoggedIn, shopId, tableUserId]);
};
