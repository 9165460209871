import React, { useEffect, useState } from 'react';
import { injector } from '../../../../data/injector';
import { Avatar, makeStyles, createStyles } from '@material-ui/core';
import Snackbar from '../../../components/molecules/snackbar';
import { useSnackbars } from '../../../components/molecules/snackbar_provider';

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: 30,
      height: 30,
    },
  }),
);

interface Props {
  items: Snackbar[];
}

export type Snackbar = {
  id: number;
  user: {
    id: string;
    image: string;
  };
  message: string;
  createdAt: number;
};

export const HomeSnackbarComponent: React.FC<Props> = ({ items }) => {
  const classes = useStyles();

  const [initializedAt] = useState(new Date().getTime() / 1000);
  const [ignored, setIgnored] = useState<string[]>([]);
  const { snackbars, createSnackbar, deleteSnackbar } = useSnackbars<Snackbar>();
  const userId = injector.firebaseClient.currentUser()?.uid;

  useEffect(() => {
    items
      .filter(({ createdAt, user }) => createdAt > initializedAt && user.id !== userId)
      .forEach(item => {
        const id = item.id.toString();
        if (!ignored.includes(id)) {
          const snackbarId = createSnackbar(id, item);
          if (snackbarId) {
            setIgnored([...ignored, snackbarId]);
          }
        }
      });
  }, [items, initializedAt, userId, ignored, createSnackbar, setIgnored]);

  return (
    <>
      {snackbars.map(([id, { user, message }]) => (
        <Snackbar
          key={id}
          id={id}
          icon={<Avatar className={classes.icon} src={user.image} />}
          message={message}
          onExit={() => deleteSnackbar(id)}
        />
      ))}
    </>
  );
};
