import { ThunkResult } from '../thunk';
import { CartItem } from '../../data/entity/Cart';
import { tableUserActions } from '../ducks/tableUser';
import { Unsubscribe } from 'firebase';

export const subscribeCart = ({
  shopId,
  tableUserId,
}: {
  shopId: string;
  tableUserId: string;
}): ThunkResult<Unsubscribe> => (dispatch, getState, { cartRef }) => {
  return cartRef(shopId, tableUserId).onSnapshot(snapshot => {
    const cartItems = snapshot.docs.map(d => d.data() as CartItem);

    dispatch(tableUserActions.updateCartAction({ cartItems }));
  });
};
