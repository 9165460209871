import React from 'react';
import styled from 'styled-components';
import { Page } from '../Page';
import DiniiLoader from '../../common/dinii_loader';

export const CheckInComponent: React.FC<Props> = (props: Props) => {
  return (
    <Page>
      <Wrapper>
        <React.Fragment>
          <DiniiLoader visible={true} text={props.loadingText} />
        </React.Fragment>
      </Wrapper>
    </Page>
  );
};

interface Props {
  loadingText: string;
  close: () => void;
}

const Wrapper = styled.div`
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
