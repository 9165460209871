import React from 'react';
import styled from 'styled-components';
import SnackbarItem from './snackbar_item';

const Message = styled.div`
  font-size: 12px;
  margin-left: 8px;
`;

export interface Props {
  id: string;
  icon: React.ReactNode;
  message: string;
  onExit?: () => void;
}

const Snackbar: React.FC<Props> = props => {
  const { id, icon, message, onExit } = props;

  return (
    <SnackbarItem id={id} hideDuration={2000} onExit={onExit}>
      {icon}
      <Message>{message}</Message>
    </SnackbarItem>
  );
};

export default React.memo(Snackbar);
