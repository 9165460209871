import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmModal from '../confirm_modal/confirm_modal';
import { Modal } from '../../../../store/app/state';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '../../../../store/app/actions';

const PleaseCallStaffPopup: React.FC = () => {
  const dispatch = useDispatch();
  const onClickConfirm = useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);

  return (
    <ConfirmModal
      name={Modal.PleaseCallStaff}
      title={<FormattedMessage id="self.molecules.pleaseCallStaffModal.title" />}
      message={<FormattedMessage id="self.molecules.pleaseCallStaffModal.message" />}
      onClickConfirm={onClickConfirm}
    />
  );
};

export default PleaseCallStaffPopup;
