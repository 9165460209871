import { ThunkResult } from '../thunk';
import { Unsubscribe } from 'firebase';
import { tableUserActions } from '../ducks/tableUser';
import { TableUser } from '../../data/entity/TableUser';

export const subscribeTableUser = ({
  shopId,
  tableUserId,
}: {
  shopId: string;
  tableUserId: string;
}): ThunkResult<Unsubscribe> => (dispatch, getState, { tableUserRef }) => {
  return tableUserRef(shopId, tableUserId).onSnapshot(snapshot => {
    const tableUser = snapshot.data() as TableUser;

    dispatch(tableUserActions.updateTableUser({ tableUser }));
  });
};
