import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { List, Typography } from '@material-ui/core';
import { Menu } from '../../../../data/entity/menu';
import { color } from '../../../../assets/colors';
import MenuItem from '../../../components/molecules/menu_item';

type Props = {
  menus: Menu[];
  onClickMenuItem: (categoryId: number, menuId: number) => void;
};

export const MenuComponent: React.FC<Props> = ({ menus, onClickMenuItem }) => {
  const { formatMessage: f } = useIntl();

  return menus.length ? (
    <List>
      {menus.map(({ menuId, categoryId, name, imageUrl, isSoldout, price, description }) => (
        <StyledMenuItem
          key={menuId}
          name={name}
          isPlan={false}
          imageUrl={imageUrl || undefined}
          isSoldOut={isSoldout}
          price={price.toString()}
          description={description || ''}
          onClick={() => {
            if (isSoldout) return;
            onClickMenuItem(categoryId, menuId);
          }}
        />
      ))}
    </List>
  ) : (
    <NoResult>
      <NoResultText variant="h4">{f({ id: 'self.pages.search.menu.noResultsFound' })}</NoResultText>
    </NoResult>
  );
};

const StyledMenuItem = styled(MenuItem)`
  padding: 20px 10px;
  box-sizing: border-box;
`;

const NoResult = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoResultText = styled(Typography)`
  color: ${color.grey};
`;
