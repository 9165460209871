import { StartPlanRequest } from '../../data/api/request/StartPlanRequest';
import { ThunkResult } from '../thunk';
import { closeModalAction, updateIsLoading } from '../app/actions';
import { Locale } from '../../i18n';
import { TableInfo } from '../../data/entity/TableInfo';
import { goBack } from 'connected-react-router';

export const startPlan = (
  tableInfo: TableInfo,
  locale: Locale,
  loadingMessage: string,
  startPlanRequest: StartPlanRequest,
  errorHandler: (e: Error) => void,
): ThunkResult<void> => async (dispatch, _, { diniiApiClient, firebaseApiClient }) => {
  dispatch(closeModalAction());
  dispatch(updateIsLoading(loadingMessage));
  const idToken = await firebaseApiClient.getIdToken();
  try {
    await diniiApiClient.startPlan(idToken, tableInfo, locale, startPlanRequest);
    dispatch(goBack());
  } catch (e) {
    errorHandler(e);
  } finally {
    dispatch(updateIsLoading(null));
  }
};
