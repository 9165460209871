import { useLocation } from 'react-router';
import { useMemo } from 'react';

const LiffStateKey = 'liff.state';

export const useQuery = () => {
  const location = useLocation();

  return useMemo(() => {
    const query = new URLSearchParams(location.search);
    const liffStateValue = query.get(LiffStateKey);

    // NOTE: LIFFでは liff.state にクエリパラメタがエンコードされて渡されるのでデコードする
    if (liffStateValue) {
      // liff.state には次のような値が入っている ☞ `/?companyId=27&shopId=7yvj4KuSj9RHo31simfL&tableId=672`
      // URLSearchParams は URL をパースしないので `/` を消して上げる必要がある
      const liffQueryParams = new URLSearchParams(
        decodeURIComponent(liffStateValue).replace(/\/.*\?/, ''),
      );

      liffQueryParams.forEach((value, key) => {
        query.set(key, value);
      });
    }

    return query;
  }, [location.search]);
};
