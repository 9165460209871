import React from 'react';
import { CustomError, getErrorSeverity } from '../errors';
import * as Sentry from '@sentry/browser';
import WarningImg from '../assets/images/warning.svg';
import ModalTitle from './components/atoms/modal_title';
import styled from 'styled-components';
import { Locale } from '../i18n';
import { Button } from '@material-ui/core';

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: object) {
    this.setState({ hasError: true });
    if (!(error instanceof CustomError)) {
      Sentry.captureException(error);
    } else {
      Sentry.configureScope((scope: any) => {
        scope.setLevel(getErrorSeverity(error.error));
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const title =
        this.props.locale == Locale.Ja
          ? '予期せぬエラーが発生しました'
          : 'Unknown error has occurred';
      const message =
        this.props.locale == Locale.Ja
          ? 'ネットワーク状況をご確認いただき、QRコードを読み込み直してください。'
          : 'please reload qr code';
      return (
        <div>
          <img src={WarningImg} style={{ width: '100%', height: '146px', objectFit: 'cover' }} />
          <TextSection>
            <ModalTitle>{title}</ModalTitle>
            <Text>{message}</Text>
          </TextSection>
          <ButtonWrapper>
            {this.props.back && (
              <Button
                style={{
                  width: '100%',
                  height: '45px',
                }}
                variant={'contained'}
                color={'primary'}
                onClick={() => {
                  this.setState({ hasError: false });
                  this.props.deleteError();
                  if (this.props.back) {
                    this.props.back();
                  }
                }}
              >
                {this.props.locale === Locale.Ja ? 'ホームに戻る' : 'Go to home'}
              </Button>
            )}
          </ButtonWrapper>
        </div>
      );
    }
    return this.props.children;
  }
}

interface Props {
  back: (() => void) | undefined;
  deleteError: () => void;
  locale: string;
}

interface State {
  hasError: boolean;
}

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 18px auto;
`;
const TextSection = styled.div`
  text-align: center;
  margin: 32px auto;
`;

const ButtonWrapper = styled.div`
  padding: 30px 50px;
`;

export default ErrorBoundary;
