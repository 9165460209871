import { useSelector } from 'react-redux';
import { HomeComponent } from './component';
import React, { useState, useCallback } from 'react';
import {
  selectPaymentType,
  selectShop,
  selectTableUser,
  selectCartItems,
  selectOrderCount,
} from '../../../store/selecter';
import { closeModalAction, updateModalAction } from '../../../store/app/actions';
import { Modal } from '../../../store/app/state';
import { operations } from '../../../store/operations';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useCanOrder } from '../../hooks/useCanOrder';
import { useSubscribeActivePlan } from '../../hooks/useSubscribeActivePlan';
import { useConfirmCheckIn } from '../../hooks/useConfirmCheckIn';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const shop = useSelector(selectShop);
  const tableUser = useSelector(selectTableUser);
  const paymentType = useSelector(selectPaymentType);
  const cartItems = useSelector(selectCartItems);
  const canOpenCartSheet = cartItems.length > 0;
  const { selfOrderCount, otherOrderCount } = useSelector(selectOrderCount);
  const { formatMessage: t } = useIntl();
  const { canOrder } = useCanOrder();

  const [cartSheetIsOpen, setCartSheetIsOpen] = useState(false);
  const toggleCartSheet = useCallback(() => {
    if (cartSheetIsOpen) {
      setCartSheetIsOpen(false);
      dispatch(closeModalAction());
    } else {
      setCartSheetIsOpen(true);
      dispatch(updateModalAction(Modal.CartCheck));
    }
  }, [dispatch, cartSheetIsOpen, setCartSheetIsOpen]);
  const postOrder = useCallback(() => {
    (async () => {
      await dispatch(operations.postOrder({ loadingMessage: t({ id: 'self.ordering' }) }));
      setCartSheetIsOpen(false);
    })();
  }, [dispatch, t, setCartSheetIsOpen]);

  useConfirmCheckIn();

  useSubscribeActivePlan();

  return (
    <HomeComponent
      shopName={shop.name}
      tableUser={tableUser}
      paymentType={paymentType}
      disableCartConfirmButton={!canOrder}
      showCartConfirmButton={selfOrderCount === 0 && otherOrderCount === 0}
      onClickCartConfirmButton={cartSheetIsOpen ? postOrder : toggleCartSheet}
      selfOrderCount={selfOrderCount}
      otherOrderCount={otherOrderCount}
      cartSheetIsOpen={cartSheetIsOpen && canOpenCartSheet}
      onOpenCartSheet={() => {}}
      onCloseCartSheet={toggleCartSheet}
      showSubmitText={cartSheetIsOpen}
    />
  );
};

export default Home;
