import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from '../../../../store/app/state';
import CompletePopup from '../complete_popup/container';

const SignInCompletePopup: React.FC = () => (
  <CompletePopup
    name={Popup.SignInComplete}
    text={<FormattedMessage id="self.organisms.signInCompletePopup.completedLogin" />}
  />
);

export default SignInCompletePopup;
