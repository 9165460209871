import 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../store/store';
import { deleteCurrentErrorAction } from '../store/app/actions';
import React from 'react';
import { selectIsLoading, selectLocale, selectShop, selectTableInfo } from '../store/selecter';
import { AppComponent } from './AppComponent';
import Config from '../config/Config';
import State from '../store/state';
import { createCheckInRoute } from './routes';
import { useInitialize } from './hooks/useInitialze';

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const loadingText = useSelector<State, string>(state => state.app.loadingFlagText || '');
  const locale = useSelector(selectLocale);
  const shop = useSelector(selectShop);
  const tableInfo = useSelector(selectTableInfo);

  useInitialize();

  return (
    <AppComponent
      loadingText={loadingText}
      stripeKey={Config.STP_KEY}
      deleteError={() => dispatch(deleteCurrentErrorAction())}
      isLoading={isLoading}
      locale={locale}
      isMaintenance={Boolean(shop.isMaintenance)}
      maintenanceMessage={shop.maintenanceText ?? ''}
      back={tableInfo ? () => history.replace(createCheckInRoute(tableInfo)) : undefined}
    />
  );
};
