import React from 'react';
import CardVisa from '../../../assets/images/visa.svg';
import CardJCB from '../../../assets/images/JCB.svg';
import CardMaster from '../../../assets/images/mastercard.svg';
import CardAmerican from '../../../assets/images/americanExpress.svg';
import { StpCard } from '../../../data/entity/StpCard';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  cardNumberColumn: {
    marginLeft: '10px',
    letterSpacing: '1px',
  },
});

function getCardLogo(brand: string) {
  switch (brand) {
    case 'Visa':
      return CardVisa;
    case 'MasterCard':
      return CardMaster;
    case 'American Express':
      return CardAmerican;
    case 'JCB':
      return CardJCB;
    default:
      return undefined;
  }
}

export const CardRegistered: React.FC<{ cardInfo: StpCard }> = props => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img src={getCardLogo(props.cardInfo.brand)} style={{ margin: '0 10px 0 0' }} />
      <Typography variant={'h3'} className={classes.cardNumberColumn}>
        ----
      </Typography>
      <Typography variant={'h3'} className={classes.cardNumberColumn}>
        ----
      </Typography>
      <Typography variant={'h3'} className={classes.cardNumberColumn}>
        ----
      </Typography>
      <Typography variant={'h3'} className={classes.cardNumberColumn}>
        {props.cardInfo.last}
      </Typography>
    </div>
  );
};
