import ReactGA from 'react-ga';
import { Modal } from '../../../store/app/state';

export const modalGa = (modalName: Modal | string) => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    ReactGA.modalview(modalName);
  }
};

const buttonGa = (actionName: GaActions) => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    ReactGA.event({ action: actionName, category: 'button' });
  }
};

enum GaActions {
  SIGNOUT = 'signout',
  INCREMENT_ITEM = 'increment_item',
  DECREMENT_ITEM = 'decrement_item',
  ADD_CART = 'add_cart',
  ITEM_DETAIL_CLOSE = 'item_detail_close',
  TO_CART = 'to_cart',
  ORDER = 'order',
  TWITTER_LOGIN = 'twitter_login',
  LINE_LOGIN = 'line_login',
  MAIL_SIGNUP = 'mail_signup',
  MAIL_LOGIN = 'mail_login',
}

export class EventGa {
  static signout() {
    buttonGa(GaActions.SIGNOUT);
  }

  static incrementItem() {
    buttonGa(GaActions.INCREMENT_ITEM);
  }

  static decrementItem() {
    buttonGa(GaActions.DECREMENT_ITEM);
  }

  static closeItemDetail() {
    buttonGa(GaActions.ITEM_DETAIL_CLOSE);
  }

  static itemDetailClose() {
    buttonGa(GaActions.ITEM_DETAIL_CLOSE);
  }

  static addCart() {
    buttonGa(GaActions.ADD_CART);
  }

  static order() {
    buttonGa(GaActions.ORDER);
  }

  static lineLogin() {
    buttonGa(GaActions.LINE_LOGIN);
  }

  static twitterLogin() {
    buttonGa(GaActions.TWITTER_LOGIN);
  }

  static mailLogin() {
    buttonGa(GaActions.MAIL_LOGIN);
  }

  static mailSignup() {
    buttonGa(GaActions.MAIL_SIGNUP);
  }
}
