import { selectTableUser, selectIsLoggedIn } from '../../store/selecter';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { operations } from '../../store/operations';
import { useAppDispatch } from './useAppDispatch';

// NOTE: あるプランが有効になったとき排他的な関係にあるプランを無効にしなければならない。そのために有効プランを監視して、それが更新されたときにプランを取得し直す
export const useSubscribeActivePlan = () => {
  const tableUser = useSelector(selectTableUser);
  const activePlanIds = tableUser?.activePlans?.map(plan => plan.planId) ?? [];
  const dispatch = useAppDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (isLoggedIn && (activePlanIds?.length ?? 0) > 0) {
      dispatch(operations.updateCategoryPlans());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, dispatch, activePlanIds.join(',')]);
};
