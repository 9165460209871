import React, { useEffect } from 'react';
import { Component } from './component';
import { useQuery } from '../../hooks/useQuery';
import { useHistory, useLocation } from 'react-router';
import { Routes } from '../../routes';
import { injector } from '../../../data/injector';
import { convertFirebaseIdToShopId } from '../../../libs/convertFirebaseIdToShopId';

export const DefaultPage = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    /**
     * LINE Messaging で送れる URL が liff.line.me の URL だけなので Gateway を通せない。
     * 代わりに redirectTo が設定されたときにフロントから無理やりリダイレクトする。
     */
    const redirectTo = query.get('redirectTo');

    if (redirectTo) {
      window.location.href = decodeURIComponent(redirectTo);
      return;
    }

    /**
     * https://liff.line.me/1653669701-VJY29kYb/payment_completed というような URL へアクセスすると
     * https://web.staging1.self.priv.dinii.jp/?liff.state=%2Fpayment_completed へ遷移する。
     * その後 liff.init すると liff.state が解釈され https://web.staging1.self.priv.dinii.jp/payment_completed に
     * 遷移するが、そのためには liffId が必要になる。ダイニーでは liffId が固定ではないためこれが結構難しい。
     * そのため、liff.state に payment_completed が指定されていた場合は無理やり /payment_completed に遷移させてしまうことにする。
     * 幸いにも /payment_completed はサインインが必要ないので問題はないはず。FUCK
     */
    if (decodeURIComponent(query.get('liff.state') ?? '') === '/payment_completed') {
      history.replace('/payment_completed');
      return;
    }

    const companyId = Number(query.get('companyId'));
    const shopId = convertFirebaseIdToShopId(query.get('shopId') ?? '');

    (async () => {
      if (companyId && shopId) {
        const companyConfig = await injector.apiClient.getCompanyConfig(companyId, shopId);

        await liff.init({ liffId: companyConfig.liffId });
      }

      switch (query.get('navigateTo')) {
        case 'receipt':
          history.replace(`${Routes.checkIn}${location.search}`);
          break;
        case 'home':
          history.replace(`${Routes.checkIn}${location.search}`);
          break;
        case 'payment_completed':
          history.replace(`${Routes.paymentCompleted}`);
          break;
        default:
          if (query.get('tableId')) {
            history.replace(`${Routes.checkIn}${location.search}`);
          } else {
            history.push(Routes.noTableInfo);
          }
      }
    })();
    // eslint-disable-next-line
  }, []);

  return <Component />;
};
