import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'firebase/app';

type State = {
  initialized: boolean;
  user: User | null;
};

export type FirebaseUserState = State;

const initialState: State = { initialized: false, user: null };

export type UpdateUserActionPayload = {
  user: User | null;
};

const slice = createSlice({
  name: 'FIREBASE_USER',
  initialState,
  reducers: {
    resetUser(state: State) {
      state.user = null;
    },
    updateUser(state: State, action: PayloadAction<UpdateUserActionPayload>) {
      state.user = action.payload.user;
      state.initialized = true;
    },
  },
});

export const firebaseUserActions = slice.actions;
export const firebaseUserReducer = slice.reducer;
