import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentType } from '../../data/entity/PaymentType';
import { Shop } from '../../data/entity/Shop';
import { ShopMaintenanceInfo } from '../../data/entity/ShopMaintenance';

export type ShopState = Partial<Shop> & Partial<ShopMaintenanceInfo>;

export const initialState: ShopState = {
  isMaintenance: false,
  paymentType: PaymentType.All,
};

const slice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    fetchShop(_, __: PayloadAction<{ shopId: string }>) {
      // saga's trigger
    },
    updateShop(state: ShopState, action: PayloadAction<Partial<ShopState>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const shopActions = slice.actions;
export const shopReducer = slice.reducer;
