import React from 'react';
import {
  Drawer,
  createStyles,
  makeStyles,
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { ZIndexSettings } from '../../../config/ZIndexSettings';
import styled from 'styled-components';
import { InformativeCartItem } from '../../../../store/selecter';
import { AmountWithAction } from './AmountWithAction';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean;
  onOpen(): void;
  onClose(): void;
  cartItems: InformativeCartItem[];
  shouldShowAvatar: boolean;
  onClickIncreaseButton(cartItem: InformativeCartItem): void;
  onClickDecreaseButton(cartItem: InformativeCartItem): void;
  title?: string;
  subTitle?: string;
}

export const CartSheet: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  cartItems,
  shouldShowAvatar,
  onClickIncreaseButton,
  onClickDecreaseButton,
  title,
  subTitle,
}) => {
  const styles = useStyles();
  const items = cartItems.map((cartItem, index) => (
    <React.Fragment key={index}>
      <ListItem>
        {shouldShowAvatar && (
          <ListItemAvatar className={styles.avatarContainer}>
            <Avatar
              alt={cartItem.userName}
              src={cartItem.userProfileImage}
              className={styles.avatar}
            />
          </ListItemAvatar>
        )}
        <ListItemText primary={cartItem.menuName} secondary={cartItem.optionText} />
        <AmountWithAction
          count={cartItem.quantity}
          onClickDecreaseButton={() => onClickDecreaseButton(cartItem)}
          onClickIncreaseButton={() => onClickIncreaseButton(cartItem)}
        />
        <Price>
          <Typography align="right">
            ¥{Number(cartItem.unitPriceWithOption) * cartItem.quantity || cartItem.priceAll}
          </Typography>
        </Price>
      </ListItem>
      <Divider />
    </React.Fragment>
  ));

  return (
    <Drawer
      open={open}
      PaperProps={{
        className: styles.sheet,
      }}
      closeAfterTransition={true}
      variant={'temporary'}
      onRendered={onOpen}
      anchor={'bottom'}
      onClose={onClose}
      className={styles.container}
    >
      <Content>
        <Header>
          <Typography variant="h2" style={{ fontWeight: 'bold', margin: 12 }}>
            {title ? title : <FormattedMessage id="self.home.cartSheet.cartTitle" />}
          </Typography>
          {subTitle ? <Typography variant="body1">{subTitle}</Typography> : null}
          <CloseButton onClick={onClose} />
        </Header>
        <ListContainer>
          <List className={styles.list}>{items}</List>
        </ListContainer>
      </Content>
    </Drawer>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      zIndex: ZIndexSettings.MODAL,
    },
    sheet: {
      position: 'absolute',
      left: 0,
      right: 0,
      width: '95%',
      margin: 'auto',
      maxHeight: '50vh',
      bottom: 'calc(120px + env(safe-area-inset-bottom))',
      borderRadius: '5px',
    },
    list: {
      width: '100%',
    },
    avatar: {
      width: 30,
      height: 30,
    },
    avatarContainer: {
      minWidth: 46,
    },
  }),
);

const Content = styled.div`
  max-height: 50vh;
  width: 100%;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 1;
  flex-direction: column;
  padding: 22px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const ListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: scroll;
  align-items: center;
  width: 100%;
`;

const Price = styled.div`
  width: 48px;
  flex-shrink: 0;
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 14px;
  right: 14px;
`;
