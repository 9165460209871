import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SelectNumberOfTableUser } from './component';
import {
  selectLocale,
  selectTableInfo,
  selectShouldInputNumberOfTableUser,
} from '../../../store/selecter';
import { injector } from '../../../data/injector';
import ApiClient from '../../../data/api/ApiClient';
import { FirebaseClient } from '../../../data/firebase/FirebaseClient';
import { useIntl } from 'react-intl';
import { useLoader } from '../../hooks/useLoader';
import { useHandleError } from '../../hooks/useHandleError';
import { Routes } from '../../routes';
import { push } from 'connected-react-router';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { userActions } from '../../../store/ducks/user';

const MAXIMUM_NUMBER_OF_TABLE_USER = 16;

/**
 * APIClient は外に出しておく
 */
const useNumberOfTableUser = ({
  apiClient,
  firebaseClient,
}: {
  apiClient: ApiClient;
  firebaseClient: FirebaseClient;
}) => {
  const { formatMessage: f } = useIntl();
  const locale = useSelector(selectLocale);
  const tableInfo = useSelector(selectTableInfo);
  const { showLoader, isLoading, dismissLoader } = useLoader();
  const handleError = useHandleError();
  const [numberOfTableUser, setNumberOfTableUser] = useState<number | undefined>(undefined);
  const numberOfTableUserOptions = new Array(MAXIMUM_NUMBER_OF_TABLE_USER)
    .fill(0)
    .map((_, i) => i + 1);
  const dispatch = useAppDispatch();

  const confirmNumberOfTableUser = useCallback(() => {
    (async () => {
      if (isLoading) return;
      if (!tableInfo) return;

      try {
        showLoader(f({ id: 'self.molecules.numPeopleSelector.updating' }));

        const idToken = await firebaseClient.getIdToken();

        if (numberOfTableUser) {
          try {
            if (liff && liff.isLoggedIn()) {
              await apiClient.confirmCheckIn(idToken, tableInfo, locale);
              dispatch(userActions.receiveLineMessage());
            }
          } catch (e) {
            // NOTE: LINE のバグで LIFF ログインに失敗することがあるらしい。失敗しても致命的なエラーにはなりえないので無視する。
          }

          await apiClient.updateTableUser(idToken, tableInfo, locale, numberOfTableUser);
        }
      } catch (e) {
        handleError(e);
      } finally {
        dismissLoader();
      }
    })();
  }, [
    isLoading,
    tableInfo,
    showLoader,
    f,
    firebaseClient,
    numberOfTableUser,
    apiClient,
    locale,
    dispatch,
    handleError,
    dismissLoader,
  ]);

  return {
    numberOfTableUser,
    setNumberOfTableUser,
    numberOfTableUserOptions,
    confirmNumberOfTableUser,
  };
};

/**
 * 他のユーザーが入力を完了した結果、入力が不要になるというパターンもあるのでストアの状況に応じて次の画面に遷移する
 */
const useNavigateIfInputNumberOfTableUserIsFullfilled = () => {
  const dispatch = useDispatch();
  const shouldInputNumberOfTableUser = useSelector(selectShouldInputNumberOfTableUser);

  useEffect(() => {
    if (!shouldInputNumberOfTableUser) {
      dispatch(push(Routes.home));
    }
  }, [dispatch, shouldInputNumberOfTableUser]);
};

export const SelectNumberOfTableUserPage: React.FC = () => {
  const {
    confirmNumberOfTableUser,
    numberOfTableUser,
    setNumberOfTableUser,
    numberOfTableUserOptions,
  } = useNumberOfTableUser(injector);

  useNavigateIfInputNumberOfTableUserIsFullfilled();

  return (
    <SelectNumberOfTableUser
      confirmNumberOfTableUser={confirmNumberOfTableUser}
      numberOfTableUser={numberOfTableUser}
      setNumberOfTableUser={setNumberOfTableUser}
      numberOfTableUserOptions={numberOfTableUserOptions}
    />
  );
};
