import { useCallback, Dispatch, useEffect } from 'react';
import State from '../../../store/state';
import { useSelector, useDispatch } from 'react-redux';
import { Action } from '../../../store/action';

export const useModal = <T extends string>(
  name: T,
  selector: (state: State) => T,
  updateAction: (name: T) => Action,
  closeAction: () => Action,
  shouldOpen?: boolean,
) => {
  const modal = useSelector(selector);
  const dispatch = useDispatch<Dispatch<Action>>();

  const isControlled = shouldOpen !== undefined;
  const isOpen = name === modal;

  const onClose = useCallback(() => {
    if (!isControlled) {
      dispatch(closeAction());
    }
  }, [isControlled, dispatch, closeAction]);

  useEffect(() => {
    if (isControlled && shouldOpen !== isOpen) {
      if (shouldOpen) {
        dispatch(updateAction(name));
      } else {
        dispatch(closeAction());
      }
    }
  }, [isControlled, shouldOpen, isOpen, dispatch, updateAction, name, closeAction]);

  return { isOpen, onClose };
};
