import { CheckInComponent } from './component';
import { injector } from '../../../data/injector';
import { userActions } from '../../../store/ducks/user';
import { Routes } from '../../routes';
import React, { Dispatch, useEffect, useState } from 'react';
import { getLanguageCode } from '../../../util/language';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as H from 'history';
import { Action } from '../../../store/action';
import { tableUserRef, usersRef } from '../../../libs/firebaseApp';
import * as Sentry from '@sentry/browser';
import { selectLocale } from '../../../store/selecter';
import { tableInfoActions } from '../../../store/ducks/tableInfo';
import { menuActions } from '../../../store/ducks/menu';
import { CompanyConfig } from '../../../data/entity/CompanyConfig';
import { Locale } from '../../../i18n';
import { TableUser } from '../../../data/entity/TableUser';
import { PaymentType } from '../../../data/entity/PaymentType';
import { TableUserStateValue } from '../../../data/entity/TableUserState';
import { tableUserActions } from '../../../store/ducks/tableUser';
import { CheckInResponse } from '../../../data/api/response/CheckInResponse';
import { useHandleError } from '../../hooks/useHandleError';
import { useQuery } from '../../hooks/useQuery';
import { shopActions } from '../../../store/ducks/shop';
import { convertFirebaseIdToShopId } from '../../../libs/convertFirebaseIdToShopId';

const liff = window.liff;

const initLiff = async (companyId: number, shopId: string): Promise<string> => {
  const companyConfig = new CompanyConfig(
    await injector.apiClient.getCompanyConfig(companyId, shopId),
  );
  try {
    // NOTE: initされたかどうかを判定するために、適当なAPIを呼んでいる
    liff.isLoggedIn();
  } catch (e) {
    await liff.init(
      { liffId: companyConfig.liffId },
      async () => {
        console.log('success');
      },
      (error: any) => console.log(error),
    );
    // NOTE: 大丈夫か？
    // NOTE: liff.state がおかしいとエラーが出るがうまく動く
    console.log(e);
  }
  return companyConfig.liffId;
};

const fetchAndUpdateProfile = async (): Promise<void> => {
  try {
    const profile = await liff.getProfile();
    await Promise.all([
      usersRef(`line:${profile.userId}`).set(profile, {
        merge: true,
      }),
      injector.firebaseClient.updateUserProfile(profile.displayName, profile.pictureUrl),
    ]);
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
};

const customLineSignIn = async (companyId: number, accessToken: string) => {
  if (!injector.firebaseClient.currentUser()) {
    const customTokenResponse = await injector.apiClient.getLineFirebaseToken(
      companyId,
      accessToken,
    );
    await injector.firebaseClient.signInWithCustomToken(customTokenResponse.customFirebaseToken);
  }
};

const signIn = async (
  companyId: number,
  shopId: string,
  lineToken: string | null,
  dispatch: Dispatch<Action>,
  history: H.History,
  setLoadingText: (text: string) => void,
  query: URLSearchParams,
) => {
  if (!companyId) {
    history.replace(Routes.noTableInfo);
    return;
  }

  const liffId = await initLiff(companyId, shopId);

  const defaultLanguage = getLanguageCode();
  dispatch(userActions.updateLocale({ locale: defaultLanguage }));
  setLoadingText(defaultLanguage === Locale.Ja ? 'チェックイン中..' : 'checking in..');

  if (liff.isInClient()) {
    try {
      await customLineSignIn(companyId, liff.getAccessToken());
      await fetchAndUpdateProfile();
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  } else {
    if (injector.firebaseClient.currentUser()) {
      console.log('already logged in');
    } else if (lineToken) {
      await injector.firebaseClient.signInWithCustomToken(lineToken);
      await fetchAndUpdateProfile();
      console.log('login success with line');
    } else if (liff.isLoggedIn()) {
      await customLineSignIn(companyId, liff.getAccessToken());
      await fetchAndUpdateProfile();
      console.log('login success with liff');
    } else {
      history.push(encodeURI(`${Routes.mailSignUp}?${query.toString()}`));
      return;
    }
  }

  const idToken = await injector.firebaseClient.getIdToken();

  return { idToken, liffId };
};

const checkIn = async (
  companyId: number,
  shopId: string,
  tableId: number,
  idToken: string,
  liffId: string,
  dispatch: Dispatch<Action>,
  setLoadingText: (text: string) => void,
): Promise<CheckInResponse | undefined> => {
  const defaultLanguage = getLanguageCode();

  setLoadingText(defaultLanguage === Locale.Ja ? 'メニュー読み込み中..' : 'loading menu..');

  const checkInResponse = await injector.apiClient.checkIn(
    companyId,
    shopId,
    tableId,
    idToken,
    defaultLanguage,
  );

  dispatch(shopActions.updateShop(checkInResponse.shop));
  dispatch(menuActions.updateAllMenus({ categoryMenus: checkInResponse.categoryMenus }));
  dispatch(menuActions.updateAllCategoryPlans({ categoryPlans: checkInResponse.categoryPlans }));
  dispatch(userActions.updateCards({ cards: checkInResponse.cards }));

  return checkInResponse;
};

// TODO: Refactor
const initializeDataAndNavigate = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  shopId: string,
  tableUserId: string,
  paymentType: PaymentType,
  navigateTo: string | null,
) => {
  const tableUserDoc = await tableUserRef(shopId, tableUserId).get();

  // TODO: add validation
  const tableUser = tableUserDoc.data() as TableUser;

  dispatch(tableUserActions.updateTableUser({ tableUser }));

  if (navigateTo) {
    history.replace(navigateTo);
    return;
  }

  switch (paymentType) {
    case PaymentType.All: {
      if (tableUser.tableUserState === TableUserStateValue.Active && tableUser.numPeople === 0) {
        history.replace(Routes.selectNumberOfTableUser);
      } else {
        history.replace(Routes.home);
      }

      break;
    }
    case PaymentType.Each:
      history.replace(Routes.home);
      break;
  }
};

// NOTE: 地獄
export const CheckIn: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(selectLocale);
  const query = useQuery();
  const handleError = useHandleError();

  const [loadingText, setLoadingText] = useState(
    locale === Locale.Ja ? 'チェックイン中' : 'checking in',
  );

  const companyId = query.get('companyId');
  // NOTE: 本来の shopId ではなく、Firestore に Shop があったころの名残りの ID
  const shopId = convertFirebaseIdToShopId(query.get('shopId') ?? '');
  const tableId = query.get('tableId');
  const lineToken = query.get('lineToken');
  const tableUserId = query.get('tableUserId');
  const navigateTo = decodeURIComponent(query.get('navigateTo') || '');

  useEffect(() => {
    (async () => {
      try {
        /**
         * initialize table info
         */
        if (companyId === null || shopId === null) {
          history.replace(Routes.noTableInfo);
          return;
        }

        dispatch(
          tableInfoActions.updateTableInfo({
            tableInfo: {
              liffId: '-1',
              companyId: Number(companyId),
              shopId, // NOTE check inに失敗してもshopを更新するため、ここでtableInfoをupdateしている
              tableId: Number(tableId),
              tableUserId: '',
            },
          }),
        );

        /**
         * sign in
         */
        const signInResponse = await signIn(
          Number(companyId),
          shopId,
          lineToken,
          dispatch,
          history,
          setLoadingText,
          query,
        );

        // TODO: signIn 関数の中ではなくここでエラーハンドリングをする。というか operations に出す。
        if (!signInResponse) {
          return;
        }

        if (!shopId) {
          throw new Error('shopId is not specified as a query parameter');
        }

        if (!tableId && tableUserId) {
          await initializeDataAndNavigate(
            dispatch,
            history,
            shopId,
            tableUserId,
            PaymentType.Each,
            navigateTo,
          );
          return;
        }

        const checkInResponse = await checkIn(
          Number(companyId),
          shopId,
          Number(tableId),
          signInResponse.idToken,
          signInResponse.liffId,
          dispatch,
          setLoadingText,
        );

        // TODO: checkIn 関数の中ではなくここでエラーハンドリングをする。というか operations に出す。
        if (!checkInResponse) {
          return;
        }

        // FIXME: ここでやらなくてもいいかな…
        dispatch(
          tableInfoActions.updateTableInfo({
            tableInfo: {
              companyId: Number(companyId),
              shopId: checkInResponse.shop.shopId,
              tableId: Number(tableId),
              tableUserId: checkInResponse?.tableUserId ?? '',
              liffId: signInResponse.liffId,
            },
          }),
        );

        await initializeDataAndNavigate(
          dispatch,
          history,
          checkInResponse.shop.shopId,
          checkInResponse?.tableUserId ?? '',
          checkInResponse?.shop.paymentType!,
          navigateTo,
        );
      } catch (e) {
        Sentry.captureException(e);
        handleError(e);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return <CheckInComponent loadingText={loadingText} close={() => liff.closeWindow()} />;
};
