const shops = [
  {
    firebaseId: '5H4CHTiiUibNxBROoUO6',
    name: 'EBISU FOOD HALL',
    shopId: 'e78f9c8b-a743-4cd0-a582-6ff3f2de684c',
  },
  {
    firebaseId: 'EuyrS7pxd7W3nN478f4J',
    name: '上海湯包',
    shopId: '449a174b-435b-4239-802d-c8baf710f501',
  },
  {
    firebaseId: 'L3KOv5MCjP8EFiosTQk6',
    name: '開発用店舗',
    shopId: '91b3b600-5fea-42ed-8de2-9b3eaa399ff1',
  },
  {
    firebaseId: 'VEZC3a6XTCk61RNEj5Q0',
    name: 'test',
    shopId: '603dd057-6455-4a99-9381-64540c822602',
  },
  {
    firebaseId: '1FJSFNV8tyN5CwKZoTai',
    name: '[デモ]AUREOLE',
    shopId: '1f95c58a-c817-4fee-a31c-372307daa88e',
  },
  {
    firebaseId: '1g1aRc1VRKZ63IPU9joK',
    name: '[SA] ホテレス店舗',
    shopId: 'd7dc43a5-d5da-486c-8258-3343615b92cd',
  },
  {
    firebaseId: '5FSBplkCIAtnt2rPNzYp',
    name: '[デモ] 鍋ぞう',
    shopId: '1396d0a3-e4ce-4fa0-812e-afd2c5983b15',
  },
  {
    firebaseId: 'PTYF47JE0ElvU6kNm6sY',
    name: '[デモ] 焼肉韓菜 福寿',
    shopId: '8f4ee91f-078f-4718-a7a8-4b4694945b28',
  },
  {
    firebaseId: 'SA3u8MBvJ736umDuRiS6',
    name: 'てんぷら味覚レジテスト',
    shopId: '7e663e7b-54c6-4cd7-ac99-c82c669b0039',
  },
  {
    firebaseId: 'aKpZiA4ZI4XOU5A24roK',
    name: '[デモ] 串カツ田中',
    shopId: '6822ff9c-87fc-45b9-8010-498699a761e2',
  },
  {
    firebaseId: 'bNeB4X8ZEgBtQzVcgoVj',
    name: '[デモ]GOSSO様',
    shopId: '1bcfe173-bbe9-443e-a849-b944c7e13461',
  },
  {
    firebaseId: 'gdjeY9wTsrLdQiP5IJFU',
    name: '[一括]ダイニーデモ',
    shopId: '1b7ee817-a758-4316-beb6-a764d4c7c6d3',
  },
  {
    firebaseId: 'hixMGSX2tNItMNc1vNbX',
    name: '[都度]ダイニーデモ',
    shopId: '9f6e747d-f9c9-499d-beab-dbc26359ae3c',
  },
  {
    firebaseId: 'iylmj3ZOaZWYkgGG4p1R',
    name: '[デモ] 焼肉キング',
    shopId: '6493dec8-3dd0-43a1-ad99-50a7f08446b3',
  },
  {
    firebaseId: 'zDaE7y8SNUqaqZW2570S',
    name: 'デモ店舗',
    shopId: '31a8d1d6-7f77-49a5-a592-5446ca769e43',
  },
  {
    firebaseId: 'nXWeaTIPiCO7uF3CWrKZ',
    name: 'たまる',
    shopId: '3e58d404-de1f-4df4-b66a-74f39c8d4acb',
  },
  {
    firebaseId: 'Fo4919U7JZtMji788MTb',
    name: 'mini_registration都度',
    shopId: '04297701-5fd3-41eb-b53c-dfb0d4b8f254',
  },
  {
    firebaseId: 'jeNbTz3hTyhnxDww5lYJ',
    name: 'mini_registration 一括',
    shopId: '7efeb6dd-04cd-4dd6-9671-19ef1331762e',
  },
  {
    firebaseId: 'C7DTtz6zsaYoyrp1zr8x',
    name: 'オリオール',
    shopId: '02546dde-a226-492b-aaae-e91f4b22efc6',
  },
  {
    firebaseId: 'BO0vm0fdEivjlIfaFKTI',
    name: '[SA] ホテレス展示会用店舗',
    shopId: 'b86118e9-7d74-42bd-b010-a3cecb8db2fe',
  },
  {
    firebaseId: 'myqhIJ81cC6fCydELkAy',
    name: 'ホテレス展示会用店舗',
    shopId: 'eddefe2b-0592-4141-a676-3d1ab11e4e56',
  },
  {
    firebaseId: 'MeAJloj5x6oBCaw7dZeI',
    name: '和食さくら',
    shopId: '553457a6-5c8e-4b57-af91-db9cdc2f0e17',
  },
  {
    firebaseId: '7yvj4KuSj9RHo31simfL',
    name: '福寿　秋葉原店',
    shopId: '5a9a1de2-c7ae-46e1-8190-2e47a28297c9',
  },
  {
    firebaseId: 'NoVb1xWwEuIT4WMvZyAJ',
    name: '受け入れ試験店舗',
    shopId: '869853b4-be06-43c4-a26a-1c9be034a6ab',
  },
  {
    firebaseId: 'GS8zFE7YGXMqoSCUKDU0',
    name: 'クルーズクルーズ新宿',
    shopId: 'b8dabaf1-ea77-4a09-a182-2f65902d43b0',
  },
  {
    firebaseId: 'TXKuzE9yjsuC6ntggIFA',
    name: '但馬屋ヨドバシ博多店',
    shopId: 'd5d8d376-3fa4-4634-b171-edbdb5e21637',
  },
  {
    firebaseId: 'ToD8pXm5EZR9eqF9iHQv',
    name: '但馬屋KITTE店',
    shopId: 'd2139dce-9387-4061-be7f-1415e6b32a2c',
  },
  {
    firebaseId: 'x8lYzvhNaXlrgc6M4ZF9',
    name: '渋谷ガーデンファーム',
    shopId: 'ada7feb6-d9ef-4a9a-9139-50f1f5ac933e',
  },
];

// NOTE: shop を Firebase から RDB に移した際に ID が変更になった。これは旧来の ID (Firebase ID) と新しい ID を変換するためのマッピング
export const convertFirebaseIdToShopId = (firebaseIdOrShopId: string) => {
  const shop = shops.find(shop => shop.firebaseId === firebaseIdOrShopId);

  return shop ? shop.shopId : firebaseIdOrShopId;
};
