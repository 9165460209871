import { PaymentType } from './PaymentType';

export enum TaxMethod {
  Included = 'included',
  Excluded = 'excluded',
  NonTaxable = 'nonTaxable',
}

export interface Shop {
  shopId: string;
  companyId: string;
  name: string;
  nameEn: string;
  open: string;
  close: string;
  maxNum: number;
  maxNumForNumPeople: number;
  taxMethod: TaxMethod;
  taxRate: number;
  topImageUrl: string;
  naviImageUrl: string;
  defaultMenuImageUrl: string;
  paymentType: PaymentType;
  lastOrderText?: string;
  changeDateTime: string;
  shopRebootTime: string;
  shopCloseConectionTime: string;
  enableAlert: boolean;
  orderIntervalSeconds?: number;
  frebaseId: string;
  issuer?: string;
  contactInfo?: string;
  enableRemark?: boolean;
  disableOnlinePayment?: boolean;
  disableOfflinePayment?: boolean;
  disableCallStaff?: boolean;
}
