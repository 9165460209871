import React from 'react';
import ConfirmModal from '../confirm_modal/confirm_modal';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../../../store/app/state';

export class ConfirmCheckInModalComponent extends React.Component<IProps> {
  render() {
    return (
      <ConfirmModal
        name={Modal.ConfirmCheckIn}
        title={<FormattedMessage id="self.organisms.confirmCheckInModal.title" />}
        message={<FormattedMessage id="self.organisms.confirmCheckInModal.message" />}
        onClickConfirm={this.props.onClickConfirm}
      />
    );
  }
}
interface IProps {
  onClose: () => void;
  onClickConfirm: () => void;
}
