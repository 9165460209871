import React, { useState, useCallback, ChangeEvent, useMemo } from 'react';
import styled from 'styled-components';
import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import { Page } from '../Page';
import { Menu } from '../../../data/entity/menu';
import { hiraganaToKatakana } from '../../../util/language';
import { AppBarComponent } from './app_bar/component';
import { MenuComponent } from './menu/component';

type Props = {
  menus: [string, Menu][];
  onClickMenuItem: (categoryId: number, menuId: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    offset: theme.mixins.toolbar,
    search: {
      display: 'flex',
      position: 'relative',
      borderRadius: 32,
      backgroundColor: fade(theme.palette.common.black, 0.1),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.15),
      },
      width: '100%',
      height: 32,
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      width: '100%',
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
    },
  }),
);

export const Search: React.FC<Props> = ({ menus, onClickMenuItem }) => {
  const classes = useStyles();
  const [keyword, setKeyword] = useState('');
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      setKeyword(value);
    },
    [setKeyword],
  );

  const filteredMenus = useMemo(() => {
    if (!keyword) return [];

    return menus
      .filter(([name]) => name.includes(hiraganaToKatakana(keyword)))
      .map(([_, menu]) => menu)
      .filter(({ isDisplay }) => isDisplay);
  }, [keyword, menus]);

  return (
    <Page>
      <div className={classes.root}>
        <AppBarComponent keyword={keyword} onChange={handleChange} />
        <Wrapper>
          <div className={classes.offset} />
          <MenuComponent menus={filteredMenus} onClickMenuItem={onClickMenuItem} />
        </Wrapper>
      </div>
    </Page>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  overflow: scroll;
`;
