import { useAppDispatch } from './useAppDispatch';
import { operations } from '../../store/operations';
import { useEffect } from 'react';

export const useSubscribeFirebaseAuth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return dispatch(operations.subscribeFirebaseAuth());
  }, [dispatch]);
};
