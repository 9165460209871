import { subscribeCart } from './subscribeCart';
import { subscribeOrders } from './subscribeOrders';
import { subscribeShopMaintenance } from './subscribeShopMaintenance';
import { subscribeTableUser } from './subscribeTableUser';
import { subscribeFirebaseAuth } from './subscribeFirebaseAuth';
import { startPlan } from './startPlan';
import { postOrder } from './postOrder';
import { updateCategoryPlans } from './updateCategoryPlans';

export const operations = {
  subscribeCart,
  subscribeOrders,
  subscribeShopMaintenance,
  subscribeTableUser,
  subscribeFirebaseAuth,
  startPlan,
  postOrder,
  updateCategoryPlans,
};
