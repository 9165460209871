import { TableInfo } from '../data/entity/TableInfo';

export enum Routes {
  default = '/',
  oldCheckIn = '/:companyId(com_\\d{3})/:shopId/:tableId',
  checkIn = '/check_in',
  otherCheckIn = '/other_check_in',
  home = '/home',
  menuDetail = '/menu/:categoryId/:menuId',
  planDetail = '/plan/:planCategoryId/:planId',
  orderHistory = '/order_history',
  orderCompleted = '/order_completed',
  account = '/account',
  addCard = '/add_card_form',
  aboutMobileOrder = '/about_mo',
  faq = '/faq',
  receipt = '/receipt',
  selectNumberOfTableUser = '/select_number_of_table_user',
  selectPaymentMethod = '/select_payment_method',
  registerCard = '/register_card',
  paymentCompleted = '/payment_completed',
  mailSignIn = '/mail_sign_in',
  mailSignUp = '/mail_sign_up',
  noTableInfo = '/no_table_info',
  search = '/search',
  error = '/error/:type',
}

export const createCheckInRoute = (tableInfo: TableInfo) =>
  `/check_in?companyId=${tableInfo.companyId}&shopId=${tableInfo.shopId}&tableId=${tableInfo.tableId}&liffId=${tableInfo.liffId}`;
