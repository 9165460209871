import React from 'react';
import styled from 'styled-components';
import CardVisa from '../../../assets/images/visa.svg';
import CardMaster from '../../../assets/images/mastercard.svg';
import CardAmerican from '../../../assets/images/americanExpress.svg';

export default class CardAll extends React.Component<IProps> {
  render() {
    return (
      <CardImageList>
        <img src={CardVisa} />
        {/* <Image src={CardJCB}/> */}
        <img src={CardMaster} />
        <img src={CardAmerican} />
        {/* <Image src={CardDiners}/> */}
      </CardImageList>
    );
  }
}

interface IProps {
  width?: string;
}

const CardImageList = styled.div`
  width: ${(props: IProps) => (props.width ? props.width : '50vw')};
  display: flex;
  justify-content: space-between;
  margin: auto;
`;
