import { handleError } from '../../errors';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

/**
 * `handleError` のラッパー
 */
export const useHandleError = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (error: Error) => handleError(error, dispatch, history);
};
