import { combineReducers } from 'redux';

import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { connectRouter, routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import appReducer from './app/reducer';
import { shopReducer } from './ducks/shop';
import { userReducer } from './ducks/user';
import { tableUserReducer } from './ducks/tableUser';
import ReactGA from 'react-ga';
import State from './state';
import Config from '../config/Config';
import { menuReducer } from './ducks/menu';
import { tableInfoReducer } from './ducks/tableInfo';
import { injector } from '../data/injector';
import { auth, shopMaintenanceRef, cartRef, tableUserRef, ordersRef } from '../libs/firebaseApp';
import { LinePayApiClient } from '../data/api/LinePayApiClient/v3';
import { Extra } from './thunk';
import { firebaseUserReducer } from './ducks/firebaseUser';

export const history = createBrowserHistory();

export const configureGlobalStore = () => {
  if (process.env.REACT_APP_SENTRY_ENV === 'production') {
    history.listen(({ pathname }) => {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    });
  }

  const persistKey = `dinii-self:${Config.VERSION}`;
  const persistConfig = {
    key: persistKey,
    storage,
    whitelist: ['tableInfo', 'userState', 'menu'],
  };

  const context: Extra = {
    history,
    diniiApiClient: injector.apiClient,
    firebaseApiClient: injector.firebaseClient,
    linePayApiClient: new LinePayApiClient({ baseURL: Config.LINE_PAY_API_BASE_URL }),
    auth,
    shopMaintenanceRef,
    cartRef,
    tableUserRef,
    ordersRef,
  };

  const store = configureStore({
    reducer: persistReducer(
      persistConfig,
      combineReducers<State>({
        app: appReducer,
        shop: shopReducer,
        userState: userReducer,
        tableUserState: tableUserReducer,
        menu: menuReducer,
        tableInfo: tableInfoReducer,
        router: connectRouter(history),
        firebaseUser: firebaseUserReducer,
      }),
    ),
    middleware: [routerMiddleware(history), thunk.withExtraArgument(context)],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};
