import 'url-search-params-polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './reset.css';
import './style.css';
import 'node-waves/dist/waves.min.css';
import 'node-waves/dist/waves.min.js';
import { configureGlobalStore } from './store/store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import Config from './config/Config';
import * as ReactGA from 'react-ga';
import { App } from './presentation/AppContainer';
import { auth } from './libs/firebaseApp';
import { PersistGate } from 'redux-persist/integration/react';

const uuidv4 = require('uuid/v4');

Sentry.init({
  dsn: Config.SENTRY,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

const { store, persistor } = configureGlobalStore();

const startApp = () => {
  const gaProject = process.env.REACT_APP_GA_CODE;
  if (gaProject) {
    ReactGA.initialize(gaProject);
    ReactGA.set(uuidv4());
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  ReactDOM.render(
    // TODO: Provider 系は App の中にまとめる（startAppの責務はrenderに必要な情報をまとめてappにわたすこと）
    <Provider store={store}>
      {/* TODO: redux-persist が全体的にうまく動いていない気がするので一旦無効化  */}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  );
};

const init = async () => {
  // TODO: Reduxで状態を管理していれば re-render する必要はないはず
  auth.onAuthStateChanged(() => {
    startApp();
  });
};

init();
