import React from 'react';
import Loader from './common/dinii_loader';
import { StripeProvider } from 'react-stripe-elements';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store/store';
import ModalManager from './components/organisms/modal_manager/component';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from './theme';
import { IntlProvider } from 'react-intl';
import { chooseLocale, Locale } from '../i18n';
import WarningImg from '../assets/images/warning.svg';
import ModalTitle from './components/atoms/modal_title';
import styled from 'styled-components';
import ErrorBoundary from './ErrorBoundary';
import { HistorySubscriber } from './HistorySubscriber';
import { Router } from './router';

export const AppComponent: React.FC<IProps> = (props: IProps) => {
  return (
    <ConnectedRouter history={history}>
      <StripeProvider apiKey={props.stripeKey}>
        <IntlProvider locale={props.locale} messages={chooseLocale(props.locale)}>
          <MuiThemeProvider theme={theme}>
            <ErrorBoundary deleteError={props.deleteError} locale={props.locale} back={props.back}>
              <HistorySubscriber>
                <ModalManager />

                <Loader text={props.loadingText} visible={props.isLoading} />

                <CssBaseline />
                {props.isMaintenance ? (
                  <MaintanancePage message={props.maintenanceMessage} />
                ) : (
                  <Router />
                )}
              </HistorySubscriber>
            </ErrorBoundary>
          </MuiThemeProvider>
        </IntlProvider>
      </StripeProvider>
    </ConnectedRouter>
  );
};

interface IProps {
  stripeKey: string;
  loadingText: string | null;
  isLoading: boolean;
  window?: () => Window;
  locale: Locale;
  isMaintenance: boolean;
  maintenanceMessage: string;
  back: undefined | (() => void);
  deleteError: () => void;
}

const MaintanancePage: React.FC<{ message: string }> = ({ message }) => (
  <div>
    <img
      src={WarningImg}
      style={{
        width: '100%',
        height: '146px',
        objectFit: 'cover',
      }}
    />
    <TextSection>
      <ModalTitle>{message}</ModalTitle>
    </TextSection>
  </div>
);

const TextSection = styled.div`
  text-align: center;
  margin: 32px auto;
`;
