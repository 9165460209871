import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from '../../../../store/app/state';
import CompletePopup from '../complete_popup/container';

const CallStaffCompletePopup: React.FC = () => (
  <CompletePopup
    name={Popup.CallStaffComplete}
    text={<FormattedMessage id="self.organisms.callStaffCompletePopup.completedCallStaff" />}
  />
);

export default CallStaffCompletePopup;
