import React, { useCallback } from 'react';
import { modalGa } from '../../../common/middleware/ga';
import { Modal as ModalType } from '../../../../store/app/state';
import { ZIndexSettings } from '../../../config/ZIndexSettings';
import { Dialog } from '@material-ui/core';
import { selectModal } from '../../../../store/selecter';
import { closeModalAction, updateModalAction } from '../../../../store/app/actions';
import { useModal } from '../../../common/hooks/useModal';

export interface IProps {
  name: ModalType;
  isModal?: boolean;
}

const Modal: React.FC<IProps> = props => {
  const { name, isModal, children } = props;

  const onOpen = useCallback(() => modalGa(name), [name]);
  const { isOpen, onClose } = useModal(
    name,
    selectModal,
    updateModalAction,
    closeModalAction,
    isModal,
  );

  return (
    <Dialog
      closeAfterTransition
      open={isOpen}
      onEntered={onOpen}
      onClose={onClose}
      maxWidth={'xs'}
      style={{
        borderRadius: 20,
        zIndex: ZIndexSettings.MODAL,
      }}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
