import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { TableInfo } from '../../../data/entity/TableInfo';
import { auth } from '../../../libs/firebaseApp';
import { createCheckInRoute } from '../../routes';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../../store/selecter';
import { Locale } from '../../../i18n';
import { Page } from '../Page';

const mapTableInfo = (
  companyId: string | undefined,
  shopId: string | undefined,
  tableId: string | undefined,
): TableInfo => {
  let ret: TableInfo = {
    liffId: '',
    companyId: Number(companyId),
    shopId: shopId || '',
    tableId: Number(tableId),
    tableUserId: '',
  };
  switch (companyId) {
    case 'com_000': {
      const companyIdSpy = 1;
      const liffId = process.env.REACT_APP_EFH_LIFF_ID || '1653631596-newyLOAg';
      let shopIdSpy = '';
      let tableIdSpy = 0;
      switch (shopId) {
        case 'sh_000': {
          shopIdSpy = '5H4CHTiiUibNxBROoUO6';
          switch (tableId) {
            case 'e1':
              tableIdSpy = 75;
              break;
            case 'e2':
              tableIdSpy = 76;
              break;
            case 'e11':
              tableIdSpy = 77;
              break;
            case 'e12':
              tableIdSpy = 78;
              break;
            case 'e21':
              tableIdSpy = 79;
              break;
            case 'e22':
              tableIdSpy = 80;
              break;
            case 'e31':
              tableIdSpy = 81;
              break;
            case 'e32':
              tableIdSpy = 82;
              break;
            case 'f1':
              tableIdSpy = 83;
              break;
            case 'f2':
              tableIdSpy = 84;
              break;
            case 'f3':
              tableIdSpy = 85;
              break;
            case 'f4':
              tableIdSpy = 86;
              break;
            case 'f5':
              tableIdSpy = 87;
              break;
            case 'a1':
              tableIdSpy = 88;
              break;
            case 'a2':
              tableIdSpy = 89;
              break;
            case 'a3':
              tableIdSpy = 90;
              break;
            case 'a4':
              tableIdSpy = 91;
              break;
            case 'a5':
              tableIdSpy = 92;
              break;
            case 'a11':
              tableIdSpy = 93;
              break;
            case 'a12':
              tableIdSpy = 94;
              break;
            case 'a13':
              tableIdSpy = 95;
              break;
            case 'a14':
              tableIdSpy = 96;
              break;
            case 'a15':
              tableIdSpy = 97;
              break;
            case 'f11':
              tableIdSpy = 98;
              break;
            case 'f12':
              tableIdSpy = 99;
              break;
            case 'f13':
              tableIdSpy = 100;
              break;
            case 'f21':
              tableIdSpy = 101;
              break;
            case 'f22':
              tableIdSpy = 102;
              break;
            case 'f23':
              tableIdSpy = 103;
              break;
            case 'f31':
              tableIdSpy = 104;
              break;
            case 'f32':
              tableIdSpy = 105;
              break;
            case 'f33':
              tableIdSpy = 106;
              break;
            case 'f41':
              tableIdSpy = 107;
              break;
            case 'f42':
              tableIdSpy = 108;
              break;
            case 'f43':
              tableIdSpy = 109;
              break;
            case 'f51':
              tableIdSpy = 110;
              break;
            case 'f52':
              tableIdSpy = 111;
              break;
            case 'f53':
              tableIdSpy = 112;
              break;
            case 'f54':
              tableIdSpy = 113;
              break;
            case 'f55':
              tableIdSpy = 114;
              break;
            case 'f56':
              tableIdSpy = 115;
              break;
            case 'f57':
              tableIdSpy = 116;
              break;
            case 'b1':
              tableIdSpy = 117;
              break;
            case 'b2':
              tableIdSpy = 118;
              break;
            case 'b3':
              tableIdSpy = 119;
              break;
            case 'b4':
              tableIdSpy = 120;
              break;
            case 'b11':
              tableIdSpy = 121;
              break;
            case 'b12':
              tableIdSpy = 122;
              break;
            case 'b13':
              tableIdSpy = 123;
              break;
            case 'b14':
              tableIdSpy = 124;
              break;
            case 'c1':
              tableIdSpy = 125;
              break;
            case 'c2':
              tableIdSpy = 126;
              break;
            case 'c3':
              tableIdSpy = 127;
              break;
            case 'c4':
              tableIdSpy = 128;
              break;
            case 'c11':
              tableIdSpy = 129;
              break;
            case 'c12':
              tableIdSpy = 130;
              break;
            case 'c13':
              tableIdSpy = 131;
              break;
            case 'c14':
              tableIdSpy = 132;
              break;
            case 't1':
              tableIdSpy = 133;
              break;
            case 't2':
              tableIdSpy = 134;
              break;
            case 't3':
              tableIdSpy = 135;
              break;
            case 't4':
              tableIdSpy = 136;
              break;
            case 't11':
              tableIdSpy = 137;
              break;
            case 't12':
              tableIdSpy = 138;
              break;
            case 't21':
              tableIdSpy = 139;
              break;
            case 't22':
              tableIdSpy = 140;
              break;
            case 't23':
              tableIdSpy = 141;
              break;
            case 't24':
              tableIdSpy = 142;
              break;
            case 'c15':
              tableIdSpy = 143;
              break;
            case 'c5':
              tableIdSpy = 144;
              break;
            case 'b15':
              tableIdSpy = 145;
              break;
            case 'b5':
              tableIdSpy = 146;
              break;
            case 'a16':
              tableIdSpy = 147;
              break;
            case 'a6':
              tableIdSpy = 148;
              break;
          }
        }
      }
      ret = {
        liffId,
        companyId: companyIdSpy,
        shopId: shopIdSpy,
        tableId: tableIdSpy,
        tableUserId: '',
      };
    }
  }
  return ret;
};

const OldCheckIn: React.FC = () => {
  const { companyId, shopId, tableId } = useParams();

  const history = useHistory();
  const locale = useSelector(selectLocale);

  const redirectToLine = (tableInfo: TableInfo) =>
    window.location.replace(
      `https://liff.line.me/${tableInfo.liffId}?companyId=${tableInfo.companyId}&shopId=${tableInfo.shopId}&tableId=${tableInfo.tableId}`,
    );

  const redirectToDinii = useCallback(
    (tableInfo: TableInfo) => history.replace(createCheckInRoute(tableInfo)),
    [history],
  );

  useEffect(() => {
    // NOTE: たぶん selectIsLoggedIn に置き換えられる
    const unsubscirbe = auth.onAuthStateChanged(() => {
      const info = mapTableInfo(companyId, shopId, tableId);
      redirectToLine(info);
    });
    return unsubscirbe;
  }, [companyId, redirectToDinii, shopId, tableId]);

  return (
    <Page>
      <section>
        {locale === Locale.Ja
          ? 'リダイレクト中…  本サービスのご利用を続けるにはLINEアプリで開いてください'
          : 'Redirecting.. To continue this service, please open with LINE App'}
      </section>
    </Page>
  );
};

export default OldCheckIn;
