import { useSelector } from 'react-redux';
import { HomeHeaderComponent } from './component';
import React, { useMemo } from 'react';
import { selectShop, selectTableUser } from '../../../../store/selecter';
import { TableUserStateValue } from '../../../../data/entity/TableUserState';

interface Props {
  setStickyHeaderHeight: (height: number) => void;
  isShowStickyHeader: boolean;
  setIsShowStickyHeader: (isShow: boolean) => void;
}

const HomeHeader: React.FC<Props> = ({
  setStickyHeaderHeight,
  isShowStickyHeader,
  setIsShowStickyHeader,
}) => {
  const shop = useSelector(selectShop);
  const tableUser = useSelector(selectTableUser);

  const lastOrderPlans = useMemo(
    () =>
      tableUser.activePlans?.map(p => ({
        name: p.name,
        lastOrderTime: p.endAt ? new Date(p.endAt * 1000) : null,
      })) || [],
    [tableUser],
  );

  return (
    <HomeHeaderComponent
      lastOrderText={shop.lastOrderText || ''}
      plans={lastOrderPlans}
      setStickyHeaderHeight={setStickyHeaderHeight}
      isShowStickyHeader={isShowStickyHeader}
      setIsShowStickyHeader={setIsShowStickyHeader}
      showSearchButton={tableUser.tableUserState === TableUserStateValue.Active}
    />
  );
};

export default HomeHeader;
