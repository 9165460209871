import React, { Dispatch } from 'react';
import SelectPaymentMethod from './component';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLocale,
  selectShop,
  selectTableInfo,
  selectTableUser,
} from '../../../store/selecter';
import { Routes } from '../../routes';
import { Action } from '../../../store/action';
import { TableInfo } from '../../../data/entity/TableInfo';
import { closeModalAction, updateIsLoading, updatePopupAction } from '../../../store/app/actions';
import { FormattedMessage } from 'react-intl';
import { injector } from '../../../data/injector';
import { handleError } from '../../../errors';
import * as H from 'history';
import { Popup } from '../../../store/app/state';
import { PaymentMethod } from '../../../data/entity/PaymentMethod';

const cashPayment = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  locale: string,
  tableInfo: TableInfo | null,
) => {
  try {
    if (!tableInfo) return;
    dispatch(updateIsLoading(<FormattedMessage id="self.connecting" />));
    await injector.apiClient.payment(
      await injector.firebaseClient.getIdToken(),
      tableInfo,
      locale,
      PaymentMethod.OnSite,
    );
    dispatch(closeModalAction());
    dispatch(updateIsLoading(null));
    dispatch(updatePopupAction(Popup.AccountComplete));
    history.goBack();
  } catch (e) {
    await handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

export const SelectPaymentMethodPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const tableUser = useSelector(selectTableUser);
  const tableInfo = useSelector(selectTableInfo);
  const { disableOnlinePayment } = useSelector(selectShop);

  return (
    <SelectPaymentMethod
      disableOnlinePayment={Boolean(disableOnlinePayment)}
      tableUser={tableUser}
      onClickCardPayment={() => history.replace(Routes.registerCard)}
      onClickCashPayment={() => cashPayment(dispatch, history, locale, tableInfo)}
    />
  );
};
