import { ThunkResult } from '../thunk';
import { selectTableInfo, selectLocale } from '../selecter';
import { menuActions } from '../ducks/menu';

export const updateCategoryPlans = (): ThunkResult<void> => async (
  dispatch,
  getState,
  { firebaseApiClient, diniiApiClient },
) => {
  const state = getState();
  const tableInfo = selectTableInfo(state);
  const locale = selectLocale(state);
  const idToken = await firebaseApiClient.getIdToken();

  const categoryPlans = await diniiApiClient.getPlans(idToken, tableInfo, locale);
  dispatch(menuActions.updateAllCategoryPlans({ categoryPlans }));
};
