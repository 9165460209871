import * as firebaseLib from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Config from '../config/Config';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const version = Config.VERSION;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseApp = firebaseLib.initializeApp(firebaseConfig);
firebaseApp.auth().useDeviceLanguage();

export const auth = firebaseApp.auth();
export type AuthType = typeof auth;

const firestoreBaseRef = () =>
  firebaseApp
    .firestore()
    .collection('versionCode')
    .doc(version);

export const shopMaintenanceRef = (shopId: string) =>
  firebaseApp
    .firestore()
    .collection('shopMaintenanceInfo')
    .where('shopId', '==', shopId);

export const usersRef = (userId: string) =>
  firebaseApp
    .firestore()
    .collection('users')
    .doc(userId);

export const tableUserRef = (shopId: string, tableUserId: string | undefined) => {
  return firestoreBaseRef()
    .collection('process')
    .doc(shopId)
    .collection('tableUsers')
    .doc(tableUserId);
};

export const cartRef = (
  shopId: string,
  tableUserId: string,
): firebaseLib.firestore.CollectionReference => {
  return firestoreBaseRef()
    .collection('process')
    .doc(shopId)
    .collection('tableUsers')
    .doc(tableUserId)
    .collection('cartItems');
};

export const ordersRef = (shopId: string, tableUserId: string) => {
  return firestoreBaseRef()
    .collection('process')
    .doc(shopId)
    .collection('orders')
    .where('tableUserId', '==', tableUserId);
};

export const actionLogsRef = (userId: string): firebaseLib.firestore.CollectionReference => {
  return firebaseApp
    .firestore()
    .collection('users')
    .doc(userId)
    .collection('actionLogs');
};

export default firebaseApp;
