import React from 'react';
import ConfirmModal from '../confirm_modal/confirm_modal';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../../../store/app/state';

export class ConfirmStartPlanModalComponent extends React.Component<IProps> {
  render() {
    return (
      <ConfirmModal
        name={Modal.ConfirmStartPlan}
        title={<FormattedMessage id="self.organisms.planDetail.modalTitle" />}
        message={<FormattedMessage id="self.organisms.planDetail.modalMessage" />}
        onClickConfirm={this.props.onClickConfirm}
        onClickCancel={this.props.onClickCancel}
      />
    );
  }
}
interface IProps {
  onClickCancel: () => void;
  onClickConfirm: () => void;
}
