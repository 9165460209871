import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PlanDetailComponent } from './component';
import { useEditPlan } from '../../hooks/useEditPlan';
import { Modal } from '../../../store/app/state';
import { useModalController } from '../../hooks/useModalController';

export const PlanDetail: React.FC = () => {
  const { planCategoryId, planId } = useParams();
  const history = useHistory();
  const { openModal } = useModalController(Modal.ConfirmStartPlan);

  const {
    plan,
    disabled,
    price,
    startPlanRequest,
    incrementOptionQuantity,
    decrementOptionQuantity,
  } = useEditPlan(Number(planId), Number(planCategoryId));

  return (
    <div>
      {planCategoryId && planId && plan && startPlanRequest && (
        <PlanDetailComponent
          plan={plan}
          price={price}
          disabled={disabled}
          planChoices={startPlanRequest}
          onClickStartPlan={openModal}
          incrementOptionQuantity={incrementOptionQuantity}
          decrementOptionQuantity={decrementOptionQuantity}
          onClickBack={history.goBack}
        />
      )}
    </div>
  );
};
