import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@material-ui/core';
import { Page } from '../Page';

export const CheckInComponent: React.FC<Props> = (props: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return (
    <Page>
      <Wrapper>
        <Button onClick={props.facebookLogin}>facebook login</Button>
        <Button onClick={() => props.emailLogin(email, password)}>custom email login</Button>
        <TextField onChange={handleEmailChange} label={'email'} />
        <TextField onChange={handlePasswordChange} label={'password'} />
        <Button onClick={props.logout}>sign out</Button>
        <Button onClick={props.checkIn}>check in</Button>
      </Wrapper>
    </Page>
  );
};

interface Props {
  facebookLogin: () => void;
  emailLogin: (email: string, password: string) => void;
  logout: () => void;
  checkIn: () => void;
}

const Wrapper = styled.div`
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
