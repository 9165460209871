import State from '../../../../store/state';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorModalComponent } from './component';
import { deleteCurrentErrorAction } from '../../../../store/app/actions';
import React from 'react';
import { selectLocale } from '../../../../store/selecter';
import { getErrorDisplayContent } from '../../../../errors';

export const ErrorModal: React.FC = () => {
  const currentError = useSelector((state: State) => state.app.error);
  const locale = useSelector(selectLocale);
  const errorDisplayContent =
    currentError &&
    getErrorDisplayContent({
      ...currentError,
      locale,
    });
  const dispatch = useDispatch();

  return (
    <ErrorModalComponent
      isShow={currentError !== undefined}
      title={errorDisplayContent?.title}
      message={errorDisplayContent?.message}
      onHide={() => dispatch(deleteCurrentErrorAction())}
    />
  );
};

export default ErrorModal;
