import { opacify } from 'polished';

const transparencify = opacify('-0.9');

// prettier-ignore
const getFilter = (color: string) => [
  `drop-shadow(0px 1px 3px ${transparencify(color)}) drop-shadow(0px 1px 1px ${transparencify(color)}) drop-shadow(0px 2px 1px ${transparencify(color)})`,
  `drop-shadow(0px 1px 5px ${transparencify(color)}) drop-shadow(0px 2px 2px ${transparencify(color)}) drop-shadow(0px 3px 1px ${transparencify(color)})`,
  `drop-shadow(0px 1px 8px ${transparencify(color)}) drop-shadow(0px 3px 4px ${transparencify(color)}) drop-shadow(0px 3px 3px ${transparencify(color)})`,
  `drop-shadow(0px 2px 4px ${transparencify(color)}) drop-shadow(0px 4px 5px ${transparencify(color)}) drop-shadow(0px 1px 10px ${transparencify(color)})`,
  `drop-shadow(0px 3px 5px ${transparencify(color)}) drop-shadow(0px 5px 8px ${transparencify(color)}) drop-shadow(0px 1px 14px ${transparencify(color)})`,
  `drop-shadow(0px 3px 5px ${transparencify(color)}) drop-shadow(0px 6px 10px ${transparencify(color)}) drop-shadow(0px 1px 18px ${transparencify(color)})`,
  `drop-shadow(0px 4px 5px ${transparencify(color)}) drop-shadow(0px 7px 10px ${transparencify(color)}) drop-shadow(0px 2px 16px ${transparencify(color)})`,
  `drop-shadow(0px 5px 5px ${transparencify(color)}) drop-shadow(0px 8px 10px ${transparencify(color)}) drop-shadow(0px 3px 14px ${transparencify(color)})`,
  `drop-shadow(0px 5px 6px ${transparencify(color)}) drop-shadow(0px 9px 12px ${transparencify(color)}) drop-shadow(0px 3px 16px ${transparencify(color)})`,
  `drop-shadow(0px 6px 6px ${transparencify(color)}) drop-shadow(0px 10px 14px ${transparencify(color)}) drop-shadow(0px 4px 18px ${transparencify(color)})`,
  `drop-shadow(0px 6px 7px ${transparencify(color)}) drop-shadow(0px 11px 15px ${transparencify(color)}) drop-shadow(0px 4px 20px ${transparencify(color)})`,
  `drop-shadow(0px 7px 8px ${transparencify(color)}) drop-shadow(0px 12px 17px ${transparencify(color)}) drop-shadow(0px 5px 22px ${transparencify(color)})`,
  `drop-shadow(0px 7px 8px ${transparencify(color)}) drop-shadow(0px 13px 19px ${transparencify(color)}) drop-shadow(0px 5px 24px ${transparencify(color)})`,
  `drop-shadow(0px 7px 9px ${transparencify(color)}) drop-shadow(0px 14px 21px ${transparencify(color)}) drop-shadow(0px 5px 26px ${transparencify(color)})`,
  `drop-shadow(0px 8px 9px ${transparencify(color)}) drop-shadow(0px 15px 22px ${transparencify(color)}) drop-shadow(0px 6px 28px ${transparencify(color)})`,
  `drop-shadow(0px 8px 10px ${transparencify(color)}) drop-shadow(0px 16px 24px ${transparencify(color)}) drop-shadow(0px 6px 30px ${transparencify(color)})`,
  `drop-shadow(0px 8px 11px ${transparencify(color)}) drop-shadow(0px 17px 26px ${transparencify(color)}) drop-shadow(0px 6px 32px ${transparencify(color)})`,
  `drop-shadow(0px 9px 11px ${transparencify(color)}) drop-shadow(0px 18px 28px ${transparencify(color)}) drop-shadow(0px 7px 34px ${transparencify(color)})`,
  `drop-shadow(0px 9px 12px ${transparencify(color)}) drop-shadow(0px 19px 29px ${transparencify(color)}) drop-shadow(0px 7px 36px ${transparencify(color)})`,
  `drop-shadow(0px 10px 13px ${transparencify(color)}) drop-shadow(0px 20px 31px ${transparencify(color)}) drop-shadow(0px 8px 38px ${transparencify(color)})`,
  `drop-shadow(0px 10px 13px ${transparencify(color)}) drop-shadow(0px 21px 33px ${transparencify(color)}) drop-shadow(0px 8px 40px ${transparencify(color)})`,
  `drop-shadow(0px 10px 14px ${transparencify(color)}) drop-shadow(0px 22px 35px ${transparencify(color)}) drop-shadow(0px 8px 42px ${transparencify(color)})`,
  `drop-shadow(0px 11px 14px ${transparencify(color)}) drop-shadow(0px 23px 36px ${transparencify(color)}) drop-shadow(0px 9px 44px ${transparencify(color)})`,
  `drop-shadow(0px 11px 15px ${transparencify(color)}) drop-shadow(0px 24px 38px ${transparencify(color)}) drop-shadow(0px 9px 46px ${transparencify(color)})`,
];

export const shadow = (elevation: number, color = 'black') => `
  filter: ${getFilter(color)[elevation]};
`;
