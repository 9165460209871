import React, { Dispatch, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryMenu, CategoryPlan, Menu } from '../../../data/entity/menu';
import { selectCategoryPlans, selectMenus, selectTableUser } from '../../../store/selecter';
import { Search } from './component';
import { hiraganaToKatakana } from '../../../util/language';
import * as H from 'history';
import { Action } from '../../../store/action';
import { handleError } from '../../../errors';
import { useHistory } from 'react-router';
import { ActivePlan } from '../../../data/entity/ActivePlan';

const mergeCategoryMenusAndActivePlanMenus = (
  categoryMenus: CategoryMenu[],
  categoryPlans: CategoryPlan[],
  activePlansInfo?: ActivePlan[],
) => {
  const categoryMenusAndPlanMenus: CategoryMenu[] = [];
  if (activePlansInfo) {
    activePlansInfo.forEach(activePlanInfo => {
      try {
        categoryPlans
          .filter(categoryPlan => categoryPlan.planCategoryId === activePlanInfo.planCategoryId)[0]
          .plans.filter(plan => plan.planId === activePlanInfo.planId)[0]
          .categoryMenus.forEach(categoryMenu => {
            categoryMenusAndPlanMenus.push(categoryMenu);
          });
      } catch (e) {
        console.log(e);
      }
    });
  }

  categoryMenus.forEach(categoryMenu => {
    categoryMenusAndPlanMenus.push(categoryMenu);
  });
  return categoryMenusAndPlanMenus;
};

const getMenus = (categoryMenus: CategoryMenu[]): Menu[] =>
  categoryMenus.flatMap(({ menus }) => menus);

const getNameMenuEntries = (menus: Menu[]) =>
  menus.map(menu => [menu.name, menu]).filter(([name]) => name) as [string, Menu][];

const handleClickMenuItem = async (
  history: H.History,
  categoryId: number,
  menuId: number,
  dispatch: Dispatch<Action>,
) => {
  try {
    history.push(`/menu/${categoryId}/${menuId}?backToHome=true`);
  } catch (e) {
    await handleError(e, dispatch, history);
  }
};

export const SearchPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryMenus = useSelector(selectMenus);
  const categoryPlans = useSelector(selectCategoryPlans);
  const tableUser = useSelector(selectTableUser);
  const categoryMenusAndPlanMenus = useMemo(() => {
    return mergeCategoryMenusAndActivePlanMenus(
      categoryMenus,
      categoryPlans,
      tableUser.activePlans,
    );
  }, [categoryMenus, categoryPlans, tableUser]);

  const onClickMenuItem = (categoryId: number, menuId: number) =>
    handleClickMenuItem(history, categoryId, menuId, dispatch);

  const menus = useMemo(
    () =>
      getNameMenuEntries(getMenus(categoryMenusAndPlanMenus)).map(([name, menu]) => [
        hiraganaToKatakana(name),
        menu,
      ]) as [string, Menu][],
    [categoryMenusAndPlanMenus],
  );

  return <Search menus={menus} onClickMenuItem={onClickMenuItem} />;
};
