import { Modal } from '../../store/app/state';
import { useAppDispatch } from './useAppDispatch';
import { updateModalAction } from '../../store/app/actions';

export const useModalController = (modal: Modal) => {
  const dispatch = useAppDispatch();
  const openModal = () => dispatch(updateModalAction(modal));
  const closeModal = () => dispatch(updateModalAction(Modal.None));
  return {
    openModal,
    closeModal,
  };
};
