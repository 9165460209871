import React from 'react';
import { StpCard } from '../../../data/entity/StpCard';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { AddCardFormComponent as AddCardOrganism } from '../../components/organisms/add_card_form/component';
import { Typography } from '@material-ui/core';
import { Page } from '../Page';
import { CardRegistered } from '../../components/molecules/card_registered';

export class AddCardComponent extends React.Component<IProps> {
  render() {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (
      <Page>
        <Wrapper style={{ height: `${h - 60}px` }}>
          {(this.props.cards ? this.props.cards.length : 0) > 0 ? (
            <div>
              <SecondaryHeader>
                <FormattedMessage id="self.pages.add_card_page.registeredCardInformation" />
              </SecondaryHeader>
              <div style={{ width: '90%', padding: '20px' }}>
                <CardRegistered cardInfo={this.props.cards[0]} />
              </div>
              <SecondaryHeader>
                <FormattedMessage id="self.pages.add_card_page.changeCard" />
              </SecondaryHeader>
            </div>
          ) : (
            <SecondaryHeader>
              <FormattedMessage id="self.pages.add_card_page.cardInfo" />
            </SecondaryHeader>
          )}
          <AddCardOrganism
            handleCardToken={this.props.handleCardToken}
            addCommunication={this.props.addCommunication}
            deleteCommunication={this.props.deleteCommunication}
            handleCardTokenError={this.props.handleCardTokenError}
            paymentType={this.props.paymentType}
          />
        </Wrapper>
      </Page>
    );
  }
}

interface IProps {
  cards: StpCard[];
  handleCardToken: (token: string) => void;
  addCommunication: (id: string) => void;
  deleteCommunication: (id: string) => void;
  handleCardTokenError: (e: any) => void;
  paymentType: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
`;

const SecondaryHeader = styled(Typography)`
  variant: h1;
`;
