import React from 'react';
import styled from 'styled-components';
import StepCard from '../../../../assets/images/step_card.svg';
import { Elements } from 'react-stripe-elements';
import CardAll from '../../molecules/card_all';
import CardInfo from '../../molecules/card_info';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

export class AddCardFormComponent extends React.Component<IProps> {
  render() {
    return (
      <Limitter shouldScroll={this.props.shouldScroll === undefined}>
        {this.props.hasStepHeader && (
          <div>
            <img src={StepCard} style={{ width: '240px', margin: '10px auto 5px' }} />
            <Typography variant={'h1'}>
              <FormattedMessage id="self.organisms.add_card.cardInfo" />
            </Typography>
          </div>
        )}
        <Wrapper>
          <CardAllSection>
            <CardAll />
          </CardAllSection>
          <Elements>
            <CardInfo
              width="100%"
              paymentType={this.props.paymentType}
              handleCardToken={this.props.handleCardToken}
              handleCardTokenError={this.props.handleCardTokenError}
              addCommunication={this.props.addCommunication}
              deleteCommunication={this.props.deleteCommunication}
            />
          </Elements>
          <Text>
            <FormattedMessage id="self.organisms.add_card.text" />
          </Text>
        </Wrapper>
      </Limitter>
    );
  }
}

interface IProps {
  handleCardToken: (token: string) => void;
  addCommunication: (id: string) => void;
  deleteCommunication: (id: string) => void;
  handleCardTokenError: (error: any) => void;
  hasStepHeader?: boolean;
  shouldScroll?: boolean;
  paymentType: string;
}

const Limitter = styled.div<{ shouldScroll: boolean }>`
  max-height: ${({ shouldScroll }) => (shouldScroll ? '80vh' : undefined)};
  overflow: ${({ shouldScroll }) => (shouldScroll ? 'scroll' : undefined)};
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 20px 20px;
`;
const CardAllSection = styled.div`
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 26px;
`;
