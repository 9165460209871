import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

export default class MyAccountList extends React.Component<IProps> {
  render() {
    return (
      <Wrapper>
        <Title>{this.translateText(this.props.title)}</Title>
        <Data>{this.props.data}</Data>
      </Wrapper>
    );
  }
  private translateText(text: string) {
    switch (text) {
      case '電話番号':
        return <FormattedMessage id="self.molecules.myaccount_list.phoneNumber" />;
      case 'メールアドレス':
        return <FormattedMessage id="self.molecules.myaccount_list.mailAdrress" />;
      case 'ユーザ名':
        return <FormattedMessage id="self.molecules.myaccount_list.name" />;
      case 'ユーザ':
        return <FormattedMessage id="self.molecules.myaccount_list.user" />;
      default:
        return text;
    }
  }
}

interface IProps {
  title: string;
  data: string;
}

const Wrapper = styled.div`
  display: flex;
  padding: 16px 14px;
  border-bottom: 1px solid #f1f1f1;
  text-align: left;
`;

const Title = styled.div`
  width: 45%;
`;
const Data = styled.div`
  width: 50%;
`;
