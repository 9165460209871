import React from 'react';
import { OrderHistoryHeader } from './order_history_header/contianer';
import styled from 'styled-components';
import { Button, Divider } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PaymentType } from '../../../data/entity/PaymentType';
import { Page } from '../Page';
import { shadow } from '../../styles/shadow';
import { OrderType } from '../../../data/entity/Order';
import { SelectNumPeopleSpinner } from './select_num_people_spinner';

interface Props {
  orders: OrderHistoryOrderItem[];
  backToHome: () => void;
  paymentType: PaymentType;
  search: string;
  navigateToMenuDetail: (categoryId: number, menuId: number) => void;
  numPeople: number | undefined;
  setNumPeople: (numPeople: number) => void;
  isMenuItem: (categoryId: number, menuId: number) => boolean;
  canReOrder: boolean;
}

export class OrderHistoryComponent extends React.Component<Props> {
  render() {
    return (
      <Page>
        <div>
          <div style={{ margin: '20px', textAlign: 'center' }}>
            <PriceWrapper>
              <OrderHistoryHeader />
            </PriceWrapper>
            <SelectNumPeopleWrapper>
              <FormattedMessage id="self.pages.history.numberOfPeople" />
              <SelectNumPeopleSpinner
                numPeople={this.props.numPeople}
                setNumPeople={this.props.setNumPeople}
              />
            </SelectNumPeopleWrapper>
          </div>
          <Wrapper>
            <Divider />
            <div>
              {this.props.orders.map((orderItem, index) => {
                const isMenuItem =
                  orderItem.orderType === OrderType.Menu &&
                  this.props.isMenuItem(orderItem.categoryId, orderItem.menuId);
                return (
                  <OrderDetailWrapper
                    onClick={() =>
                      isMenuItem &&
                      this.props.navigateToMenuDetail(orderItem.categoryId, orderItem.menuId)
                    }
                    key={index}
                  >
                    <Time>{orderItem.time}</Time>
                    <Name>
                      {orderItem.name}
                      {orderItem.optionText.length > 0 && <br />}
                      {orderItem.optionText.length > 0 && <Option>{orderItem.optionText}</Option>}
                      {isMenuItem && this.props.canReOrder && (
                        <ReOrder>
                          <FormattedMessage id={'self.reorder'} />
                        </ReOrder>
                      )}
                    </Name>
                    <div style={{ flexGrow: 1 }} />
                    <Price>¥{orderItem.unitPrice}</Price>
                    <Quantity>× {orderItem.quantity}</Quantity>
                  </OrderDetailWrapper>
                );
              })}
            </div>
          </Wrapper>
          <ButtonWrapper>
            <ShadowButton
              variant={'contained'}
              fullWidth={true}
              color={'primary'}
              onClick={() => this.props.backToHome()}
            >
              <FormattedMessage id="self.pages.order_history_page.backToHome" />
            </ShadowButton>
          </ButtonWrapper>
        </div>
      </Page>
    );
  }
}

export interface OrderHistoryOrderItem {
  categoryId: number;
  menuId: number;
  name: string;
  time: string;
  unitPrice: number;
  quantity: number;
  optionText: string;
  orderType: OrderType;
}

const ShadowButton = styled(Button)`
  ${shadow(2)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding-top: 10px;
  padding-bottom: 100px;
  margin: 0 16px;
`;

const SelectNumPeopleWrapper = styled.div`
  font-size: 1.6rem;
  padding-top: 20px;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  left: 7.5%;
  width: 85%;
  text-align: center;
  bottom: calc(30px + env(safe-area-inset-bottom));
`;

const PriceWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
const OrderDetailWrapper = styled.div`
  display: flex;
  font-size: 16px;
  padding: 20px 0;
  border-bottom: 1px solid #f1f1f1;
`;
const Time = styled.div`
  width: 60px;
`;
const Name = styled.div`
  max-width: 200px;
  max-lines: 2;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const Price = styled.div`
  width: 70px;
  margin-right: 10px;
  text-align: right;
`;
const Quantity = styled.div`
  width: 40px;
`;
const Option = styled.span`
  font-size: 14px;
  color: #a0a0a0;
  margin-top: 6px;
  white-space: pre-wrap;
`;
const ReOrder = styled.div`
  text-decoration: underline;
  font-size: 14px;
  margin-top: 6px;
  color: #f46f46;
`;
