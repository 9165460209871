import { useEffect, useState } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { updateModalAction } from '../../store/app/actions';
import { Modal } from '../../store/app/state';
import { useSelector } from 'react-redux';
import { selectTableUser, selectUid, selectUser } from '../../store/selecter';
import { TableUserStateValue } from '../../data/entity/TableUserState';

// NOTE: 実装の理由はこちら https://dinii.kibe.la/notes/2355
export const useConfirmCheckIn = () => {
  const dispatch = useAppDispatch();
  const [confirmed, setConfirmed] = useState(false);
  const tableUser = useSelector(selectTableUser);
  const uid = useSelector(selectUid);
  const user = useSelector(selectUser);

  useEffect(() => {
    (async () => {
      if (!tableUser) return;
      if (!uid) return;
      if (confirmed) return;

      try {
        const canReceiveLineMessage = liff && liff.isLoggedIn();
        const receivedLineMessage =
          (tableUser.users ?? {})[uid]?.receivedLineMessageAt || user.receivedLineMessage;

        if (
          (tableUser.tableUserState === TableUserStateValue.Active ||
            tableUser.tableUserState === TableUserStateValue.Seated) &&
          canReceiveLineMessage &&
          !receivedLineMessage
        ) {
          dispatch(updateModalAction(Modal.ConfirmCheckIn));
        }
      } catch (e) {
        // NOTE: LINE のバグで isLoggedIn がエラーで失敗することがあるらしい
        console.log(e);
      }

      setConfirmed(true);
    })();
  }, [
    confirmed,
    dispatch,
    tableUser,
    tableUser.tableUserState,
    tableUser.users,
    uid,
    user.receivedLineMessage,
  ]);
};
