import { closeModalAction } from '../../../../store/app/actions';
import { useDispatch } from 'react-redux';
import { ConfirmStartPlanModalComponent } from './component';
import React from 'react';
import { StartPlanRequest } from '../../../../data/api/request/StartPlanRequest';
import { useStartPlan } from '../../../hooks/useStartPlan';

export const ConfirmStartPlanModal: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const startPlan = useStartPlan();

  return (
    <ConfirmStartPlanModalComponent
      onClickCancel={() => dispatch(closeModalAction())}
      onClickConfirm={async () => {
        const action = await startPlan(props.startPlanRequest);
        if (action) {
          dispatch(action);
        }
      }}
    />
  );
};

interface Props {
  startPlanRequest: StartPlanRequest;
  planId: number;
}
