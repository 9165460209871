import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    textField: {
      marginTop: '8px',
      width: '100%',
    },
    resizeFont: {
      fontSize: '16px',
    },
  }),
);

interface Props {
  mailSignUp: (email: string, password: string) => void;
  navigateToSignIn: () => void;
  liffSignIn: () => void;
}

export const MailSignUp: React.FC<Props> = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isInputComplete, setIsInputComplete] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // firebase auth側でvalidateされるので最小限の判定にとどめる
  const validateEmail = (email: string) => {
    const re = /^.+@.+\..+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setIsInputComplete(!(validateEmail(email) && password.length > 5));
  }, [email, password]);

  return (
    <Wrapper>
      <Typography variant={'h1'} style={{ margin: '20px' }}>
        <FormattedMessage id={'self.signUp.title'} />
      </Typography>
      <InputSection>
        <TextField
          name="email"
          label="メールアドレス/Email"
          variant={'outlined'}
          className={classes.textField}
          InputProps={{
            classes: {
              input: classes.resizeFont,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.resizeFont,
              focused: classes.resizeFont,
            },
          }}
          onChange={handleEmailChange}
        />
        <TextField
          type="password"
          name="password"
          variant={'outlined'}
          label="パスワード/Password"
          className={classes.textField}
          InputProps={{
            classes: {
              input: classes.resizeFont,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.resizeFont,
              focused: classes.resizeFont,
            },
          }}
          onChange={handlePasswordChange}
        />
      </InputSection>
      <Typography variant={'body2'} style={{ color: '#777', marginTop: '8px' }}>
        <FormattedMessage id={'self.pages.signUp.passwordLimitation'} />
      </Typography>
      <ButtonSection>
        <Button
          name="sign-up"
          disabled={isInputComplete}
          onClick={() => props.mailSignUp(email, password)}
          color={'primary'}
          variant={'contained'}
          style={{ width: '100%', margin: '30px auto 0' }}
        >
          <FormattedMessage id={'self.pages.signUp.signUp'} />
        </Button>

        <Typography
          variant={'body2'}
          style={{ textAlign: 'center', color: '#777', marginTop: '96px' }}
        >
          <FormattedMessage id={'self.pages.signUp.alreadySignInText'} />
        </Typography>
        <Button
          name="sign-in"
          onClick={props.navigateToSignIn}
          style={{
            width: '100%',
            margin: '20px auto 0',
            color: '#c4c4c4',
            border: 'solid 1px #c4c4c4',
          }}
        >
          <FormattedMessage id={'self.pages.signUp.signIn'} />
        </Button>
        <Button
          onClick={props.liffSignIn}
          style={{
            width: '100%',
            margin: '16px auto 0',
            fontSize: '1.6rem',
            color: '#fff',
            background: '#00B900',
          }}
        >
          <FormattedMessage id={'self.pages.signUp.liffSignIn'} />
        </Button>
      </ButtonSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonSection = styled.div`
  width: 65%;
`;

const InputSection = styled.div`
  width: 65%;
`;
