import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PlzReadQRImg from '../../../assets/images/please_read_qr.svg';
import { Shop } from '../../../data/entity/Shop';
import { Page } from '../Page';

export class NoTableInfo extends React.Component<IProps> {
  render() {
    return (
      <Page>
        <div>
          <img src={PlzReadQRImg} style={{ width: '100%', objectFit: 'cover' }} />
        </div>
      </Page>
    );
  }
}

interface IProps
  extends RouteComponentProps<{
    type: string;
  }> {
  checkInPath?: string;
  shop?: Shop;
  locale: string;
  backToHome: (checkInPath: string) => void;
}
