import React from 'react';
import PaymentTotal from '../../../components/molecules/payment_total';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { PaymentMethod } from '../../../../data/entity/PaymentMethod';

export class CashPayingHomeComponent extends React.Component<Props> {
  render() {
    return (
      <TotalSection>
        {this.props.paymentMethod === PaymentMethod.OnSite && (
          <TableNumberCard>
            <TableName>{this.props.tableName}</TableName>
            <Typography style={{ textAlign: 'center' }} variant={'body1'}>
              <FormattedMessage id="self.organisms.homePaymentSection.titleDescription" />
              <br />
              <FormattedMessage id="self.organisms.homePaymentSection.titleText.ja" />
            </Typography>
          </TableNumberCard>
        )}
        <PaymentSummaryWrapper>
          <PaymentTotal
            isPresentOnePersonPrice={this.props.isPresentOnePersonPrice}
            width="100%"
            subtotal="小計"
            subtotalPrice={this.props.subTotal}
            service="サービス料"
            servicePrice={this.props.servicePrice}
            tax="消費税"
            taxPrice={this.props.taxAmount}
            total="合計"
            totalPrice={this.props.paidAmount}
            onePersonPrice={this.props.onePersonPrice}
          />
        </PaymentSummaryWrapper>
      </TotalSection>
    );
  }
}

interface Props {
  isPresentOnePersonPrice: boolean;
  subTotal: number;
  servicePrice: number;
  taxAmount: number;
  paidAmount: number;
  onePersonPrice: number;
  paymentMethod?: PaymentMethod;
  tableName?: string;
}

const TotalSection = styled.div`
  display: block;
`;

const TableNumberCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 240px;
  border-radius: 10px;
  background-color: #f2c94c;
  margin: 20px;
`;

const TableName = styled.div`
  width: 100%;
  text-align: center;
  color: black;
  font-weight: bolder;
  font-size: 80px;
`;

const PaymentSummaryWrapper = styled.div`
  margin: 10px;
`;
