import { Dispatch } from 'react';
import { updateCurrentErrorAction } from './store/app/actions';
import { Action } from './store/action';
import * as H from 'history';
import * as Sentry from '@sentry/browser';
import { createErrorRoute, ErrorDisplayType } from './presentation/pages/error_page/container';
import { Locale } from './i18n';

export class CustomError extends Error {
  error: Errors;
  fixedTitle?: string;
  fixedMessage?: string;

  constructor(error: Errors, title?: string, message?: string) {
    super(error);
    this.error = error;
    this.fixedTitle = title;
    this.fixedMessage = message;
  }
}

export enum Errors {
  UNKNOWN_ERROR = 'unknown error',
  NOT_FIREBASE_AUTHORIZED = 'not firebase authorized',
  CARD_REGISTER_ERROR = 'card register error',
  API_ERROR = 'api error',
  NO_ORDER = 'no order',
  INVALID_QUERY_PARAM = 'invalid query param',
  ALREADY_ACCOUNTED = 'already accounted',
  NOT_OPEN = 'not open',
  COMPANY_CONFIG_NOT_FOUND_ERROR = 'company config not found error',
  FIREBASE_SIGN_IN_MAIL_ALREADY_IN_USE = 'firebase sign in mail already in use',
  FIREBASE_SIGN_IN_MAIL_FORMAT_ERROR = 'firebase sign in mail format error',
  FIREBASE_SIGN_IN_USER_NOT_FOUND = 'firebase sign in user not found',
  FIREBASE_SIGN_IN_WRONG_PASSWORD = 'firebase sign in wrong password',
}

export const getErrorSeverity = (error: Errors) => {
  switch (error) {
    case Errors.UNKNOWN_ERROR:
    case Errors.COMPANY_CONFIG_NOT_FOUND_ERROR:
      return Sentry.Severity.Error;
    case Errors.INVALID_QUERY_PARAM:
      return Sentry.Severity.Warning;
    default:
      return Sentry.Severity.Info;
  }
};

export const getErrorDisplayContent = (props: {
  type: Errors;
  title?: string;
  message?: string;
  locale: string;
}): { title: string; message: string } => {
  let title =
    props.locale == Locale.Ja ? '予期せぬエラーが発生しました' : 'Unknown error has occurred';
  let message =
    props.locale == Locale.Ja
      ? 'ネットワーク状況をご確認いただき、QRコードを読み込み直してください。'
      : 'please reload qr code';
  switch (props.type) {
    case Errors.NOT_FIREBASE_AUTHORIZED:
      title = props.locale === Locale.Ja ? 'ユーザ認証に失敗しました' : 'Authorization error';
      title =
        props.locale === Locale.Ja
          ? '電波状況をご確認いただき、QRコードを読み込み直して下さい'
          : 'Please reload the QR code';
      break;
    case Errors.CARD_REGISTER_ERROR:
      title = props.locale === Locale.Ja ? 'カード登録に失敗しました' : 'Card registration failed';
      message =
        props.locale === Locale.Ja
          ? '対応しているカードはVisa/MasterCard/American Expressです'
          : 'Visa / MasterCard / American Express are supported';
      break;
    case Errors.ALREADY_ACCOUNTED:
      message = props.locale === Locale.Ja ? '' : '';
      title = props.locale === Locale.Ja ? 'お会計済みです' : 'Accounted';
      break;
    case Errors.NO_ORDER:
      message = props.locale === Locale.Ja ? '' : '';
      title = props.locale === Locale.Ja ? '注文がありません' : 'No order';
      break;
    case Errors.COMPANY_CONFIG_NOT_FOUND_ERROR:
      message = props.locale === 'ja' ? '店舗情報の取得に失敗しました' : 'No shop found';
      title = props.locale === 'ja' ? '' : '';
      break;
    case Errors.FIREBASE_SIGN_IN_MAIL_ALREADY_IN_USE:
      message =
        props.locale === 'ja'
          ? 'そのメールアドレスはすでに使用されています'
          : 'Email already in use';
      title = props.locale === 'ja' ? '' : '';
      break;
    case Errors.FIREBASE_SIGN_IN_MAIL_FORMAT_ERROR:
      message = props.locale === 'ja' ? '不適切なメールアドレスの形式です' : 'Email format error';
      title = props.locale === 'ja' ? '' : '';
      break;
    case Errors.FIREBASE_SIGN_IN_USER_NOT_FOUND:
    case Errors.FIREBASE_SIGN_IN_WRONG_PASSWORD:
      message =
        props.locale === 'ja'
          ? 'メールアドレスもしくはパスワードが間違っています'
          : 'Invalid email or password';
      title = props.locale === 'ja' ? '' : '';
      break;
    case Errors.NOT_OPEN:
      message =
        props.locale === 'ja'
          ? 'ご利用可能時間になりましたら再度お試しください'
          : 'Please try again after open';
      title = props.locale === 'ja' ? '現在ご利用時間外です' : 'Not open now';
      break;
    case Errors.INVALID_QUERY_PARAM:
    case Errors.API_ERROR:
    case Errors.UNKNOWN_ERROR:
    default:
      break;
  }
  // prioritize messages defined in props
  return {
    title: props.title || title,
    message: props.message || message,
  };
};

export const handleError = (error: Error, dispatch: Dispatch<Action>, history?: H.History) => {
  console.log('caught error: ', error);
  if (error instanceof CustomError) {
    Sentry.configureScope((scope: any) => {
      scope.setLevel(getErrorSeverity(error.error));
      Sentry.captureException(error);
    });

    dispatch(updateCurrentErrorAction(error.error, error.fixedTitle, error.fixedMessage));

    switch (error.error) {
      case Errors.NOT_OPEN:
        history?.replace(createErrorRoute(ErrorDisplayType.NOT_OPEN));
        break;
      default:
        break;
    }
  } else {
    Sentry.captureException(error);
    dispatch(updateCurrentErrorAction(Errors.UNKNOWN_ERROR));
  }
};
