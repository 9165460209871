import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import State from '../../../store/state';
import { ErrorPageComponent } from './component';
import { push } from 'connected-react-router';
import { createCheckInRoute } from '../../routes';

const mapStateToProps = (state: State) => {
  let checkInPath;
  if (state.tableInfo) {
    checkInPath = createCheckInRoute(state.tableInfo);
  }
  const locale = state.userState.locale;
  return {
    checkInPath,
    locale,
    shop: state.shop,
  };
};

/* eslint @typescript-eslint/no-explicit-any: 0 */
const mapDispatchToProps = (dispatch: any) => {
  return {
    backToHome: (checkInPath: string) => {
      dispatch(push(checkInPath));
    },
  };
};

export enum ErrorDisplayType {
  NO_PAGE = 'no_page',
  NOT_OPEN = 'not_open',
  NO_MENU = 'no_menu',
}

export function createErrorRoute(type: ErrorDisplayType): string {
  return `/error/${type}`;
}

export const ErrorPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ErrorPageComponent));

export default ErrorPage;
