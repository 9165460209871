import { injector } from '../../../data/injector';
import { handleError } from '../../../errors';
import * as H from 'history';
import React, { Dispatch, useEffect, useCallback } from 'react';
import { MailSignUp } from './component';
import { useHistory, useLocation } from 'react-router';
import { Action } from '../../../store/action';
import { useDispatch } from 'react-redux';
import { Profile } from '../../../data/entity/Profile';
import { usersRef } from '../../../libs/firebaseApp';
import { CompanyConfig } from '../../../data/entity/CompanyConfig';
import { createErrorRoute, ErrorDisplayType } from '../error_page/container';
import { Routes } from '../../routes';
import { useQuery } from '../../hooks/useQuery';
import { convertFirebaseIdToShopId } from '../../../libs/convertFirebaseIdToShopId';

const updateProfile = async (profile: Profile) => {
  await usersRef(profile.userId).set(profile, {
    merge: true,
  });
};

const mailSignUp = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  email: string,
  password: string,
) => {
  try {
    const user = await injector.firebaseClient.signUpWithEmail(email, password);
    if (user) {
      await updateProfile({ userId: user.uid });
      history.goBack();
    }
  } catch (e) {
    await handleError(e, dispatch, history);
  }
};

const liffSignIn = async (companyId: number, shopId: string) => {
  const companyConfig = new CompanyConfig(
    await injector.apiClient.getCompanyConfig(companyId, shopId),
  );
  await liff.init({ liffId: companyConfig.liffId });
  const params = new URLSearchParams(window.location.search);

  params.set('navigateTo', 'home');

  liff.login({
    redirectUri: `${window.location.origin}/?${params.toString()}`,
  });
};

export const MailSignUpPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const navigateToSignIn = useCallback(() => {
    history.push(`${Routes.mailSignIn}${location.search}`);
  }, [history, location.search]);

  const companyId = query.get('companyId');
  const shopId = convertFirebaseIdToShopId(query.get('shopId') ?? '');

  useEffect(() => {
    if (!companyId) {
      history.replace(createErrorRoute(ErrorDisplayType.NO_PAGE));
    }
  }, [companyId, history]);

  return (
    <MailSignUp
      mailSignUp={(email, password) => mailSignUp(dispatch, history, email, password)}
      liffSignIn={() => liffSignIn(Number(companyId), shopId)}
      navigateToSignIn={navigateToSignIn}
    />
  );
};
