import React from 'react';
import styled from 'styled-components';
import NoImg from '../../../assets/images/no_image.svg';
import { Menu } from '../../../data/entity/menu';
import { Button, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import plusButton from '../../../assets/images/plusButton.svg';
import minusButton from '../../../assets/images/minusButton.svg';
import { ZIndexSettings } from '../../config/ZIndexSettings';
import { CurrentSelectedOptions } from './container';
import clsx from 'clsx';
import { Locale } from '../../../i18n';
import { Page } from '../Page';
import { fade } from '@material-ui/core/styles/colorManipulator';
import HighlightOffSharpIcon from '@material-ui/icons/Close';
import { TaxMethod } from '../../../data/entity/Shop';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'; // NOTE: 左上の戻るボタン

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 'calc(130px + env(safe-area-inset-bottom))',
    },
    title: {
      margin: '10px 0',
    },
    choiceButton: {
      fontSize: '1.4rem',
      background: '#f5f5f7',
      borderRadius: 3,
      color: '#5d5d5d',
      padding: '0 12px',
      height: '3.5rem',
      margin: '4px 8px',
      lineHeight: '3.5rem',
      textAlign: 'center',
      display: 'inline-block',
    },
    choiceButtonSelected: {
      background: '#f46f46',
      color: '#ffffff',
    },
    choiceButtonSoldOut: {
      background: '#dddddd',
    },
    textField: {
      padding: '0 20px',
      width: '100%',
    },
    textFieldInputSize: {
      fontSize: '1.4rem',
    },
    closeButton: {
      position: 'absolute',
      backgroundColor: fade('#fff', 0.7),
      borderRadius: '16px',
      margin: '16px',
      color: '#333',
      zIndex: 10000,
      top: 0,
      right: 0,
    },
    backIcon: {
      position: 'absolute',
      backgroundColor: fade('#fff', 0.7),
      borderRadius: '16px',
      margin: '16px',
      color: '#333',
      zIndex: 10000,
      top: 0,
      left: 0,
    },
  }),
);

const getSoldOutText = (locale: string) => (locale === Locale.Ja ? '(売切)' : 'Sold Out');

export const MenuDetailComponent = (props: IProps) => {
  const classes = useStyles();

  return (
    <Page>
      <HighlightOffSharpIcon
        onClick={props.onClickBack}
        fontSize={'large'}
        className={classes.closeButton}
      />
      {/*// NOTE: 左上に戻るボタン*/}
      {/*<ArrowBackIcon onClick={props.onClickBack} fontSize={'large'} className={classes.backIcon} />*/}
      <div className={classes.content}>
        <ImageWrapper src={props.menu.imageUrl || NoImg} />

        <TopContent>
          <div className={classes.title}>
            <Typography variant={'h1'}>{props.menu.name}</Typography>{' '}
          </div>
          {props.menu.description && (
            <Description>
              <Typography variant={'body1'}>{props.menu.description}</Typography>
            </Description>
          )}
        </TopContent>

        <SelectContent>
          {props.menu.options &&
            props.menu.options.map((option, optionIndex) => {
              return (
                <React.Fragment key={`option:${optionIndex}`}>
                  <Typography component={'span'} variant={'body1'}>
                    <ChoiceHeader>
                      {`${option.name}`}
                      {option.isRequired && (
                        <Typography component={'span'} variant={'body1'}>
                          <FormattedMessage id="self.organisms.menuDetail.requiredLabel" />
                        </Typography>
                      )}
                    </ChoiceHeader>
                  </Typography>
                  <ChoicesWrapper>
                    {option.choices.map((choice, index) => {
                      const selected =
                        props.activeOptions.filter(
                          ao =>
                            ao.optionId === option.optionId &&
                            ao.choices.filter(ac => ac.choiceId === choice.choiceId).length > 0,
                        ).length > 0;
                      return (
                        <Typography
                          className={clsx(classes.choiceButton, {
                            [classes.choiceButtonSelected]: selected,
                            [classes.choiceButtonSoldOut]: choice.isSoldout,
                          })}
                          key={`${optionIndex}:choice:${index}`}
                          onClick={() => {
                            if (!choice.isSoldout) {
                              props.clickChoice(option.optionId, choice.choiceId, choice.price);
                            }
                          }}
                        >
                          {`${choice.name}${choice.price === 0 ? '' : ' ¥' + choice.price}${
                            choice.isSoldout ? ` ${getSoldOutText(props.locale)}` : ''
                          }`}
                        </Typography>
                      );
                    })}
                  </ChoicesWrapper>
                </React.Fragment>
              );
            })}

          {props.showRemark && (
            <>
              <ChoiceHeader>
                <Typography variant={'body1'}>
                  <FormattedMessage id="self.organisms.menuDetail.remark" />
                </Typography>
              </ChoiceHeader>
              <TextField
                multiline
                rows="4"
                className={classes.textField}
                InputProps={{
                  classes: {
                    input: classes.textFieldInputSize,
                  },
                }}
                margin="normal"
                variant="outlined"
                onChange={e => props.onChangeRemark(e.target.value)}
              />
            </>
          )}
        </SelectContent>
      </div>

      <BottomWrapper>
        <QuantityForm>
          <QuantityButton src={minusButton} onClick={props.onClickMinus} />
          {props.quantity}
          <QuantityButton src={plusButton} onClick={props.onClickPlus} />
        </QuantityForm>
        <ButtonWrapper>
          <Button
            onClick={props.clickAddCartItem}
            color={'primary'}
            variant={'contained'}
            size={'small'}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 18px',
            }}
          >
            <Typography variant={'h3'}>
              <FormattedMessage id="self.organisms.menuDetail.addCart" />
            </Typography>

            <span style={{ fontSize: '1.6rem', letterSpacing: '5%' }}>
              ¥{props.price}
              {props.taxMethod === TaxMethod.Excluded && (
                <span style={{ fontSize: '1.0rem', letterSpacing: '5%' }}>
                  <FormattedMessage id="self.organisms.planDetail.withoutTax" />
                </span>
              )}
              {props.taxMethod === TaxMethod.NonTaxable && (
                <span style={{ fontSize: '1.0rem', letterSpacing: '5%' }}>
                  <FormattedMessage id="self.organisms.planDetail.nonTaxable" />
                </span>
              )}
            </span>
          </Button>
        </ButtonWrapper>
      </BottomWrapper>
    </Page>
  );
};

interface IProps {
  menu: Menu;
  clickCloseButton: () => void;
  remark: string;
  onChangeRemark: (remark: string) => void;
  onClickPlus: () => void;
  onClickMinus: () => void;
  quantity: number;
  clickAddCartItem: () => void;
  locale: string;
  price: number;
  activeOptions: CurrentSelectedOptions[];
  clickChoice: (optionId: number, choiceId: number, choicePrice: number) => void;
  onClickBack: () => void;
  showRemark: boolean;
  taxMethod?: TaxMethod;
}

const ImageWrapper = styled.img`
  height: 100vw;
  width: 100vw;
  overflow: hidden;
  object-fit: cover;
  background-color: transparent;
`;

const TopContent = styled.div`
  margin: 10px 20px;
`;

const Description = styled.div`
  color: #a0a0a0;
  font-size: 14px;
  margin-bottom: 10px;
`;

const SelectContent = styled.div``;

const ChoiceHeader = styled.div`
  display: flex;
  padding: 8px 20px;
  background-color: #f1f1f1;
`;
const ChoicesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 8px 10px;
`;

const BottomWrapper = styled.div`
  background-color: white;
  align-items: center;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  border-top: #e9e9e9 1px solid;
  z-index: ${ZIndexSettings.MODAL};
  padding-top: 10px;
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
  bottom: 0;
  width: 100%;
`;
const QuantityForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  max-width: 80px;
  min-width: 100px;
  flex-grow: 3;
`;
const QuantityButton = styled.img`
  width: 25px;
  height: 25px;
`;
const ButtonWrapper = styled.div`
  flex-grow: 1;
  max-width: 180px;
`;
