import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Page } from '../Page';

export default class FAQComponent extends React.Component {
  render() {
    return (
      <Page>
        <div>
          <Wrapper>
            <TitleText>
              <FormattedMessage id="self.pages.faq.firstQuestion" />
            </TitleText>
            <Text>
              <FormattedMessage id="self.pages.faq.firstAnswer" />
            </Text>
            {/* <TitleText>
            <FormattedMessage id="self.pages.faq.secondQuestion" />
          </TitleText>
          <Text>
            <FormattedMessage id="self.pages.faq.secondAnswer" />
          </Text> */}
            <TitleText>
              <FormattedMessage id="self.pages.faq.thirdQuestion" />
            </TitleText>
            <Text>
              <FormattedMessage id="self.pages.faq.topThirdAnswer" />
              <br />
              <FormattedMessage id="self.pages.faq.thirdAnswer" />
            </Text>
            <TitleText>
              <FormattedMessage id="self.pages.faq.fourthQuestion" />
            </TitleText>
            <Text>
              <FormattedMessage id="self.pages.faq.topFourthAnswer" />

              <br />
              <FormattedMessage id="self.pages.faq.fourthAnswer" />
            </Text>
            <TitleText>
              <FormattedMessage id="self.pages.faq.fifthQuestion" />
            </TitleText>
            <Text>
              <FormattedMessage id="self.pages.faq.fifthAnswer" />
            </Text>
            <TitleText>
              <FormattedMessage id="self.pages.faq.sixthQuestion" />
            </TitleText>
            <Text>
              <FormattedMessage id="self.pages.faq.sixthAnswer" />

              {/* お帰りの際に、1
            人の方にまとめて全ての注文分をお支払いいただきます。
            <br />
            現状、割り勘や個別会計などはできません。 */}
            </Text>
          </Wrapper>
        </div>
      </Page>
    );
  }
}

const Wrapper = styled.div`
  max-height: 100vh;
  width: 90%;
  margin: auto;
  margin-bottom: 40px;
  overflow: scroll;
  padding-bottom: 300px;
`;
const TitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 40px auto 12px;
`;
const Text = styled.div`
  font-size: 14px;
`;
