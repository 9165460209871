import { useHandleError } from './useHandleError';
import { useDispatch, useSelector } from 'react-redux';
import { startPlan } from '../../store/operations/startPlan';
import { selectLocale, selectTableInfo } from '../../store/selecter';
import { StartPlanRequest } from '../../data/api/request/StartPlanRequest';
import { useIntl } from 'react-intl';

export const useStartPlan = () => {
  const dispatch = useDispatch();
  const tableInfo = useSelector(selectTableInfo);
  const locale = useSelector(selectLocale);
  const { formatMessage: f } = useIntl();
  const loadingText = f({ id: 'self.ordering' });

  const errorHandler = useHandleError();
  return async (request: StartPlanRequest) =>
    dispatch(await startPlan(tableInfo, locale, loadingText, request, errorHandler));
};
