import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ZIndexSettings } from '../../../config/ZIndexSettings';
import { Button, Dialog, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

interface Props {
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  onHide: () => void;
  isShow: boolean;
}

export const ErrorModalComponent: React.FC<Props> = props => {
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    // props.isShow === falseのタイミングではtitle, messageがundefのため、
    // モーダル出現タイミングは管理されるが、消失タイミングはcomponentないで管理する
    if (props.isShow) {
      setShowing(true);
    }
  }, [props]);

  return (
    <Dialog
      closeAfterTransition={true}
      open={showing}
      // modal close時にmodal消失処理を行う
      onClose={() => setShowing(false)}
      onExited={props.onHide}
      style={{
        zIndex: ZIndexSettings.ERROR_MODAL,
      }}
    >
      <Content>
        <Wrapper>
          <TextSection>
            <Typography variant={'h1'}>{props.title}</Typography>
            <Typography variant={'h5'} style={{ marginTop: '16px', color: '#888888' }}>
              {props.message}
            </Typography>
          </TextSection>

          <div
            style={{
              margin: '0 30px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button size={'large'} onClick={() => setShowing(false)} color={'primary'}>
              <FormattedMessage id="self.molecules.alertModal.ok" />
            </Button>
          </div>
        </Wrapper>
      </Content>
    </Dialog>
  );
};

const Wrapper = styled.div`
  margin: 36px 10px 30px 10px;
  height: 100%;
`;

const TextSection = styled.div`
  text-align: center;
  margin: 30px;
`;

const Content = styled.div`
  max-width: 90vw;
`;
