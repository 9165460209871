import React, { useState } from 'react';
import styled from 'styled-components';
import { TableUserStateValue } from '../../../data/entity/TableUserState';
import { TableUser } from '../../../data/entity/TableUser';
import HomeHeader from './home_header/container';
import MenuHome from './menu_home/container';
import { CashPayingHome } from './cash_paying_home/container';
import { PaymentCompleted } from '../payment_completed/component';
import { CartConfirmButton } from './CartConfirmButton';
import CartSheet from './CartSheet';
import { Page } from '../Page';

interface Props {
  shopName: string | undefined;
  tableUser: TableUser;
  paymentType: string;
  onClickCartConfirmButton(): void;
  selfOrderCount: number;
  otherOrderCount: number;
  cartSheetIsOpen: boolean;
  onOpenCartSheet(): void;
  onCloseCartSheet(): void;
  showSubmitText: boolean;
  showCartConfirmButton: boolean;
  disableCartConfirmButton: boolean;
}

export const HomeComponent: React.FC<Props> = ({
  tableUser: { tableUserState },
  onClickCartConfirmButton,
  selfOrderCount,
  otherOrderCount,
  cartSheetIsOpen,
  onOpenCartSheet,
  onCloseCartSheet,
  showSubmitText,
  disableCartConfirmButton,
  showCartConfirmButton,
}: Props) => {
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);
  const [isShowStickyHeader, setIsShowStickyHeader] = useState(true);
  const offset = isShowStickyHeader ? stickyHeaderHeight : 0;

  return (
    <Page>
      <HomeWrapper>
        <HomeHeader
          setStickyHeaderHeight={setStickyHeaderHeight}
          isShowStickyHeader={isShowStickyHeader}
          setIsShowStickyHeader={setIsShowStickyHeader}
        />

        {tableUserState === TableUserStateValue.Inactive ? (
          <PaymentCompleted />
        ) : tableUserState === TableUserStateValue.RegisterCheckInProgress ? (
          <CashPayingHome />
        ) : (
          <MenuHome offset={offset} />
        )}

        <CartConfirmButton
          visible={showCartConfirmButton}
          onClick={disableCartConfirmButton ? onCloseCartSheet : onClickCartConfirmButton}
          disabled={disableCartConfirmButton}
          count={selfOrderCount + otherOrderCount}
          showSubmitText={showSubmitText}
        />
        <CartSheet open={cartSheetIsOpen} onOpen={onOpenCartSheet} onClose={onCloseCartSheet} />
      </HomeWrapper>
    </Page>
  );
};

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
