import { Locale } from '../i18n';

export function getLanguageCode(): Locale {
  const code =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    '';
  if (code.startsWith(Locale.En)) {
    return Locale.En;
  }
  return Locale.Ja;
}

export const hiraganaToKatakana = (string: string) =>
  string.replace(/[\u30a1-\u30f6]/g, match => String.fromCharCode(match.charCodeAt(0) - 0x60));
