import {
  updateIsLoading,
  updatePopupAction,
  closeModalAction,
} from '../../../../store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmCallStaffModalComponent } from './component';
import React, { Dispatch } from 'react';
import { FormattedMessage } from 'react-intl';
import { injector } from '../../../../data/injector';
import { Popup } from '../../../../store/app/state';
import { handleError } from '../../../../errors';
import { useHistory } from 'react-router';
import { selectLocale, selectTableInfo } from '../../../../store/selecter';
import { TableInfo } from '../../../../data/entity/TableInfo';

const callStaff = async (
  dispatch: Dispatch<any>,
  history: any,
  tableInfo: TableInfo | null,
  locale: string,
) => {
  if (!tableInfo) return;
  try {
    dispatch(updateIsLoading(<FormattedMessage id="self.connecting" />));
    await injector.apiClient.callStaff(
      await injector.firebaseClient.getIdToken(),
      tableInfo,
      locale,
    );
    dispatch(updateIsLoading(null));
    dispatch(updatePopupAction(Popup.CallStaffComplete));
    dispatch(closeModalAction());
  } catch (e) {
    await handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

const ConfirmCallStaffModal: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(selectLocale);
  const tableInfo = useSelector(selectTableInfo);

  return (
    <ConfirmCallStaffModalComponent
      onClose={() => dispatch(closeModalAction())}
      onClickConfirm={() => callStaff(dispatch, history, tableInfo, locale)}
    />
  );
};

export default ConfirmCallStaffModal;
