import Config from '../config/Config';
import ApiClient from './api/ApiClient';
import { FirebaseClient } from './firebase/FirebaseClient';

const apiClient = new ApiClient(Config.API);
const firebaseClient = new FirebaseClient();

export const injector = {
  apiClient,
  firebaseClient,
};
