import { ThunkResult } from '../thunk';
import { updateIsLoading, updatePopupAction } from '../app/actions';
import { selectTableInfo, selectPaymentType, selectLocale } from '../selecter';
import { PaymentType } from '../../data/entity/PaymentType';
import { Popup } from '../app/state';
import { Routes } from '../../presentation/routes';
import { handleError } from '../../errors';

export const postOrder = ({
  loadingMessage,
}: {
  loadingMessage: string;
}): ThunkResult<void> => async (
  dispatch,
  getState,
  { diniiApiClient, firebaseApiClient, history },
) => {
  const state = getState();
  const tableInfo = selectTableInfo(state);
  const paymentType = selectPaymentType(state);
  const locale = selectLocale(state);
  const idToken = await firebaseApiClient.getIdToken();

  if (!tableInfo) return;

  try {
    switch (paymentType) {
      case PaymentType.All:
        dispatch(updateIsLoading(loadingMessage));

        await diniiApiClient.postOrder(idToken, tableInfo, locale);

        dispatch(updatePopupAction(Popup.OrderComplete));
        history.push(Routes.orderCompleted);
        break;
      case PaymentType.Each:
        history.push(Routes.registerCard);
        break;
    }
  } catch (e) {
    handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};
