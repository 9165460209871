import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectLocale, selectCartItems } from '../../../../store/selecter';
import { HomeSnackbarComponent, Snackbar } from '../home_snackbar/component';

const CartSnackbar: React.FC = () => {
  const locale = useSelector(selectLocale);
  const cartItems = useSelector(selectCartItems);
  const { formatMessage: f } = useIntl();

  const items: Snackbar[] = useMemo(
    () =>
      cartItems.map(item => ({
        id: item.cartItemId,
        user: {
          id: item.userId,
          image: item.userProfileImage,
        },
        message: f(
          { id: 'self.pages.home.cartSnackbar.added' },
          { name: item.meta?.i18n[locale].name ?? '' },
        ),
        createdAt: item.createdAt,
      })),
    [cartItems, f, locale],
  );

  return <HomeSnackbarComponent items={items} />;
};

export default CartSnackbar;
