import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Modal from '../modal/container';
import { Modal as ModalType } from '../../../../store/app/state';

export default class ConfirmModal extends React.Component<IProps> {
  render() {
    return (
      <Modal name={this.props.name} isModal={this.props.isModal}>
        <Content>
          <Wrapper>
            <TextSection>
              <Typography variant={'h1'}>{this.props.title}</Typography>
              <Typography variant={'h5'} style={{ marginTop: '16px', color: '#888888' }}>
                {this.props.message}
              </Typography>
            </TextSection>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {this.props.onClickCancel && (
                <Button
                  style={{ margin: '4px' }}
                  onClick={this.props.onClickCancel}
                  color={'secondary'}
                  size={'large'}
                  disableRipple={true}
                >
                  <FormattedMessage id="self.molecules.confirmModal.cancel" />
                </Button>
              )}
              <Button
                style={{ margin: '4px' }}
                variant={'text'}
                size={'large'}
                onClick={this.props.onClickConfirm}
                color={'primary'}
              >
                <FormattedMessage id="self.molecules.confirmModal.yes" />
              </Button>
            </div>
          </Wrapper>
        </Content>
      </Modal>
    );
  }
}
interface IProps {
  name: ModalType;
  isModal?: boolean;
  title: JSX.Element | string | undefined;
  message: JSX.Element | string | undefined;
  onClickConfirm: () => void;
  onClickCancel?: () => void;
}

const Wrapper = styled.div`
  margin: 36px 10px 30px 10px;
  height: 100%;
`;

const TextSection = styled.div`
  text-align: center;
  margin: 30px;
`;

const Content = styled.div``;
