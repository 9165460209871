import React from 'react';
import { ReceiptComponent } from './component';
import { useSelector } from 'react-redux';
import { selectShop, selectTableUser } from '../../../store/selecter';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const Receipt: React.FC = () => {
  const tableUser = useSelector(selectTableUser);
  const shop = useSelector(selectShop);

  return tableUser.displayReceipts && tableUser.displayReceipts.length > 0 && shop ? (
    <ReceiptComponent
      receipt={tableUser.displayReceipts.slice().sort((a, b) => b.createdAt - a.createdAt)[0]}
      issuer={shop.issuer ?? ''}
      contactInfo={shop.contactInfo ?? ''}
    />
  ) : (
    <div style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
      <Typography variant={'body2'}>
        <FormattedMessage id="self.receipt.none" />
      </Typography>
    </div>
  );
};

export default Receipt;
