import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CircleIcon from '@material-ui/icons/AccessTimeOutlined';
import { Typography } from '@material-ui/core';
import { color } from '../../../assets/colors';
import { FormattedMessage } from 'react-intl';

const LastOrder: React.FC<Props> = ({ lastOrderText, plans }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timeout = setInterval(() => {
      setCurrentTime(new Date());
    }, 60 * 1000);

    return () => clearInterval(timeout);
  }, []);

  return (
    <LastOrderWrapper>
      <>
        <CircleIcon style={{ fontSize: '14px', marginRight: '4px' }} />
        <Typography variant={'body2'}>{lastOrderText}</Typography>
      </>
      <Typography component={'pre'} variant={'body2'} style={{ color: 'red' }}>
        {plans?.map(({ name, lastOrderTime }) => {
          if (!lastOrderTime) {
            return <p>{name}</p>;
          }

          const leftTimeInMinutes = Math.max(
            Math.ceil((Number(lastOrderTime) - Number(currentTime)) / 1000 / 60),
            0,
          );

          return (
            <p key={name}>
              <FormattedMessage
                id="self.molecules.lastOrder"
                key={name}
                values={{
                  name,
                  leftTimeInMinutes,
                }}
              />
            </p>
          );
        })}
      </Typography>
    </LastOrderWrapper>
  );
};

export default LastOrder;

interface Props {
  lastOrderText: string;
  plans?: LastOrderPlan[];
}

export interface LastOrderPlan {
  name: string;
  lastOrderTime: Date | null;
}

const LastOrderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${color.greyDark};
`;
