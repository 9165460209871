import { useDispatch, useSelector } from 'react-redux';
import React, { Dispatch } from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { RegisterCard } from './component';
import { TableInfo } from '../../../data/entity/TableInfo';
import { updateIsLoading, updatePopupAction } from '../../../store/app/actions';
import { handleError } from '../../../errors';
import { injector } from '../../../data/injector';
import { userActions } from '../../../store/ducks/user';
import {
  selectCards,
  selectIsLoading,
  selectLocale,
  selectPaymentType,
  selectTableInfo,
} from '../../../store/selecter';
import * as H from 'history';
import { Routes } from '../../routes';
import { Popup } from '../../../store/app/state';
import { PaymentType } from '../../../data/entity/PaymentType';
import { PaymentMethod } from '../../../data/entity/PaymentMethod';

const payWithExistCard = async (
  dispatch: Dispatch<any>,
  history: H.History,
  locale: string,
  tableInfo: TableInfo | null,
  paymentType: PaymentType,
) => {
  if (!tableInfo) return;
  try {
    dispatch(updateIsLoading(<FormattedMessage id="self.accounting" />));
    switch (paymentType) {
      case PaymentType.All:
        await injector.apiClient.payment(
          await injector.firebaseClient.getIdToken(),
          tableInfo,
          locale,
          PaymentMethod.Card,
        );
        break;
      case PaymentType.Each:
        await injector.apiClient.postOrder(
          await injector.firebaseClient.getIdToken(),
          tableInfo,
          locale,
        );
        break;
    }
    dispatch(updateIsLoading(null));
    dispatch(updatePopupAction(Popup.AccountComplete));
    history.replace(Routes.paymentCompleted);
  } catch (e) {
    await handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

const payWithNewCard = async (
  dispatch: Dispatch<any>,
  history: H.History,
  newCardToken: string,
  locale: string,
  tableInfo: TableInfo | null,
  paymentType: PaymentType,
) => {
  try {
    if (!tableInfo) return;
    dispatch(updateIsLoading(<FormattedMessage id="self.registeringCard" />));
    const cards = await injector.apiClient.sendCardToken(
      await injector.firebaseClient.getIdToken(),
      tableInfo,
      locale,
      newCardToken,
    );
    dispatch(updateIsLoading(<FormattedMessage id="self.accounting" />));
    switch (paymentType) {
      case PaymentType.All:
        await injector.apiClient.payment(
          await injector.firebaseClient.getIdToken(),
          tableInfo,
          locale,
          PaymentMethod.Card,
        );
        break;
      case PaymentType.Each:
        await injector.apiClient.postOrder(
          await injector.firebaseClient.getIdToken(),
          tableInfo,
          locale,
        );
        break;
    }
    dispatch(userActions.updateCards({ cards }));
    dispatch(updateIsLoading(null));
    dispatch(updatePopupAction(Popup.AccountComplete));
    history.replace(Routes.paymentCompleted);
  } catch (e) {
    await handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

const RegisterCardPage: React.FC = () => {
  const dispatch = useDispatch();
  const cardInfo = useSelector(selectCards);
  const history = useHistory();
  const tableInfo = useSelector(selectTableInfo);
  const locale = useSelector(selectLocale);
  const isLoading = useSelector(selectIsLoading);
  const paymentType = useSelector(selectPaymentType);

  return (
    <RegisterCard
      isLoading={isLoading}
      paymentType={paymentType}
      payWithExistCard={() => payWithExistCard(dispatch, history, locale, tableInfo, paymentType)}
      payWithNewCard={(token: string) =>
        payWithNewCard(dispatch, history, token, locale, tableInfo, paymentType)
      }
      cardInfo={cardInfo}
      handleError={async error => await handleError(error, dispatch, history)}
      addCommunication={() => {
        dispatch(updateIsLoading(<FormattedMessage id="self.connecting" />));
      }}
      deleteCommunication={() => {
        dispatch(updateIsLoading(null));
      }}
    />
  );
};

export default RegisterCardPage;
