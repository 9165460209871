import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsLoading } from '../../store/app/actions';
import { selectIsLoading } from '../../store/selecter';

export const useLoader = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const showLoader = useCallback(
    (text: string) => {
      dispatch(updateIsLoading(text));
    },
    [dispatch],
  );
  const dismissLoader = useCallback(() => {
    dispatch(updateIsLoading(null));
  }, [dispatch]);

  return {
    isLoading,
    showLoader,
    dismissLoader,
  };
};
