import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from '../../../../store/app/state';
import CompletePopup from '../complete_popup/container';

const CardRegisterCompletePopup: React.FC = () => (
  <CompletePopup
    name={Popup.CardRegistorComplete}
    text={<FormattedMessage id="self.organisms.cardRegisterCompletePopup.completedCardRegister" />}
  />
);

export default CardRegisterCompletePopup;
