import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from '../../../../store/app/state';
import CompletePopup from '../complete_popup/container';

const OrderCompletePopup: React.FC = () => (
  <CompletePopup
    name={Popup.OrderComplete}
    text={<FormattedMessage id="self.organisms.orderCompletePopup.completedOrder" />}
  />
);

export default OrderCompletePopup;
