import { useAppDispatch } from './useAppDispatch';
import { useSelector } from 'react-redux';
import { selectTableInfo } from '../../store/selecter';
import { operations } from '../../store/operations';
import { useEffect } from 'react';

export const useSubscribeShopMaintenanceInfo = () => {
  const dispatch = useAppDispatch();
  const { shopId } = useSelector(selectTableInfo);

  useEffect(() => {
    if (shopId) {
      return dispatch(operations.subscribeShopMaintenance({ shopId }));
    }
  }, [dispatch, shopId]);
};
