import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useMeasure } from 'react-use';
import { color } from '../../../../assets/colors';
import LastOrder, { LastOrderPlan } from '../../../components/molecules/last_order';
import SearchIcon from '@material-ui/icons/Search';
import HomeActions from '../home_actions/container';
import { LocaleSelect } from '../../../components/organisms/locale_select/container';
import { Routes } from '../../../routes';

interface Props {
  lastOrderText: string;
  plans: LastOrderPlan[];
  setStickyHeaderHeight: (height: number) => void;
  isShowStickyHeader: boolean;
  setIsShowStickyHeader: (isShow: boolean) => void;
  showSearchButton: boolean;
}

export const HomeHeaderComponent: React.FC<Props> = ({
  lastOrderText,
  plans,
  setStickyHeaderHeight,
  isShowStickyHeader,
  setIsShowStickyHeader,
  showSearchButton,
}) => {
  const [headerRef, { y, height }] = useMeasure<HTMLDivElement>();

  useEffect(() => setStickyHeaderHeight(y + height), [setStickyHeaderHeight, y, height]);

  const timer = useRef<number>();
  const scrollY = useRef(0);

  useEffect(() => {
    const onScroll = () => {
      const top = window.scrollY;
      const shouldShowStickyHeader = top < scrollY.current || top < height;
      if (isShowStickyHeader !== shouldShowStickyHeader) {
        setIsShowStickyHeader(shouldShowStickyHeader);
      }
      scrollY.current = top;
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isShowStickyHeader, setIsShowStickyHeader, height]);

  useEffect(() => {
    const onScroll = () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => setIsShowStickyHeader(true), 1000);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [setIsShowStickyHeader]);

  return (
    <HeaderWrapper ref={headerRef} isShowStickyHeader={isShowStickyHeader} data-test="home-header">
      <HomeActionsWrapper>
        <HomeActions />
        <div style={{ flex: 1 }} />
        {showSearchButton && (
          <Link to={Routes.search} style={{ color: '#999999', textDecoration: 'inherit' }}>
            <SearchIcon style={{ width: 24, height: 24, marginRight: 12 }} />
          </Link>
        )}
        <LocaleSelect />
      </HomeActionsWrapper>
      <LastOrderWrapper>
        <LastOrder lastOrderText={lastOrderText} plans={plans} />
      </LastOrderWrapper>
    </HeaderWrapper>
  );
};

export interface HomeAction {
  type: HomeActionType;
  canClick: boolean;
}

export enum HomeActionType {
  History = '履歴',
  CallStaff = '呼出',
  Account = '会計',
}

const HeaderWrapper = styled.div<{ isShowStickyHeader: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  transition: transform 0.5s;
  transform: ${({ isShowStickyHeader }) => `translateY(${isShowStickyHeader ? `0` : `-100`}%)`};
  will-change: transform;
  border-bottom: 1px solid ${color.greyLight};
`;

const HomeActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 10px;
`;

const LastOrderWrapper = styled.div`
  padding: 5px 12px 10px;
`;
