import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { selectLocale, selectOrders, selectShop } from '../../../store/selecter';
import { useHistory } from 'react-router';
import { Order } from '../../../data/entity/Order';
import { ComponentOrder, OrderCompleted } from './component';
import moment from 'moment-timezone';
import State from '../../../store/state';
import { Locale } from '../../../i18n';

const translateToComponentOrderItems = (
  lastOrderedAt: number,
  orderedItems: Order[],
  locale: Locale,
): ComponentOrder[] => {
  return orderedItems
    .filter(o => o.orderedAt >= lastOrderedAt - 10) // 注文時間のラグを考慮して-10している
    .map(o => {
      const time = moment(o.orderedAt * 1000)
        .tz('Asia/Tokyo')
        .format('HH:mm');
      return {
        name: o.meta?.i18n[locale]?.name ?? o.name ?? '',
        time: time,
        unitPrice: o.unitPriceWithOption,
        quantity: o.quantity,
        optionText: o.meta?.i18n[locale]?.optionText || '',
        userProfileImage: o.userProfileImage,
      };
    });
};

export const OrderCompletedPage: React.FC = () => {
  const history = useHistory();
  const shop = useSelector(selectShop);
  const locale = useSelector(selectLocale);
  const lastOrderedAt = useSelector((state: State) => state.tableUserState.lastOrderedAt);

  const orders = useSelector(selectOrders);

  const orderedItems = useMemo(() => {
    return translateToComponentOrderItems(
      lastOrderedAt,
      orders.slice().sort((a, b) => a.orderedAt - b.orderedAt),
      locale,
    );
  }, [lastOrderedAt, locale, orders]);

  return (
    <OrderCompleted
      locale={locale}
      paymentType={shop.paymentType!}
      orders={orderedItems}
      backToHome={() => history.goBack()}
      search={history.location.search}
    />
  );
};

export default OrderCompletedPage;
