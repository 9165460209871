import State from '../../../../store/state';
import { connect } from 'react-redux';
import { CashPayingHomeComponent } from './component';

const mapStateToProps = (state: State) => {
  const tableUser = state.tableUserState.tableUser;
  let servicePrice = 0;
  tableUser.feeAmounts.forEach(fee_amount => {
    servicePrice += fee_amount.amount;
  });
  const subTotal = tableUser.paidAmount - tableUser.taxAmount - servicePrice;
  const isPresentOnePersonPrice = tableUser.numPeople !== undefined;
  const onePersonPrice = Math.ceil(
    tableUser.numPeople && tableUser.numPeople > 0 ? tableUser.paidAmount / tableUser.numPeople : 0,
  );
  return {
    isPresentOnePersonPrice,
    subTotal,
    servicePrice,
    taxAmount: tableUser.taxAmount,
    paidAmount: tableUser.paidAmount,
    onePersonPrice,
    paymentMethod: tableUser.paymentMethod,
    tableName: tableUser.tableName,
  };
};

export const CashPayingHome = connect(mapStateToProps)(CashPayingHomeComponent);

export default CashPayingHome;
