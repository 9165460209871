import { createMuiTheme } from '@material-ui/core';

const diniiTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#f2c94c', // 基本の色よりも明るい色
      main: '#f46f46', // 基本の色
      contrastText: '#ffffff', // テキストの色
      dark: '#f46f46',
    },
    secondary: {
      main: '#C4C4C4',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '1.8rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '1.8rem',
      },
      h3: {
        fontSize: '1.6rem', //16px
      },
      h4: {
        fontSize: '1.6rem', //16px
        letterSpacing: '10%',
      },
      h5: {
        fontSize: '1.4rem', //14px
        fontWeight: 'bold',
        letterSpacing: '10%',
      },
      h6: {
        fontSize: '1.4rem', //14px
        fontWeight: 'bold',
      },
      body1: {
        fontSize: '1.4rem', //14px
      },
      body2: {
        fontSize: '1.2rem', //12px
      },
    },
    MuiButton: {
      root: {
        height: 44,
        fontSize: 18,
        whiteSpace: 'nowrap',
        minWidth: 130,
        textTransform: 'none',
      },
      textSizeLarge: {
        fontWeight: 'bold',
        height: 27,
        fontSize: '1.7rem',
        whiteSpace: 'nowrap',
        padding: 0,
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiSvgIcon: {
      fontSizeLarge: {
        width: 32,
        height: 32,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '10px 20px',
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        margin: 0,
        minHeight: 0,
      },
      content: {
        padding: 0,
        margin: 0,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
        margin: 0,
        minHeight: 0,
      },
    },
  },
});

const theme = diniiTheme;

export default theme;
