import { Locale } from '../../i18n';

/**
 *  OrderedItems
 */
export interface Order {
  orderId: number;
  categoryId: number;
  menuId: number;
  orderedAt: number;
  quantity: number;
  price: number;
  priceAll: number;
  name?: string;
  meta?: MetaOrderInfo;
  userName: string;
  userId: string;
  userProfileImage: string;
  unitPriceWithOption: number;
  orderType: OrderType;
}

export enum OrderType {
  Menu = 'menu',
  Plan = 'plan',
}

interface MetaOrderInfo {
  i18n: Record<Locale, OrderInfo>;
}

interface OrderInfo {
  name: string;
  optionText: string;
}
