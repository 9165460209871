import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Box, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { shadow } from '../../../styles/shadow';
import cartImage from '../../../../assets/images/cart.svg';

interface Props {
  visible: boolean;
  disabled: boolean;
  onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  count: number;
  showSubmitText: boolean;
}

export const CartConfirmButton: React.FC<Props> = ({
  visible,
  disabled,
  onClick,
  count,
  showSubmitText,
}) => (
  <ButtonWrapper visible={visible}>
    <StyledButton fullWidth variant={'contained'} color={'primary'} onClick={onClick}>
      <CartImage />

      <CountText>{count}</CountText>

      <FormattedMessage id="self.pages.home.confirmCartCount" />

      <Box flex={1} alignItems="center" justifyContent="center" style={{ display: 'flex' }}>
        {showSubmitText ? (
          disabled ? (
            <FormattedMessage id="self.pages.home.close" />
          ) : (
            <>
              <FormattedMessage id="self.pages.home.order" />
              <AnimatedSendIcon />
            </>
          )
        ) : (
          <FormattedMessage id="self.pages.home.confirmCart" />
        )}
      </Box>
    </StyledButton>
  </ButtonWrapper>
);

const ButtonWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 42px;
  width: 95%;
  right: 2.5%;
  z-index: 1800; /* same level as the cart modal */
  transition: bottom 1s ease-out;
  ${({ visible }) =>
    visible &&
    css`
      bottom: -64px;
    `}
`;

const StyledButton = styled(Button)`
  ${shadow(2, '#f46f46')};
`;

const CartImage = styled.img.attrs({ src: cartImage })`
  width: 26px;
  height: 20px;
`;

const CountText = styled.span`
  font-size: 1.8rem;
  margin-right: 4px;
  margin-left: 4px;
`;

const bounce = keyframes`
  to {
    animation-timing-function: ease-out;
    transform: translate3d(14px, 0, 0);
  }
}
`;

const AnimatedSendIcon = styled(SendIcon)`
  font-size: 1.8rem;
  margin-left: 18px;
  animation: ${bounce} 1s linear infinite;
`;
