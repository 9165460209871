import { ThunkResult } from '../thunk';
import { Unsubscribe } from 'firebase';
import { firebaseUserActions } from '../ducks/firebaseUser';

export const subscribeFirebaseAuth = (): ThunkResult<Unsubscribe> => (
  dispatch,
  getState,
  { auth },
) => {
  return auth.onAuthStateChanged(user => {
    dispatch(firebaseUserActions.updateUser({ user }));
  });
};
