import React, { Dispatch, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CategoryMenuAndPlan as MenuItemListCategoryMenuAndPlan,
  MenuAndPlanItem as MenuItemListMenuAndPlanItem,
  MenuHomeComponent,
} from './component';
import { CategoryMenu, CategoryPlan } from '../../../../data/entity/menu';
import { selectCategoryPlans, selectMenus, selectActivePlans } from '../../../../store/selecter';
import { handleError } from '../../../../errors';
import { Action } from '../../../../store/action';
import * as H from 'history';
import { ActivePlan } from '../../../../data/entity/ActivePlan';

const mergeCategoryPlansAndMenus = (
  categoryMenus: CategoryMenu[],
  categoryPlans: CategoryPlan[],
  activePlansInfo?: ActivePlan[],
) => {
  const categoryMenusAndPlans: MenuItemListCategoryMenuAndPlan[] = [];

  categoryPlans.forEach(categoryPlan => {
    const items: MenuItemListMenuAndPlanItem[] = [];
    categoryPlan.plans.forEach(plan => {
      let shouldPushPlan = true;
      if (activePlansInfo) {
        activePlansInfo.forEach(activePlanInfo => {
          if (activePlanInfo.planId === plan.planId) shouldPushPlan = false;
        });
      }
      if (!plan.isDisplay || !plan.available) shouldPushPlan = false;
      if (shouldPushPlan) {
        items.push({
          id: plan.planId,
          name: plan.name,
          imageUrl: plan.imageUrl,
          price: null, // NOTE: Plan本体にpriceは存在しない
          description: plan.description,
          isSoldOut: plan.isSoldout,
          isDisplay: plan.isDisplay,
          isPlan: true,
          descriptionLabelText: plan.descriptionLabelText,
          featured: plan.featured,
        });
      }
    });
    if (items.length !== 0) {
      categoryMenusAndPlans.push({
        id: categoryPlan.planCategoryId,
        name: categoryPlan.planCategoryName,
        items,
      });
    }
  });

  const activePlanIds = activePlansInfo?.map(plan => plan.planId) ?? [];

  categoryPlans
    .flatMap(categoryPlan => categoryPlan.plans)
    .filter(plan => activePlanIds.find(planId => planId === plan.planId))
    .flatMap(plan => plan.categoryMenus)
    .forEach(categoryMenu => {
      const items = categoryMenu.menus
        .filter(menu => menu.isDisplay)
        .map(menu => ({
          id: menu.menuId,
          name: menu.name,
          imageUrl: menu.imageUrl,
          price: menu.price,
          description: menu.description,
          isSoldOut: menu.isSoldout,
          isDisplay: menu.isDisplay,
          isPlan: false,
          featured: menu.featured,
        }));

      categoryMenusAndPlans.push({
        id: categoryMenu.menuCategoryId,
        name: categoryMenu.menuCategoryName,
        items,
      });
    });

  categoryMenus.forEach(categoryMenu => {
    const items = categoryMenu.menus
      .filter(menu => menu.isDisplay)
      .map(menu => ({
        id: menu.menuId,
        name: menu.name,
        imageUrl: menu.imageUrl,
        price: menu.price,
        description: menu.description,
        isSoldOut: menu.isSoldout,
        isDisplay: menu.isDisplay,
        isPlan: false,
        featured: menu.featured,
      }));

    categoryMenusAndPlans.push({
      id: categoryMenu.menuCategoryId,
      name: categoryMenu.menuCategoryName,
      items,
    });
  });

  return categoryMenusAndPlans;
};

const handleClickMenuItem = async (
  history: H.History,
  categoryId: number,
  menuId: number,
  dispatch: Dispatch<Action>,
) => {
  try {
    history.push(`/menu/${categoryId}/${menuId}`);
  } catch (e) {
    await handleError(e, dispatch, history);
  }
};

const handleClickPlanItem = async (
  history: H.History,
  planCategoryId: number,
  planId: number,
  dispatch: Dispatch<Action>,
) => {
  try {
    history.push(`/plan/${planCategoryId}/${planId}`);
  } catch (e) {
    await handleError(e, dispatch, history);
  }
};

interface Props {
  offset: number;
}

const MenuHome: React.FC<Props> = ({ offset }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryMenus = useSelector(selectMenus);
  const categoryPlans = useSelector(selectCategoryPlans);
  const activePlans = useSelector(selectActivePlans);
  const categoryMenusAndPlans = useMemo(() => {
    return mergeCategoryPlansAndMenus(categoryMenus, categoryPlans, activePlans);
  }, [activePlans, categoryMenus, categoryPlans]);

  const onClickMenuItem = (categoryId: number, menuId: number) =>
    handleClickMenuItem(history, categoryId, menuId, dispatch);
  const onClickPlanItem = (planCategoryId: number, planId: number) =>
    handleClickPlanItem(history, planCategoryId, planId, dispatch);

  return (
    <MenuHomeComponent
      offset={offset}
      categoryMenusAndPlans={categoryMenusAndPlans}
      onClickMenuItem={onClickMenuItem}
      onClickPlanItem={onClickPlanItem}
    />
  );
};

export default MenuHome;
