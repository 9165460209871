import React from 'react';
import styled from 'styled-components';
import NavigateToOrderHistory from '../../../../assets/images/navigateToOrderHistory.svg';
import HomeActionButton from '../home_action_button';

interface Props {
  homeActions: HomeAction[];
  onClickHomeAction: (action: HomeAction) => void;
}

export const HomeActionsComponent: React.FC<Props> = ({ homeActions, onClickHomeAction }) => {
  return (
    <HomeActionsWrapper>
      {homeActions.map((action, index) => (
        <HomeActionButton
          key={index}
          clickable={action.canClick}
          type={action.type}
          onClick={() => onClickHomeAction(action)}
        >
          <img src={NavigateToOrderHistory} />
        </HomeActionButton>
      ))}
    </HomeActionsWrapper>
  );
};

export interface HomeAction {
  type: HomeActionType;
  canClick: boolean;
}

export enum HomeActionType {
  History = '履歴',
  CallStaff = '呼出',
  Account = '会計',
}

const HomeActionsWrapper = styled.div`
  display: flex;

  & > button:not(:first-child) {
    margin-left: 8px;
  }
`;
