import { objToQueryString } from '../../util/url_util';
import { CustomError, Errors } from '../../errors';
import { CheckInResponse } from './response/CheckInResponse';
import { UserConnectResponse } from './response/UserConnectResponse';
import { StpCard } from '../entity/StpCard';
import { UpdateCartItemRequest } from './request/UpdateCartItemRequest';
import { AddCartRequest } from './request/AddCartRequest';
import { AppInfoResponse } from './response/AppInfoResponse';
import { TableInfo } from '../entity/TableInfo';
import { TableUser } from '../entity/TableUser';
import { StartPlanRequest } from './request/StartPlanRequest';
import * as Sentry from '@sentry/browser';
import { CategoryPlan } from '../entity/menu';
import { PaymentMethod } from '../entity/PaymentMethod';
const liff = window.liff;

enum DiniiApiError {
  userApiAfterValidCheckInTime = 'userApiAfterValidCheckInTime',
  userApiBeforeValidCheckInTime = 'userApiBeforeValidCheckInTime',
}

/* eslint @typescript-eslint/no-explicit-any: 0 */
const handleApiError = async (res: Response): Promise<any> => {
  if (res.ok) {
    return res;
  }
  const message = await res.json();
  switch (message.diniiErrorCode) {
    case DiniiApiError.userApiAfterValidCheckInTime:
    case DiniiApiError.userApiBeforeValidCheckInTime:
      throw new CustomError(Errors.NOT_OPEN);
    default:
      throw new CustomError(
        Errors.API_ERROR,
        message.title || 'Network error',
        message.message || '電波状況を確認いただき、QRコードを再読み込みしてください',
      );
  }
};

/* eslint @typescript-eslint/no-explicit-any: 0 */
const fetchWithErrorHandling = (url: string, options: any): Promise<any> =>
  fetch(url, options)
    .catch(_reason => {
      throw new CustomError(Errors.UNKNOWN_ERROR);
    })
    .then(handleApiError)
    .then(res => res.json());

const createAuthHeader = (idToken: string, tableInfo: TableInfo, locale: string) => {
  const baseHeader = {
    'x-auth-key': idToken,
    'Content-Type': 'application/json',
    'x-company-id': tableInfo.companyId,
    'x-shop-id': tableInfo.shopId,
    'x-table-user-id': tableInfo.tableUserId,
    'x-lang-code': locale,
  };
  let liffAccessToken = undefined as string | undefined;
  try {
    if (liff.isLoggedIn()) {
      liffAccessToken = liff.getAccessToken();
    }
  } catch (e) {
    Sentry.captureException(e);
    console.log(e);
  }
  if (liffAccessToken) {
    return { ...baseHeader, 'x-liff-access-token': liffAccessToken };
  } else {
    return baseHeader;
  }
};

export default class ApiClient {
  constructor(readonly url: string) {
    this.url = url;
    this.checkIn = this.checkIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.postOrder = this.postOrder.bind(this);
    this.sendCardToken = this.sendCardToken.bind(this);
    this.callStaff = this.callStaff.bind(this);
    this.payment = this.payment.bind(this);
    this.startPlan = this.startPlan.bind(this);
    this.updateTableUser = this.updateTableUser.bind(this);
    this.getLineFirebaseToken = this.getLineFirebaseToken.bind(this);
  }

  /**
   * auth
   */
  async signUp(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
  ): Promise<UserConnectResponse> {
    const option = {
      method: 'POST',
      headers: createAuthHeader(idToken, tableInfo, locale),
    };
    return await fetchWithErrorHandling(`${this.url}/auth/sign_up`, option);
  }

  async getLineFirebaseToken(
    companyId: number,
    lineAccessToken: string,
  ): Promise<{ customFirebaseToken: string }> {
    const option = {
      method: 'GET',
      headers: {
        'x-line-access-token': lineAccessToken,
        'x-company-id': companyId,
      },
    };
    return await fetchWithErrorHandling(`${this.url}/liff/sign_in`, option);
  }

  async checkIn(
    companyId: number,
    shopId: string,
    tableId: number,
    idToken: string,
    locale: string,
  ): Promise<CheckInResponse> {
    const queries = {
      companyId: companyId,
      shopId: shopId,
      tableId: tableId,
    };
    return await fetchWithErrorHandling(`${this.url}/auth/check_in?${objToQueryString(queries)}`, {
      headers: {
        'x-lang-code': locale,
        'x-auth-key': idToken,
      },
    });
  }

  /**
   * card
   */
  async sendCardToken(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
    token: string,
  ): Promise<StpCard[]> {
    return fetchWithErrorHandling(`${this.url}/cards`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: JSON.stringify({
        card: token,
      }),
    });
  }

  /**
   * userState app info
   */
  async getUserAppInfo(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
  ): Promise<AppInfoResponse> {
    const queries = {
      companyId: tableInfo.companyId,
      shopId: tableInfo.shopId,
      tableId: tableInfo.tableId,
    };
    return await fetchWithErrorHandling(`${this.url}/app_info/get?${objToQueryString(queries)}`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
    });
  }

  /**
   * table userState
   */
  async updateTableUser(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
    numPeople: number,
  ): Promise<TableUser> {
    return await fetchWithErrorHandling(`${this.url}/num_people`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: JSON.stringify({ numPeople }),
    });
  }

  /**
   * cart
   */
  async addCart(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
    addCartRequest: AddCartRequest,
  ): Promise<void> {
    await fetchWithErrorHandling(`${this.url}/carts`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: JSON.stringify(addCartRequest),
    });
  }

  async updateCart(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
    cartItemId: number,
    quantity: number,
  ): Promise<void> {
    const body: UpdateCartItemRequest = { quantity };

    await fetchWithErrorHandling(`${this.url}/carts/${cartItemId}/update`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  /**
   * actions
   */
  async postOrder(idToken: string, tableInfo: TableInfo, locale: string): Promise<void> {
    return await fetchWithErrorHandling(`${this.url}/carts/order`, {
      method: 'GET',
      headers: createAuthHeader(idToken, tableInfo, locale),
    });
  }

  async callStaff(idToken: string, tableInfo: TableInfo, locale: string): Promise<void> {
    return fetchWithErrorHandling(`${this.url}/call_staff`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'GET',
    });
  }

  async payment(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
    paymentMethod: PaymentMethod,
  ): Promise<void> {
    return fetchWithErrorHandling(`${this.url}/payment`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: JSON.stringify({
        paymentMethod,
      }),
    });
  }

  async startPlan(
    idToken: string,
    tableInfo: TableInfo,
    locale: string,
    request: StartPlanRequest,
  ): Promise<void> {
    return fetchWithErrorHandling(`${this.url}/plan/start_plan`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: JSON.stringify(request),
    });
  }

  async getPlans(idToken: string, tableInfo: TableInfo, locale: string): Promise<CategoryPlan[]> {
    return fetchWithErrorHandling(`${this.url}/plan/get_plan`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'GET',
    });
  }

  async confirmCheckIn(idToken: string, tableInfo: TableInfo, locale: string): Promise<void> {
    return fetchWithErrorHandling(`${this.url}/confirm_check_in`, {
      headers: createAuthHeader(idToken, tableInfo, locale),
      method: 'POST',
      body: '{}',
    });
  }

  async getCompanyConfig(companyId: number, shopId: string) {
    return fetchWithErrorHandling(`${this.url}/company_config/${companyId}/shops/${shopId}`, {
      headers: {},
      method: 'GET',
    });
  }
}
