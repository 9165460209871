import { useSubscribeCart } from './useSubscribeCart';
import { useSubscribeOrders } from './useSubscribeOrders';
import { useSubscribeTableUser } from './useSubscribeTableUser';
import { useSubscribeFirebaseAuth } from './useSubscribeFirebaseAuth';
import { useSubscribeShopMaintenanceInfo } from './useSubscribeShopMaintenanceInfo';

export const useInitialize = () => {
  useSubscribeCart();
  useSubscribeOrders();
  useSubscribeShopMaintenanceInfo();
  useSubscribeTableUser();
  useSubscribeFirebaseAuth();
};
