import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from '../../../../store/app/state';
import CompletePopup from '../complete_popup/container';

const ReceiptEmailRegisterCompletePopup: React.FC = () => (
  <CompletePopup
    name={Popup.ReceiptEmailRegisterComplete}
    text={<FormattedMessage id="self.organisms.receiptEmailRegisterCompletePopup.sendReceipt" />}
  />
);

export default ReceiptEmailRegisterCompletePopup;
