import React from 'react';
import styled from 'styled-components';
import MyAccountList from '../../../components/molecules/myaccount_list';
import { Profile } from '../../../../data/entity/Profile';

export class AccountUserInfoComponent extends React.Component<IProps> {
  render() {
    return (
      <div>
        <Wrapper>
          <MyAccountList
            title="ユーザ"
            data={this.props.firebaseUser || this.props.profile?.displayName || ''}
          />
        </Wrapper>
      </div>
    );
  }
}

interface IProps {
  profile: Profile | undefined;
  firebaseUser: string | null | undefined;
}

const Wrapper = styled.div`
  margin: 0 20px;
`;
