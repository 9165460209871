import { CheckInComponent } from './component';
import { injector } from '../../../data/injector';
import { createErrorRoute, ErrorDisplayType } from '../error_page/container';
import { userActions } from '../../../store/ducks/user';
import { Routes } from '../../routes';
import React, { Dispatch, useMemo } from 'react';
import { handleError } from '../../../errors';
import { getLanguageCode } from '../../../util/language';
import { shopActions } from '../../../store/ducks/shop';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as H from 'history';
import { Action } from '../../../store/action';
import { auth } from '../../../libs/firebaseApp';
import { tableInfoActions } from '../../../store/ducks/tableInfo';
import { menuActions } from '../../../store/ducks/menu';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { selectShouldInputNumberOfTableUser } from '../../../store/selecter';
import { CompanyConfig } from '../../../data/entity/CompanyConfig';
import { convertFirebaseIdToShopId } from '../../../libs/convertFirebaseIdToShopId';

const facebookLogin = async () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(function(result) {
      console.log('login success ', result);
    })
    .catch(function(error) {
      console.log(error);
    });
};

const emailLogin = async (email: string, password: string) => {
  try {
    const credential = await auth.signInWithEmailAndPassword(email, password);
    console.log('login success ', credential);
  } catch (e) {
    console.log(e);
  }
};

const signOut = async () => {
  await auth.signOut();
};

const checkInWithoutLiff = async (
  companyId: number | null,
  shopId: string | null,
  tableId: number | null,
  dispatch: Dispatch<Action>,
  history: H.History,
  shouldInputNumberOfTableUser: boolean,
) => {
  try {
    if (!companyId || !shopId || !tableId || !history || !dispatch) {
      history.push(createErrorRoute(ErrorDisplayType.NO_PAGE));
      return;
    }

    const defaultLanguage = getLanguageCode();
    dispatch(userActions.updateLocale({ locale: defaultLanguage }));

    const companyConfig = new CompanyConfig(
      await injector.apiClient.getCompanyConfig(companyId, shopId),
    );

    await liff.init({ liffId: companyConfig.liffId });

    const idToken = await injector.firebaseClient.getIdToken();

    const response = await injector.apiClient.checkIn(
      companyId,
      shopId,
      tableId,
      idToken,
      defaultLanguage,
    );
    dispatch(
      tableInfoActions.updateTableInfo({
        tableInfo: {
          liffId: companyConfig.liffId,
          companyId,
          shopId: response.shop.shopId,
          tableId,
          tableUserId: response.tableUserId,
        },
      }),
    );
    dispatch(shopActions.updateShop(response.shop));
    dispatch(menuActions.updateAllMenus({ categoryMenus: response.categoryMenus }));
    dispatch(menuActions.updateAllCategoryPlans({ categoryPlans: response.categoryPlans }));
    dispatch(userActions.updateCards({ cards: response.cards }));
    // NOTE: shouldInputNumberOfTableUser を判断するための情報はすでに取得済みなのか？
    if (shouldInputNumberOfTableUser) {
      history.replace(Routes.selectNumberOfTableUser);
    } else {
      history.replace(Routes.home);
    }
  } catch (e) {
    await handleError(e, dispatch, history);
  }
};

const OtherCheckIn: React.FC = () => {
  const history = useHistory();
  const shouldInputNumberOfTableUser = useSelector(selectShouldInputNumberOfTableUser);

  const { companyId, shopId, tableId } = useMemo<{
    companyId: string | null;
    shopId: string | null;
    tableId: string | null;
  }>(() => {
    const urlParams = new URLSearchParams(history.location.search);
    return {
      companyId: urlParams.get('companyId'),
      shopId: convertFirebaseIdToShopId(urlParams.get('shopId') ?? ''),
      tableId: urlParams.get('tableId'),
    };
  }, [history]);

  const dispatch = useDispatch();

  return (
    <CheckInComponent
      checkIn={() =>
        checkInWithoutLiff(
          Number(companyId),
          shopId,
          Number(tableId),
          dispatch,
          history,
          shouldInputNumberOfTableUser,
        )
      }
      logout={signOut}
      facebookLogin={facebookLogin}
      emailLogin={(email, password) => emailLogin(email, password)}
    />
  );
};

export default OtherCheckIn;
