import { useSelector } from 'react-redux';
import { OrderHistoryComponent, OrderHistoryOrderItem } from './component';
import React, { useCallback, useMemo } from 'react';
import {
  selectCategoryPlans,
  selectLocale,
  selectMenus,
  selectOrders,
  selectShop,
  selectTableInfo,
  selectTableUser,
} from '../../../store/selecter';
import { useHistory } from 'react-router';
import { Order } from '../../../data/entity/Order';
import moment from 'moment-timezone';
import { Locale } from '../../../i18n';
import ApiClient from '../../../data/api/ApiClient';
import { FirebaseClient } from '../../../data/firebase/FirebaseClient';
import { useIntl } from 'react-intl';
import { useLoader } from '../../hooks/useLoader';
import { useHandleError } from '../../hooks/useHandleError';
import { injector } from '../../../data/injector';
import { TableUserStateValue } from '../../../data/entity/TableUserState';

const translateToOrderHistoryOrderItems = (
  orderedItems: Order[],
  locale: Locale,
): OrderHistoryOrderItem[] => {
  return orderedItems.map(orderedItem => {
    const time = moment(orderedItem.orderedAt * 1000)
      .tz('Asia/Tokyo')
      .format('HH:mm');
    return {
      categoryId: orderedItem.categoryId,
      menuId: orderedItem.menuId,
      name: orderedItem.meta?.i18n[locale]?.name ?? orderedItem.name ?? '',
      time,
      unitPrice: orderedItem.unitPriceWithOption,
      quantity: orderedItem.quantity,
      optionText: orderedItem.meta?.i18n[locale]?.optionText || '',
      orderType: orderedItem.orderType,
    };
  });
};

const useIsMenuItem = (): {
  isMenuItem: (categoryId: number, menuId: number) => boolean;
} => {
  const menuCategories = useSelector(selectMenus);
  const planMenuCategories = useSelector(selectCategoryPlans);

  const isMenuItem = useCallback(
    (categoryId: number, menuId: number) => {
      const isMenuItem =
        menuCategories
          .find(c => c.menuCategoryId === categoryId)
          ?.menus?.find(m => m.menuId === menuId) !== undefined;
      const isPlanMenuItem =
        isMenuItem ||
        planMenuCategories
          .flatMap(pmc => pmc.plans.flatMap(p => p.categoryMenus))
          .find(c => c.menuCategoryId === categoryId)
          ?.menus?.find(m => m.menuId === menuId) !== undefined;
      return isMenuItem || isPlanMenuItem;
    },
    [menuCategories, planMenuCategories],
  );

  return { isMenuItem };
};

/**
 * APIClient は外に出しておく
 */
const useNumberOfTableUser = ({
  apiClient,
  firebaseClient,
}: {
  apiClient: ApiClient;
  firebaseClient: FirebaseClient;
}) => {
  const { formatMessage: f } = useIntl();
  const locale = useSelector(selectLocale);
  const tableInfo = useSelector(selectTableInfo);
  const { showLoader, isLoading, dismissLoader } = useLoader();
  const handleError = useHandleError();

  const tableUser = useSelector(selectTableUser);

  const setNumberOfTableUser = useCallback(
    (numPeople: number) => {
      (async () => {
        if (isLoading) return;
        if (!tableInfo) return;

        try {
          showLoader(f({ id: 'self.molecules.numPeopleSelector.updating' }));

          const idToken = await firebaseClient.getIdToken();

          await apiClient.updateTableUser(idToken, tableInfo, locale, numPeople);
        } catch (e) {
          await handleError(e);
        } finally {
          dismissLoader();
        }
      })();
    },
    [
      isLoading,
      tableInfo,
      showLoader,
      f,
      firebaseClient,
      apiClient,
      locale,
      handleError,
      dismissLoader,
    ],
  );

  return {
    numberOfTableUser: tableUser.numPeople,
    confirmNumberOfTableUser: setNumberOfTableUser,
  };
};

export const OrderHistory: React.FC = () => {
  const history = useHistory();
  const shop = useSelector(selectShop);
  const locale = useSelector(selectLocale);
  const tableUser = useSelector(selectTableUser);
  const orders = useSelector(selectOrders);

  const canReOrder = tableUser.tableUserState === TableUserStateValue.Active;

  const { isMenuItem } = useIsMenuItem();

  const { numberOfTableUser, confirmNumberOfTableUser } = useNumberOfTableUser({
    apiClient: injector.apiClient,
    firebaseClient: injector.firebaseClient,
  });

  const orderedItems = useMemo(() => {
    return translateToOrderHistoryOrderItems(orders, locale);
  }, [locale, orders]);

  const navigateToMenuDetail = (categoryId: number, menuId: number) => {
    history.push(`/menu/${categoryId}/${menuId}?backToHome=true`);
  };

  return (
    <OrderHistoryComponent
      isMenuItem={isMenuItem}
      numPeople={numberOfTableUser}
      setNumPeople={confirmNumberOfTableUser}
      paymentType={shop.paymentType!}
      orders={orderedItems}
      backToHome={() => history.goBack()}
      search={history.location.search}
      navigateToMenuDetail={navigateToMenuDetail}
      canReOrder={canReOrder}
    />
  );
};

export default OrderHistory;
