import React from 'react';
import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const MAXIMUM_NUMBER_OF_TABLE_USER = 16;

interface Props {
  numPeople: number | undefined;
  setNumPeople: (numPeople: number) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      fontSize: '1.8rem',
    },
    option: {
      fontSize: '1.8rem',
    },
  }),
);

const options = new Array(MAXIMUM_NUMBER_OF_TABLE_USER).fill(0).map((_, i) => i + 1);

export const SelectNumPeopleSpinner: React.FC<Props> = props => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.setNumPeople(event.target.value as number);
  };

  return (
    <Select className={classes.select} value={props.numPeople || ''} onChange={handleChange}>
      {options.map(option => (
        <MenuItem
          className={classes.option}
          value={option}
          key={`selectNumPeopleSpinnerCell:${option}`}
        >
          <FormattedMessage
            id="self.molecules.numPeopleSelector.selectPeople"
            values={{ name: option }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
