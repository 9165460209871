import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { TableUser } from '../../../data/entity/TableUser';
import { Page } from '../Page';

interface Props {
  disableOnlinePayment: boolean;
  onClickCashPayment: () => void;
  onClickCardPayment: () => void;
  tableUser: TableUser;
}

const SelectPaymentMethod: React.FC<Props> = props => {
  const servicePrice = props.tableUser.feeAmounts.reduce(
    (total, feeAmount) => total + feeAmount.amount,
    0,
  );
  const subTotal = props.tableUser.paidAmount - props.tableUser.taxAmount - servicePrice;

  return (
    <Page>
      <Wrapper>
        <PaymentContent>
          <PaymentColumn>
            <Typography variant={'h3'}>
              <FormattedMessage id="self.molecules.payment_total.subTotal" />
            </Typography>
            <Typography variant={'h3'}>￥{subTotal}</Typography>
          </PaymentColumn>
          <PaymentColumn>
            <Typography variant={'h3'}>
              <FormattedMessage id="self.molecules.payment_total.tax" />
            </Typography>
            <Typography variant={'h3'}>￥{props.tableUser.taxAmount}</Typography>
          </PaymentColumn>

          {servicePrice > 0 && (
            <PaymentColumn>
              <Typography variant="h3">
                <FormattedMessage id="self.molecules.payment_total.service" />
              </Typography>
              <Typography variant="h3">￥{servicePrice}</Typography>
            </PaymentColumn>
          )}

          <PaymentMiddleColumn>
            <FormattedMessage id="self.molecules.payment_total.total" />
            <div>￥{props.tableUser.paidAmount}</div>
          </PaymentMiddleColumn>
          <NumPeopleContent>
            {props.tableUser.numPeople && (
              <div style={{ marginLeft: '10px', fontSize: '16px' }}>
                ¥{Math.round(props.tableUser.paidAmount / props.tableUser.numPeople)}
                <FormattedMessage id="self.molecules.confirmChoosePeopleModal.perPeople" />
              </div>
            )}
          </NumPeopleContent>
        </PaymentContent>

        <Buttons>
          {!props.disableOnlinePayment && (
            <Button
              onClick={props.onClickCardPayment}
              style={{ width: '100%' }}
              variant="contained"
              color={'primary'}
            >
              <FormattedMessage id="self.molecules.confirmChoosePeopleModal.accountingWithCard" />
            </Button>
          )}
          <Button
            onClick={props.onClickCashPayment}
            style={{ width: '100%', marginTop: '10px' }}
            variant="outlined"
          >
            <FormattedMessage id="self.molecules.confirmChoosePeopleModal.accountingWithCash" />
          </Button>
        </Buttons>
        <ExplainWrapper variant={'body2'}>
          <FormattedMessage id="self.molecules.confirmChoosePeopleModal.description" />
        </ExplainWrapper>
        <ExplainWrapper variant={'body2'}>
          <FormattedMessage id="self.molecules.confirmChoosePeopleModal.remark" />
        </ExplainWrapper>
      </Wrapper>
    </Page>
  );
};

const Wrapper = styled.div`
  padding: 20px 10px;
  height: 100%;
`;

const PaymentContent = styled.div`
  padding: 20px;
`;

const PaymentColumn = styled.div`
  padding: 4px;
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PaymentMiddleColumn = styled.div`
  padding: 4px;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
`;

const NumPeopleContent = styled.div`
  padding: 4px;
  width: 100%;
  text-align: right;
`;

const ExplainWrapper = styled(Typography)`
  padding-left: 20px;
  padding-right: 20px;
`;

export default SelectPaymentMethod;
