import { closeModalAction } from '../../../../store/app/actions';
import { ConfirmPaymentModalComponent } from './component';
import React, { Dispatch } from 'react';
import { useHistory } from 'react-router';
import * as H from 'history';
import { Routes } from '../../../routes';
import { useDispatch } from 'react-redux';
import { Action } from '../../../../store/action';

const onClickConfirm = (dispatch: Dispatch<Action>, history: H.History) => {
  dispatch(closeModalAction());
  history.push(Routes.selectPaymentMethod);
};

const ConfirmPaymentModal: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <ConfirmPaymentModalComponent
      onClose={() => dispatch(closeModalAction())}
      onClickConfirm={() => onClickConfirm(dispatch, history)}
    />
  );
};

export default ConfirmPaymentModal;
