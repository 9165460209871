import React from 'react';
import styled from 'styled-components';
import MyAccountMenuElement from '../../../components/atoms/myaccount_menu_element';

const AccountMenu: React.FC = () => {
  return (
    <div>
      <Wrapper>
        <MyAccountMenuElement
          title="お問い合わせ"
          onClick={() =>
            window.open(
              'https://docs.google.com/forms/d/128wmVoTK1k2UirGAyy0R7PZDedOle1s-YUckz0TiRRY/edit',
              '_blank',
            )
          }
        />
        <MyAccountMenuElement
          title="利用規約"
          onClick={() => window.open('https://info.dinii.jp', '_blank')}
        />
        <MyAccountMenuElement
          title="プライバシーポリシー"
          onClick={() => window.open('https://info.dinii.jp/privacy.html', '_blank')}
        />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin: 0 20px;
`;

export default AccountMenu;
