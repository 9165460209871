import { ThunkResult } from '../thunk';
import { Unsubscribe } from 'firebase';
import { shopActions } from '../ducks/shop';
import { ShopMaintenanceInfo } from '../../data/entity/ShopMaintenance';

export const subscribeShopMaintenance = ({
  shopId,
}: {
  shopId: string;
}): ThunkResult<Unsubscribe> => (dispatch, getState, { shopMaintenanceRef }) => {
  return shopMaintenanceRef(shopId).onSnapshot(snapshot => {
    snapshot.docs.forEach(doc => {
      const shopMaintenance = doc.data() as ShopMaintenanceInfo;
      dispatch(shopActions.updateShop(shopMaintenance));
    });
  });
};
