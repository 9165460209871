import { PostPaymentsRequest } from './types/PostPaymentRequest';
import { LinePayApiResponseBase } from './types/base';

interface ConstructorOptions {
  baseURL: string;
}

export class LinePayApiClient {
  version = 'v3';
  baseURL: string;

  constructor(options: ConstructorOptions) {
    this.baseURL = options.baseURL;
  }

  async fetch<T extends LinePayApiResponseBase>(
    path: string,
    method: 'GET' | 'POST',
    body?: Record<string, any>,
  ) {
    const response = await fetch(`${this.baseURL}/${this.version}/${path}`, {
      method,
      body: body ? JSON.stringify(body) : undefined,
    });
    const data = (await response.json()) as T;

    if (data.returnCode !== '0000') {
      throw data;
    }

    return data;
  }

  async get<T extends LinePayApiResponseBase>(path: string) {
    return this.fetch<T>(path, 'GET');
  }

  async post<T extends LinePayApiResponseBase>(path: string, body: Record<string, any>) {
    return this.fetch<T>(path, 'POST', body);
  }

  postPaymentRequest(body: PostPaymentsRequest.Request) {
    return this.post<PostPaymentsRequest.Response>(`payments/request`, body);
  }
}
