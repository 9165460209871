import { ThunkResult } from '../thunk';
import { Unsubscribe } from 'firebase';
import { Order } from '../../data/entity/Order';
import { tableUserActions } from '../ducks/tableUser';

export const subscribeOrders = ({
  shopId,
  tableUserId,
}: {
  shopId: string;
  tableUserId: string;
}): ThunkResult<Unsubscribe> => (dispatch, getState, { ordersRef }) => {
  return ordersRef(shopId, tableUserId).onSnapshot(snapshot => {
    const orders = snapshot.docs.map(d => d.data() as Order);

    if (orders.length > 0) {
      dispatch(
        tableUserActions.updateLastOrderedAt({
          orderedAt: Math.max(...orders.map(o => o.orderedAt)),
        }),
      );
    }

    dispatch(
      tableUserActions.updateOrdersAction({
        orders: orders.slice().sort((a, b) => b.orderedAt - a.orderedAt),
      }),
    );
  });
};
