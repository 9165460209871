export class CompanyConfig {
  companyId: number | undefined = undefined;
  liffId = '';
  stripeOwnerId = '';
  versionCode = '';

  constructor(option?: Partial<CompanyConfig>) {
    Object.assign(this, option);
  }
}
