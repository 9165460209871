import { Modal, Popup } from './state';
import {
  AppActionTypes,
  DELETE_CURRENT_ERROR,
  UPDATE_CURRENT_ERROR,
  UPDATE_LOADING_FLAG_TEXT,
  UPDATE_MODAL,
  UPDATE_POPUP_MODAL,
} from './types';
import { Errors } from '../../errors';

export function updateIsLoading(loadingFlagText: string | null | JSX.Element): AppActionTypes {
  return {
    type: UPDATE_LOADING_FLAG_TEXT,
    loadingFlagText,
  };
}

export function updateModalAction(modal: Modal): AppActionTypes {
  return {
    type: UPDATE_MODAL,
    modal,
  };
}

export function closeModalAction(): AppActionTypes {
  return {
    type: UPDATE_MODAL,
    modal: Modal.None,
  };
}

export function updateCurrentErrorAction(
  error: Errors,
  title?: string,
  message?: string,
): AppActionTypes {
  return {
    type: UPDATE_CURRENT_ERROR,
    currentError: {
      type: error,
      title,
      message,
    },
  };
}

export function deleteCurrentErrorAction(): AppActionTypes {
  return {
    type: DELETE_CURRENT_ERROR,
  };
}

export function updatePopupAction(popup: Popup): AppActionTypes {
  return {
    type: UPDATE_POPUP_MODAL,
    popup,
  };
}

export function closePopupAction(): AppActionTypes {
  return {
    type: UPDATE_POPUP_MODAL,
    popup: Popup.None,
  };
}
