import { closeModalAction } from '../../../../store/app/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmCheckInModalComponent } from './component';
import React, { Dispatch } from 'react';
import { injector } from '../../../../data/injector';
import { useHistory } from 'react-router';
import { selectLocale, selectTableInfo } from '../../../../store/selecter';
import { TableInfo } from '../../../../data/entity/TableInfo';
import { userActions } from '../../../../store/ducks/user';

// NOTE: 実装の詳細はこちら https://dinii.kibe.la/notes/2355
const confirmCheckIn = async (
  dispatch: Dispatch<any>,
  history: any,
  tableInfo: TableInfo | null,
  locale: string,
) => {
  if (!tableInfo) return;
  try {
    // NOTE: 失敗してもあんまり問題ない処理なのでフィードバックは表示しない
    dispatch(closeModalAction());
    dispatch(userActions.receiveLineMessage());
    const token = await injector.firebaseClient.getIdToken();
    await injector.apiClient.confirmCheckIn(token, tableInfo, locale);
  } catch (e) {
    // NOTE: LIFF ログイン以外のログイン方法を利用していたりすると LINE メッセージの送信に失敗する。
    // そのときエラーが発生するが、通常のフローでも発生しうるエラーなので無視する。UXもそこまで悪くはならない。
  }
};

const ConfirmCheckInModal: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = useSelector(selectLocale);
  const tableInfo = useSelector(selectTableInfo);

  return (
    <ConfirmCheckInModalComponent
      onClose={() => dispatch(closeModalAction())}
      onClickConfirm={() => confirmCheckIn(dispatch, history, tableInfo, locale)}
    />
  );
};

export default ConfirmCheckInModal;
