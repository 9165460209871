import React, { useEffect } from 'react';
import WarningImg from '../assets/images/warning.svg';
import ModalTitle from './components/atoms/modal_title';
import styled from 'styled-components';
import { Switch, useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectTableInfo, selectIsLoggedIn, selectIsUserInitialized } from '../store/selecter';
import { FormattedMessage } from 'react-intl';
import { Page } from './pages/Page';
import { Routes } from './routes';
import { useQuery } from './hooks/useQuery';

export const AuthBoundarySwitch: React.FC = ({ children }) => {
  const tableInfo = useSelector(selectTableInfo);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isInitialized = useSelector(selectIsUserInitialized);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    if (!isInitialized) return;
    if (isLoggedIn) return;

    query.set('navigateTo', location.pathname);

    history.replace(`${Routes.checkIn}?${query.toString()}`);
  }, [history, isInitialized, isLoggedIn, location.pathname, location.search, query]);

  if (!isInitialized) {
    return <Page />;
  }

  if (!tableInfo) {
    return null;
  }

  if (!isLoggedIn) {
    return <NotLoggedInGuide />;
  }

  return <Switch>{children}</Switch>;
};

const NotLoggedInGuide = () => (
  <Page>
    <img src={WarningImg} style={{ width: '100%', height: '146px', objectFit: 'cover' }} />

    <TextSection>
      <ModalTitle>
        <FormattedMessage id="self.auth_boundary" />
      </ModalTitle>
      <Text>
        <FormattedMessage id="self.auth_boundary.please_check_network" />
      </Text>
    </TextSection>
  </Page>
);

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin: 18px auto;
`;

const TextSection = styled.div`
  text-align: center;
  margin: 32px auto;
`;
