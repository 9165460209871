import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryMenu, CategoryPlan } from '../../data/entity/menu';

export type MenuState = {
  categoryMenus: CategoryMenu[];
  categoryPlans: CategoryPlan[];
};

type State = MenuState;

export const initialState: State = {
  categoryMenus: [],
  categoryPlans: [],
};

interface UpdateAllMenusActionPayload {
  categoryMenus: CategoryMenu[];
}

interface UpdateAllCategoryPlansActionPayload {
  categoryPlans: CategoryPlan[];
}

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateAllMenus(state, action: PayloadAction<UpdateAllMenusActionPayload>) {
      state.categoryMenus = action.payload.categoryMenus;
    },
    updateAllCategoryPlans(state, action: PayloadAction<UpdateAllCategoryPlansActionPayload>) {
      state.categoryPlans = action.payload.categoryPlans;
    },
  },
});

export const menuActions = slice.actions;
export const menuReducer = slice.reducer;
