import { Routes } from '../../routes';
import { FormattedMessage } from 'react-intl';
import { Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import PaymentCompletedImage from '../../../assets/images/payment_completed_image.svg';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectPaymentType, selectTableUser } from '../../../store/selecter';
import { PaymentType } from '../../../data/entity/PaymentType';
import { Page } from '../Page';
import { shadow } from '../../styles/shadow';

const useStyles = makeStyles(() =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '20px',
    },
    detailSectionWrapper: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    detailSection: {
      width: '100%',
      marginBottom: '12px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    detailTitle: {},
    detailContent: {},
  }),
);

export const PaymentCompleted: React.FC = () => {
  const classes = useStyles();
  const closeModal = () => {};
  const history = useHistory();
  const tableUser = useSelector(selectTableUser);
  const paymentType = useSelector(selectPaymentType);

  return (
    <Page>
      <div>
        <TopImage src={PaymentCompletedImage} />
        <div className={classes.contentWrapper}>
          <TextWrapper>
            <Typography component={'pre'} variant={'h2'}>
              <FormattedMessage id={'self.order.complete.thankText'} />
            </Typography>
          </TextWrapper>

          {tableUser.activatedAt && (
            <Button
              onClick={() => {
                closeModal && closeModal();
                history.push(Routes.orderHistory);
              }}
              style={{
                marginBottom: '8px',
                width: '60%',
              }}
              color={'secondary'}
              variant={'outlined'}
            >
              <FormattedMessage id="self.order.complete.navigateToOrderHistory" />
            </Button>
          )}

          {(tableUser.displayReceipts?.length ?? 0) > 0 && (
            <Button
              onClick={() => {
                closeModal && closeModal();
                history.push(Routes.receipt);
              }}
              style={{
                width: '60%',
              }}
              color={'secondary'}
              variant={'outlined'}
            >
              <FormattedMessage id="self.order.complete.navigateToReceipt" />
            </Button>
          )}
        </div>
        {paymentType === PaymentType.Each && (
          <ButtonWrapper>
            <ShadowButton
              variant={'contained'}
              fullWidth={true}
              color={'primary'}
              onClick={history.goBack}
            >
              <FormattedMessage id="self.pages.order_history_page.backToHome" />
            </ShadowButton>
          </ButtonWrapper>
        )}
      </div>
    </Page>
  );
};

const TextWrapper = styled.div`
  font-weight: bold;
  margin: 20px;
`;

const TopImage = styled.img`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  left: 7.5%;
  width: 85%;
  text-align: center;
  bottom: calc(30px + env(safe-area-inset-bottom));
`;

const ShadowButton = styled(Button)`
  ${shadow(2)}
`;
