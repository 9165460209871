import { CustomError, Errors } from '../../errors';
import { actionLogsRef, auth } from '../../libs/firebaseApp';
import firebase, { firestore } from 'firebase/app';
import { TableInfo } from '../entity/TableInfo';
import { UserAction } from '../entity/UserAction';

export class FirebaseClient {
  async getUserId() {
    const user = auth.currentUser;
    if (user === null) {
      return null;
    }
    return user.uid;
  }

  currentUser(): firebase.User | null {
    return auth.currentUser;
  }

  async signInWithCustomToken(customToken: string): Promise<firebase.auth.UserCredential> {
    return await auth.signInWithCustomToken(customToken);
  }

  async updateUserProfile(displayName: string, photoURL: string): Promise<void> {
    const user = auth.currentUser;
    if (user) {
      await user.updateProfile({
        displayName,
        photoURL,
      });
    }
  }

  async isSignedIn(): Promise<boolean> {
    const user = await auth.currentUser;
    return user !== null && !user.isAnonymous;
  }

  async signOut() {
    const user = auth.currentUser;
    if (user !== null) {
      await auth.signOut();
    }
  }

  async signUpWithEmail(mail: string, password: string): Promise<firebase.User | null> {
    try {
      const credential = await auth.createUserWithEmailAndPassword(mail, password);
      if (credential) {
        return credential.user;
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use': {
          throw new CustomError(Errors.FIREBASE_SIGN_IN_MAIL_ALREADY_IN_USE);
        }
        case 'auth/invalid-email': {
          throw new CustomError(Errors.FIREBASE_SIGN_IN_MAIL_FORMAT_ERROR);
        }
        default:
          throw error;
      }
    }
    return null;
  }

  async signInWithEmail(mail: string, password: string): Promise<firebase.User | null> {
    try {
      const credential = await auth.signInWithEmailAndPassword(mail, password);
      if (credential) {
        return credential.user;
      }
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password': {
          throw new CustomError(Errors.FIREBASE_SIGN_IN_WRONG_PASSWORD);
        }
        case 'auth/userState-not-found': {
          throw new CustomError(Errors.FIREBASE_SIGN_IN_USER_NOT_FOUND);
        }
        default:
          throw new CustomError(Errors.FIREBASE_SIGN_IN_USER_NOT_FOUND);
      }
    }
    return null;
  }
  async getIdToken() {
    const user = auth.currentUser;
    if (user === null) {
      throw new CustomError(Errors.NOT_FIREBASE_AUTHORIZED);
    }
    return await user.getIdToken(true);
  }

  getUser() {
    return auth.currentUser;
  }

  async twitterSignInWithRedirect() {
    const user = auth.currentUser;
    if (user !== null) {
      await auth.signOut();
    }
    const provider = new firebase.auth.TwitterAuthProvider();
    await auth.signInWithRedirect(provider);
  }

  // NOTE: 現状使われていない
  sendActionLog = async (tableInfo: TableInfo, action: UserAction) => {
    try {
      const uid = auth.currentUser?.uid;
      if (uid) {
        await actionLogsRef(uid).add({
          companyId: tableInfo.companyId,
          shopId: tableInfo.shopId,
          actionType: action,
          timestamp: firestore.FieldValue.serverTimestamp(),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
}
