import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import { StpCard } from '../../../data/entity/StpCard';
import { AddCardFormComponent } from '../../components/organisms/add_card_form/component';
import { Page } from '../Page';
import { CardRegistered } from '../../components/molecules/card_registered';

interface Props {
  isLoading: boolean;
  cardInfo: StpCard[];
  payWithExistCard: () => void;
  payWithNewCard: (token: string) => void;
  paymentType: string;
  handleError: (error: any) => void;
  addCommunication: (id: string) => void;
  deleteCommunication: (id: string) => void;
}

export const RegisterCard: React.FC<Props> = (props: Props) => {
  const [expand, setExpand] = useState(false);

  const handleChange = (_: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpand(isExpanded);
  };

  return (
    <Page>
      <Wrapper>
        {/* when user already has card */}
        {props.cardInfo.length > 0 ? (
          <React.Fragment>
            <CardWrapper>
              <CardInfoWrapper>
                <CardRegistered cardInfo={props.cardInfo[0]} />
              </CardInfoWrapper>
              <ButtonWrapper>
                <Button
                  disabled={props.isLoading}
                  onClick={props.payWithExistCard}
                  style={{ width: '100%' }}
                  color={'primary'}
                  variant={'contained'}
                >
                  <FormattedMessage id="self.organisms.cardPayment.useThisCard" />
                </Button>
              </ButtonWrapper>
            </CardWrapper>
            <ExpansionPanel expanded={expand} onChange={handleChange('panel1')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {expand ? (
                  <div />
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: expand ? 'none' : 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant={'body1'}>
                      <FormattedMessage id="self.organisms.cardPayment.changeCard" />
                    </Typography>
                  </div>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <AddCardFormComponent
                  handleCardToken={token => props.payWithNewCard(token)}
                  handleCardTokenError={error => props.handleError(error)}
                  addCommunication={props.addCommunication}
                  deleteCommunication={props.deleteCommunication}
                  paymentType={props.paymentType}
                  shouldScroll={false}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* when user already has no card */}
            <NoCardTextWrapper>
              <FormattedMessage id="self.organisms.cardPayment.enterCard" />
            </NoCardTextWrapper>
            <AddCardFormComponent
              handleCardToken={token => props.payWithNewCard(token)}
              handleCardTokenError={error => props.handleError(error)}
              addCommunication={props.addCommunication}
              deleteCommunication={props.deleteCommunication}
              paymentType={props.paymentType}
              shouldScroll={false}
            />
          </React.Fragment>
        )}
      </Wrapper>
    </Page>
  );
};

const Wrapper = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 16px;
`;
const CardWrapper = styled.div`
  padding: 45px 45px 0px;
  margin-bottom: 12px;
`;

const CardInfoWrapper = styled.div`
  padding-bottom: 45px;
`;

const ButtonWrapper = styled.div`
  padding-bottom: 36px;
`;

const NoCardTextWrapper = styled.div`
  padding-top: 45px;
  padding-bottom: 15px;
  font-size: 14px;
  text-align: center;
`;
