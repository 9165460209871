import { AppState, initialState } from './state';
import {
  AppActionTypes,
  DELETE_CURRENT_ERROR,
  RESET_PLAN_CART,
  UPDATE_CURRENT_ERROR,
  UPDATE_LOADING_FLAG_TEXT,
  UPDATE_MODAL,
  UPDATE_POPUP_MODAL,
} from './types';

export default function(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case UPDATE_MODAL:
      return Object.assign({}, state, {
        modal: action.modal,
      });
    case UPDATE_CURRENT_ERROR: {
      return Object.assign({}, state, {
        error: action.currentError,
      });
    }
    case DELETE_CURRENT_ERROR: {
      return Object.assign({}, state, { error: undefined });
    }
    case UPDATE_LOADING_FLAG_TEXT: {
      return Object.assign({}, state, {
        loadingFlagText: action.loadingFlagText,
      });
    }
    case UPDATE_POPUP_MODAL: {
      return Object.assign({}, state, {
        popup: action.popup,
      });
    }
    case RESET_PLAN_CART: {
      return Object.assign({}, state, {
        planCart: undefined,
      });
    }
    default:
      return state;
  }
}
