import { TableInfo } from '../../data/entity/TableInfo';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UpdateTableInfoActionPayload {
  tableInfo: TableInfo;
}

export const tableInfoInitialState = {
  liffId: '-1',
  companyId: -1,
  shopId: '',
  tableId: -1,
  tableUserId: '',
};

const slice = createSlice({
  name: 'table_info',
  initialState: tableInfoInitialState,
  reducers: {
    updateTableInfo(state, action: PayloadAction<UpdateTableInfoActionPayload>) {
      Object.assign(state, action.payload.tableInfo);
    },
  },
});

export const tableInfoActions = slice.actions;
export const tableInfoReducer = slice.reducer;
