import React, { useState } from 'react';
import styled from 'styled-components';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Snackbar, SnackbarProps, makeStyles, createStyles } from '@material-ui/core';
import { useSnackbar } from './snackbar_provider';

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      transform: 'translateX(0)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      maxWidth: 280,
      minWidth: 280,
      marginTop: 4,
      marginBottom: 4,
    },
    contentRoot: {
      boxShadow: 'none',
      backgroundColor: 'rgba(50, 50, 50, 0.7)',
    },
    message: {
      width: '100%',
    },
  }),
);

export interface Props {
  id: string;
  anchorOrigin?: SnackbarProps['anchorOrigin'];
  hideDuration: SnackbarProps['autoHideDuration'];
  onExit?: () => void;
  children?: React.ReactNode;
}

const SnackbarItem: React.FC<Props> = props => {
  const { id, anchorOrigin, hideDuration, onExit, children } = props;
  const classes = useStyles();
  const { render, snackbars, deleteSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);

  return render(
    <Snackbar
      classes={{ root: classes.root }}
      open={snackbars.includes(id) && open}
      onClose={(_, reason) => {
        if (reason !== 'clickaway') {
          setOpen(false);
        }
      }}
      onExit={() => {
        onExit && onExit();
        deleteSnackbar(id);
      }}
      anchorOrigin={anchorOrigin}
      autoHideDuration={hideDuration}
    >
      <SnackbarContent
        classes={{ root: classes.contentRoot, message: classes.message }}
        aria-describedby="snackbar-message"
        message={<MessageContainer id="snackbar-message">{children}</MessageContainer>}
      />
    </Snackbar>,
  );
};

export default React.memo(SnackbarItem);
