import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartItem } from '../../data/entity/Cart';
import { TableUserStateValue } from '../../data/entity/TableUserState';
import { TableUser } from '../../data/entity/TableUser';
import { Order } from '../../data/entity/Order';

export type TableUserState = {
  tableUser: TableUser;
  cartItems: CartItem[];
  orders: Order[];
  lastOrderedAt: number;
};

interface UpdateTableUserActionPayload {
  tableUser: TableUser;
}

interface UpdateCartActionPayload {
  cartItems: CartItem[];
}

interface UpdateOrdersActionPayload {
  orders: Order[];
}

interface UpdateLastOrderedAtActionPayload {
  orderedAt: number;
}

export const initialState: TableUserState = {
  lastOrderedAt: 0,
  tableUser: {
    tableUserId: undefined,
    shopId: undefined,
    tableId: undefined,
    numPeople: undefined,
    tableUserState: TableUserStateValue.Seated,
    paidAmount: 0,
    feeAmounts: [],
    activatedAt: undefined,
    deactivatedAt: undefined,
    taxAmount: 0,
    taxRate: undefined,
    fees: [],
    isCalling: false,
    orderIds: [],
    paymentMethod: undefined,
    displayReceipts: [],
  },
  cartItems: [],
  orders: [],
};

const slice = createSlice({
  name: 'table_user',
  initialState,
  reducers: {
    updateTableUser(state, action: PayloadAction<UpdateTableUserActionPayload>) {
      Object.assign(state, action.payload);
    },
    updateCartAction(state, action: PayloadAction<UpdateCartActionPayload>) {
      state.cartItems = action.payload.cartItems;
    },
    updateOrdersAction(state, action: PayloadAction<UpdateOrdersActionPayload>) {
      state.orders = action.payload.orders;
    },
    updateLastOrderedAt(state, action: PayloadAction<UpdateLastOrderedAtActionPayload>) {
      state.lastOrderedAt = action.payload.orderedAt;
    },
  },
});

export const tableUserActions = slice.actions;
export const tableUserReducer = slice.reducer;
