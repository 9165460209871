import React from 'react';
import minusButton from '../../../../../assets/images/minusButton_gray.svg';
import plusButton from '../../../../../assets/images/plusButton_gray.svg';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  count: number;
  onClickIncreaseButton(): void;
  onClickDecreaseButton(): void;
}

export const AmountWithAction: React.FC<Props> = ({
  count,
  onClickIncreaseButton,
  onClickDecreaseButton,
}) => {
  return (
    <Container>
      <ActionImg src={minusButton} onClick={onClickDecreaseButton} />
      <Typography variant={'h3'}>{count}</Typography>
      <ActionImg src={plusButton} onClick={onClickIncreaseButton} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  padding-right: 8px;
  padding-left: 8px;
`;

const ActionImg = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
  margin-left: 8px;
`;
