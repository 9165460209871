import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StpCard } from '../../data/entity/StpCard';
import { Locale } from '../../i18n';
import { Profile } from '../../data/entity/Profile';

interface State {
  cards: StpCard[];
  profile?: Profile;
  locale: Locale;
  receivedLineMessage?: boolean;
}

export type UserState = State;

export const initialState: State = {
  cards: [],
  locale: Locale.Ja,
};

interface UpdateCardsActionPayload {
  cards: StpCard[];
}

interface UpdateProfileActionPayload {
  profile: Profile;
}

interface UpdateLocaleActionPayload {
  locale: Locale;
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateCards(state: State, action: PayloadAction<UpdateCardsActionPayload>) {
      state.cards = action.payload.cards;
    },
    updateProfile(state: State, action: PayloadAction<UpdateProfileActionPayload>) {
      state.profile = action.payload.profile;
    },
    updateLocale(state, action: PayloadAction<UpdateLocaleActionPayload>) {
      state.locale = action.payload.locale;
    },
    receiveLineMessage(state) {
      state.receivedLineMessage = true;
    },
  },
});

export const userActions = slice.actions;
export const userReducer = slice.reducer;
