import { connect } from 'react-redux';
import State from '../../../../store/state';
import { OrderHistoryHeaderComponent } from './component';
import { selectShop, selectTableUser } from '../../../../store/selecter';

const mapStateToProps = (state: State) => {
  const tableUser = selectTableUser(state);
  const shop = selectShop(state);
  return {
    taxAmount: tableUser.taxAmount,
    feeAmounts: tableUser.feeAmounts,
    paidAmount: tableUser.paidAmount,
    numPeople: tableUser.numPeople,
    paymentType: shop.paymentType,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export const OrderHistoryHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderHistoryHeaderComponent);
