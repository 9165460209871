import React from 'react';
import styled from 'styled-components';
import MoImage from '../../../assets/images/mo_image.svg';
import { FormattedMessage } from 'react-intl';
import { Page } from '../Page';

export const AboutMoPage = () => (
  <Page>
    <Wrapper>
      <TitleText>
        <FormattedMessage id="self.pages.aboutMo.titleText" />
      </TitleText>
      <Image src={MoImage} style={{ margin: '0 auto 40px' }} />
      <Text>
        <FormattedMessage id="self.pages.aboutMo.topText" />
        <br />
        <Bold>
          <FormattedMessage id="self.pages.aboutMo.boldText" />
        </Bold>
        <br />
        <FormattedMessage id="self.pages.aboutMo.secondText" />
        <br />
        <Bold>
          <FormattedMessage id="self.pages.aboutMo.smartPhone" />
        </Bold>
        <FormattedMessage id="self.pages.aboutMo.serviceText" />
        <br />
        <br />
        <FormattedMessage id="self.pages.aboutMo.leftText" />
        <Bold>
          <FormattedMessage id="self.pages.aboutMo.credit" />
        </Bold>
        <FormattedMessage id="self.pages.aboutMo.rightText" />
        <br />
        <FormattedMessage id="self.pages.aboutMo.passText" />
        <br />
        <br />
        <FormattedMessage id="self.pages.aboutMo.lastText" />
      </Text>
    </Wrapper>
  </Page>
);

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Image = styled.img`
  margin: 0 auto;
`;

const TitleText = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 32px auto;
  text-align: center;
`;
const Text = styled.div`
  font-size: 14px;
  text-align: center;
  line-height: 24px;
`;
const Bold = styled.span`
  font-weight: bold;
`;
