import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { scrollSpy, Link } from 'react-scroll';
import MenuItem from '../../../components/molecules/menu_item';
import Snackbar from '../../../components/molecules/snackbar_provider';
import CartSnackbar from '../cart_snackbar/container';
import OrderSnackbar from '../order_snackbar/container';
import { color } from '../../../../assets/colors';

const handleScrollSetActive = (e: string, categoryPane: HTMLDivElement | null, offset: number) => {
  const tab = document.getElementById(e.replace('menu', 'tab'));
  if (tab && categoryPane) {
    const { height, bottom } = tab.getBoundingClientRect();
    const scrollTo = (window.screen.height - height) / 2 - bottom + offset;
    categoryPane.scrollTop = categoryPane.scrollTop - scrollTo;
  }
};

interface Props {
  offset: number;
  categoryMenusAndPlans: CategoryMenuAndPlan[];
  onClickMenuItem: (categoryId: number, menuId: number) => void;
  onClickPlanItem: (planCategoryId: number, planId: number) => void;
}

export const MenuHomeComponent: React.FC<Props> = ({
  offset,
  categoryMenusAndPlans,
  onClickMenuItem,
  onClickPlanItem,
}) => {
  const categoryPaneRef = useRef<HTMLDivElement>(null);

  useEffect(() => scrollSpy.update(), [offset]);

  return (
    <MenuHomeWrapper>
      <CategoryPane ref={categoryPaneRef} top={offset}>
        {categoryMenusAndPlans.map(category => (
          <Tab
            id={`tab-${category.id}`}
            key={category.id}
            to={`menu-${category.id}`}
            activeClass="active-tab"
            spy={true}
            smooth={true}
            offset={-offset - 1}
            duration={400}
            isDynamic={false}
            onSetActive={(to: string) => handleScrollSetActive(to, categoryPaneRef.current, offset)}
          >
            <TabContent>{category.name}</TabContent>
          </Tab>
        ))}
      </CategoryPane>
      <MenuPane>
        {categoryMenusAndPlans.map(category => (
          <MenuSection id={`menu-${category.id}`} key={category.id}>
            <MenuHeader>{category.name}</MenuHeader>

            {category.items.map((item, idx) => {
              const featured = item.featured || idx === 0;

              return (
                <StyeldMenuItem
                  key={item.id}
                  name={item.name}
                  imageUrl={item.imageUrl || undefined}
                  isSoldOut={item.isSoldOut}
                  price={item.price ? item.price.toString() : null}
                  isPlan={item.isPlan}
                  description={item.description || ''}
                  onClick={() => {
                    if (item.isSoldOut) return;
                    if (item.isPlan) {
                      onClickPlanItem(category.id, item.id);
                    } else {
                      onClickMenuItem(category.id, item.id);
                    }
                  }}
                  type={featured ? 'large' : 'small'}
                  featured={featured}
                  descriptionLabelText={item.descriptionLabelText}
                />
              );
            })}
          </MenuSection>
        ))}
      </MenuPane>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        left={24}
        bottom={100}
        maxStack={3}
      >
        <CartSnackbar />
        <OrderSnackbar />
      </Snackbar>
    </MenuHomeWrapper>
  );
};

const MenuHomeWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const CategoryPane = styled.div<{ top: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f1f1f1;
  padding-bottom: 30px;
  overflow: scroll;
  position: sticky;
  top: ${({ top }) => `${top}px`};
  height: ${({ top }) => `calc(100vh - ${top}px)`};
  padding-bottom: 100px;
  transition: 0.5s;
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 7px;
  color: #999999;
  word-break: break-word;
  font-weight: bold;
`;

const cartButtonPadding = 100;

const Tab = styled(Link)`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.4rem;
  background-color: #f1f1f1;
  border-left: transparent 4px solid;

  &.active-tab {
    font-weight: bold;
    background-color: #ffffff;
    border-color: #f46f46;
    > ${TabContent} {
      color: #333333;
    }
  }
`;

const MenuPane = styled.div`
  flex: 4;
  padding: 0 8px;
  min-height: 100%;
  padding-bottom: ${cartButtonPadding}px;
`;

const MenuSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  &:last-child {
    /* 最後の要素もスクロールで飛べるようにするため */
    min-height: calc(100vh - ${cartButtonPadding}px);
  }
`;

const StyeldMenuItem = styled(MenuItem)<
  { featured?: boolean } & React.ComponentProps<typeof MenuItem>
>`
  padding: 8px;
  box-sizing: border-box;
  width: ${props => (props.featured ? '100%' : '50%')};
`;

const MenuHeader = styled.p`
  flex: 1;
  margin: 10px 30px 0 10px;
  font-size: 1.4rem;
  border-bottom: 1.5px solid #c4c4c4;
  color: ${color.greyDark};
`;

export interface CategoryMenuAndPlan {
  id: number;
  name: string;
  items: MenuAndPlanItem[];
}

export interface MenuAndPlanItem {
  id: number;
  name: string;
  imageUrl: string | null;
  price: number | null;
  description: string | null;
  isSoldOut: boolean;
  isDisplay: boolean;
  isPlan: boolean;
  featured?: boolean;
  descriptionLabelText?: string;
}
