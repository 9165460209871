import en from './en_US';
import ja from './ja_JP';
import { MessageKey } from './types';

export const translationMessages = {
  en,
  ja,
};

export enum Locale {
  Ja = 'ja',
  En = 'en',
}

export const chooseLocale = (locale: Locale) => {
  switch (locale) {
    case Locale.En:
      return translationMessages.en;
    case Locale.Ja:
      return translationMessages.ja;
    default:
      return translationMessages.ja;
  }
};

export const t = (id: MessageKey, locale: Locale) => {
  return chooseLocale(locale)[id];
};
