/* eslint sort-keys-fix/sort-keys-fix: "error" */
import { MessageKey } from './types';

const en_US: Record<MessageKey, string> = {
  'self.AppContainer.RedirectError': 'Redirecting Error',
  'self.AppContainer.RedirectProcessingFailed': 'Redirecting Failed',
  'self.AppContainer.checkingUserInfo': 'Checking User Information',
  'self.accounting': 'Checking...',
  'self.atoms.dropdown.selectPeople': '{name} P',
  'self.atoms.loginSmallText.agreement': '.',
  'self.atoms.loginSmallText.and': 'and ',
  'self.atoms.loginSmallText.privacyPolicy': 'Privacy Policy ',
  'self.atoms.loginSmallText.smallLoginText': 'By loggin in, you will agree with ',
  'self.atoms.loginSmallText.termsOfService': 'Terms ',
  'self.atoms.myaccount_menu_element.contactUs': 'Contact Us',
  'self.atoms.myaccount_menu_element.logout': 'Logout',
  'self.atoms.myaccount_menu_element.privacyPolicy': 'Privacy Policy',
  'self.atoms.myaccount_menu_element.termsOfService': 'Terms',
  'self.auth_boundary': 'Authorization error',
  'self.auth_boundary.please_check_network': 'please reload qr code',
  'self.cardAuthentication': 'Processing...',
  'self.checkAfterOrder': 'please check after orders',
  'self.checkingOrder': 'Confirming orders...',
  'self.connecting': 'Connecting...',
  'self.home.alreadyDoneCashPayment': 'You have already paid. Thank you for using.',
  'self.home.cartSheet.cartTitle': 'Submit orders',
  'self.infra.errors.stripeDeclineErrorMessage': 'Please use others',
  'self.infra.errors.stripeDeclineErrorTitle': 'Unable to use this card',
  'self.infra.errors.unexpectedErrorTitle': 'Unexpected Error.',
  'self.infra.firebaseClient.authError': 'Authentication error',
  'self.infra.firebaseClient.check': 'Please confirm again',
  'self.infra.firebaseClient.correctError': 'Please enter correct Email address',
  'self.infra.firebaseClient.login': 'Please log in',
  'self.infra.firebaseClient.mailFormatError': 'Incorrect Email address format',
  'self.infra.firebaseClient.passwordError': 'Incorrect Email address or password',
  'self.infra.firebaseClient.registeredError': 'Registered address',
  'self.infra.firebaseClient.userInfoError': 'Failed to get userState authentication information',
  'self.loading': 'Loading',
  'self.molecules.alertModal.ok': 'OK',
  'self.molecules.card_info.buttonText.all': 'Check with this card',
  'self.molecules.card_info.buttonText.each': 'Order With this card',
  'self.molecules.card_info.cardNumber': 'Card Number',
  'self.molecules.card_info.description':
    'When adding a debit card, a payment confirmation of ¥ 100 may be made. Payment confirmation will be refunded within 10 minutes.',
  'self.molecules.card_info.expirationDate': 'Expiration Date',
  'self.molecules.card_info.securityCode': 'Security Code',
  'self.molecules.confirmChoosePeopleModal.accountingWithCard': 'Pay Online',
  'self.molecules.confirmChoosePeopleModal.accountingWithCash': 'Pay otherwise',
  'self.molecules.confirmChoosePeopleModal.description':
    'Please select Pay Otherwise when you want to pay by cash or use any voucher.',
  'self.molecules.confirmChoosePeopleModal.inTheCase': 'if split, ¥',
  'self.molecules.confirmChoosePeopleModal.perPeople': ' / P',
  'self.molecules.confirmChoosePeopleModal.remark': 'Cashless return only with Pay Otherwise',
  'self.molecules.confirmChoosePeopleModal.topTitle': 'Checkout?',
  'self.molecules.confirmModal.cancel': 'Cancel',
  'self.molecules.confirmModal.yes': 'Yes',
  'self.molecules.home_action_button.account': 'Check',
  'self.molecules.home_action_button.callStaff': 'Call',
  'self.molecules.home_action_button.history': 'History',
  'self.molecules.lastOrder': '{name} {leftTimeInMinutes} mins left',
  'self.molecules.last_order.drink': 'Drink',
  'self.molecules.last_order.food': 'Food',
  'self.molecules.last_order.lastOrder': 'Last Order',
  'self.molecules.mailLoginButtonSet.signinButton': 'Login',
  'self.molecules.mailLoginButtonSet.signupButton': 'Signin',
  'self.molecules.mailLoginButtonSet.title': 'Signin / Login with email address',
  'self.molecules.mailLoginForm.buttonText': 'Login & Order',
  'self.molecules.mailLoginForm.description': 'Password must be at least 6 characters.',
  'self.molecules.mailLoginForm.noMailError': 'Please enter your e-mail address',
  'self.molecules.mailLoginForm.nopassError': 'Please enter your password',
  'self.molecules.mailSignupForm.bottomDescription': 'Set a password with at least 6 characters.',
  'self.molecules.mailSignupForm.leastPassError':
    'Please enter a password with at least 6 characters',
  'self.molecules.menuItem.soldOut': '【Sold Out】',
  'self.molecules.myaccount_list.mailAdrress': 'Email Adrress',
  'self.molecules.myaccount_list.name': 'User Name',
  'self.molecules.myaccount_list.phoneNumber': 'Phone Number',
  'self.molecules.myaccount_list.user': 'User',
  'self.molecules.numPeopleSelector.selectPeople': '{name} P',
  'self.molecules.numPeopleSelector.selectText': 'Select the number',
  'self.molecules.numPeopleSelector.updating': 'Updating number of people',
  'self.molecules.payment_total.perPerson': '(¥{name} / p)',
  'self.molecules.payment_total.service': 'Service Charge',
  'self.molecules.payment_total.subTotal': 'Subtotal',
  'self.molecules.payment_total.tax': 'Tax',
  'self.molecules.payment_total.total': 'Total',
  'self.molecules.planItem.minutes': '{name}mins',
  'self.molecules.planItem.startPlan': 'Start {name}',
  'self.molecules.pleaseCallStaffModal.message': 'Please call staff',
  'self.molecules.pleaseCallStaffModal.title': 'You can check at the table',
  'self.noOrder': 'No order',
  'self.noPlan': 'No plan selected',
  'self.order.complete.navigateToOrderHistory': 'Order history',
  'self.order.complete.navigateToReceipt': 'Receipt',
  'self.order.complete.thankText': 'Thank you for using.\nWe hope to see you soon!',
  'self.ordering': 'Ordering...',
  'self.organisms.accountCompletePopup.completedAccounting': 'Checkout Succeeded!',
  'self.organisms.accountMenu.other': 'Other',
  'self.organisms.accountUserInfo.userInfo': 'User Information',
  'self.organisms.addCard.checkCardInfo': 'Checking card information',
  'self.organisms.addCardFailed': 'Card registration failed',
  'self.organisms.addCardFailedText': 'Visa / MasterCard / American Express are supported',
  'self.organisms.add_card.cardInfo': 'Card Information',
  'self.organisms.add_card.text': 'Smoothly checkout by credit card ♪',
  'self.organisms.appBar.FAQ': 'FAQ',
  'self.organisms.appBar.aboutMobileOrder': 'What is Mobile Order?',
  'self.organisms.appBar.addCard': 'Add Card',
  'self.organisms.appBar.checkIn': 'Check In',
  'self.organisms.appBar.error': 'Error',
  'self.organisms.appBar.logout': 'Logout',
  'self.organisms.appBar.myAccount': 'MyAccount',
  'self.organisms.callStaffCompletePopup.completedCallStaff': 'Staff is coming! Just a sec pelease',
  'self.organisms.cardPayment.changeCard': 'Tap here to change cards',
  'self.organisms.cardPayment.enterCard': 'Please enter your card information',
  'self.organisms.cardPayment.useThisCard': 'Use this card',
  'self.organisms.cardRegisterCompletePopup.completedCardRegister': 'Added Card!',
  'self.organisms.cartButton.buttonText': 'Confirm the order',
  'self.organisms.cartConfirm.updateCart': 'Updating Cart',
  'self.organisms.cashPaymentCompleteModal.cashPaymentCompleted':
    'Please bring the slip to the cash register\nWe hope you will visit us sometime soon!',
  'self.organisms.chooseNumPeopleModal.enter': 'Check in',
  'self.organisms.chooseNumPeopleModal.enterDescription': 'You can order after the check in',
  'self.organisms.chooseNumPeopleModal.howMany': 'How many people？',
  'self.organisms.confirmCallStaffModal.callStaff': 'Call Staff',
  'self.organisms.confirmCallStaffModal.message': 'Calling staff?',
  'self.organisms.confirmCheckInModal.message': 'You can order menus after the check in',
  'self.organisms.confirmCheckInModal.title': 'Check in',
  'self.organisms.confirmPaymentModal.message': 'Paying?',
  'self.organisms.confirmPaymentModal.payment': 'Payment',
  'self.organisms.homePaymentSection.titleDescription': 'Please show this screen when checkout',
  'self.organisms.homePaymentSection.titleText.ja': ' ',
  'self.organisms.homeTopSection.checkoutMessage': 'Thank you for your order',
  'self.organisms.homeTopSection.checkoutTitle': 'Checked out',
  'self.organisms.homeTopSection.noOrder': 'No order',
  'self.organisms.homeTopSection.signinBeforeCheckMessage': ' ',
  'self.organisms.homeTopSection.signinBeforeCheckTitle': 'Please sign in before check',
  'self.organisms.logoutConfirm.cancelButton': 'Cancel',
  'self.organisms.logoutConfirm.description':
    'You might not want to log out for a comfortable dining experience next time',
  'self.organisms.logoutConfirm.firstCheck': 'Need to log in again next time',
  'self.organisms.logoutConfirm.logoutButton': 'Log out',
  'self.organisms.logoutConfirm.secondCheck':
    'The card information will be deleted & need to register again',
  'self.organisms.logoutConfirm.topTitle': 'Sure to logout?',
  'self.organisms.mailSignUp.createUser': 'Creating User',
  'self.organisms.mailSignin.title': 'Login with Email address',
  'self.organisms.mailSignup.here': 'HERE',
  'self.organisms.mailSignup.loginText': 'login? Tap ',
  'self.organisms.mailSignup.title': 'Signup with email address',
  'self.organisms.mailSignupForm.buttonText': 'Register & Order',
  'self.organisms.menuDetail.addCart': 'Add',
  'self.organisms.menuDetail.remark': 'Notes',
  'self.organisms.menuDetail.requiredLabel': '(Required)',
  'self.organisms.orderCompletePopup.completedOrder': 'Order Succeeded!',
  'self.organisms.paymentComleted.paidAmountText': 'Amount',
  'self.organisms.paymentComleted.paymentMethod': 'Pay by',
  'self.organisms.paymentComleted.receiptAddress': 'Send a receipt to',
  'self.organisms.paymentComleted.sendToThisEmailAddress': 'Send to This Email Address',
  'self.organisms.planDetail.availableTime': 'Available time',
  'self.organisms.planDetail.description':
    'After ordering this item, you will be able to select {name} menu',
  'self.organisms.planDetail.menuContentLabel': 'Menus',
  'self.organisms.planDetail.modalMessage': 'Are you starting the plan?',
  'self.organisms.planDetail.modalTitle': 'Plan',
  'self.organisms.planDetail.nonTaxable': 'Non taxable',
  'self.organisms.planDetail.people': 'Order for {name}',
  'self.organisms.planDetail.pleaseSelectAtLeastNItem':
    'Please select at least {minChoiceNum} items.',
  'self.organisms.planDetail.pleaseSelectAtMostNItem':
    'Please select at most {maxChoiceNum} items.',
  'self.organisms.planDetail.startText': 'Start',
  'self.organisms.planDetail.withoutTax': '(w/o tax)/p',
  'self.organisms.receiptEmailRegisterCompletePopup': "We'll send a receipt soon",
  'self.organisms.signIn.cantUseSMS': "Can't use SMS? Tap",
  'self.organisms.signIn.checkPhoneNumber': 'Checking Phone Number',
  'self.organisms.signIn.clickHere': ' HERE',
  'self.organisms.signInCompletePopup.completedLogin': 'Logged in!',
  'self.organisms.signOutCompletePopup.completedLogout': 'Logged out!',
  'self.pages.aboutMo.boldText': 'needless to call staff and tell them what you want. ',
  'self.pages.aboutMo.credit': ' ',
  'self.pages.aboutMo.lastText': "Let's dig in! ",
  'self.pages.aboutMo.leftText': 'No wait time, no checkout, just order and have fun. ',
  'self.pages.aboutMo.passText': ' ',
  'self.pages.aboutMo.rightText': ' ',
  'self.pages.aboutMo.secondText': 'With this service, you pay online by credt cards,',
  'self.pages.aboutMo.serviceText': ' ',
  'self.pages.aboutMo.smartPhone': 'which gives you a chance to skip checkout',
  'self.pages.aboutMo.titleText': 'Easily Submit Order with Your Mobile!',
  'self.pages.aboutMo.topText':
    'So called Mobile Order is a servive where you can easily place order with your mobile, ',
  'self.pages.add_card_page.cardInfo': 'Card Information',
  'self.pages.add_card_page.changeCard': 'Change Card?',
  'self.pages.add_card_page.registeredCardInformation': 'Card Information',
  'self.pages.cartConfirm.orderAdvice': 'You can order again in {leftMinutes} minutes.',
  'self.pages.cartConfirm.orderGuide': 'You can order once every {orderInterval} minutes.',
  'self.pages.checkIn.again': 'Enter again',
  'self.pages.checkIn.close': 'Close',
  'self.pages.default.authenticating': 'Authenticating...',
  'self.pages.drawer.FAQ': 'FAQ',
  'self.pages.drawer.account': 'Checkout',
  'self.pages.drawer.addCard': 'Card Registration',
  'self.pages.drawer.callStaff': 'Call Staff',
  'self.pages.drawer.mobileOrderDescription': 'What is Mobile Order?',
  'self.pages.drawer.myaccount': 'My Account',
  'self.pages.drawer.orderHistory': 'History',
  'self.pages.drawer.receipt': 'Receipt',
  'self.pages.errorPage.description': 'Please check the signal condition and reload the QR code',
  'self.pages.errorPage.linkError': 'Page not found',
  'self.pages.errorPage.notOpenError': 'Currently out of service',
  'self.pages.errorPage.unknownError': 'Error',
  'self.pages.faq.fifthAnswer': 'Please call staff and tell them that you have made a wrong order.',
  'self.pages.faq.fifthQuestion': 'Order wrong item?',
  'self.pages.faq.firstAnswer':
    'You might not able to log in in the other way you did last time.Please try logging in in the same way again.',
  'self.pages.faq.firstQuestion': 'Can’t Log in?',
  'self.pages.faq.fourthAnswer':
    'Please log out on My Account page, and try logging in in the same way before.',
  'self.pages.faq.fourthQuestion':
    'Card information has been deleted although you registered once.',
  'self.pages.faq.secondAnswer': 'Please reset the password: Tap “Reset Password” on log in page.',
  'self.pages.faq.secondQuestion': 'Forget the password?',
  'self.pages.faq.sixthAnswer':
    'The last person that taps Checkout button will pay all the orders that are submitted from the same QR code.If you want to split the bill, please push the Pay Otherwise button and make a payment at a register.',
  'self.pages.faq.sixthQuestion':
    'How will the payment be proceeded if you order with other people from the same QR code?',
  'self.pages.faq.thirdAnswer':
    'If you want to pay by cash or any other methods, select Pay Otherwise and make a payment at a register.',
  'self.pages.faq.thirdQuestion': 'Any other payment methods than credit / debit card?',
  'self.pages.faq.topFourthAnswer':
    'The way you logged in might be different from the one last time.',
  'self.pages.faq.topThirdAnswer':
    'Currently online payment is available only by credit / debit card.',
  'self.pages.history.numberOfPeople': 'Number of people',
  'self.pages.home.cartSnackbar.added': 'Added {name}',
  'self.pages.home.close': 'Close',
  'self.pages.home.confirmCart': 'Confirm orders',
  'self.pages.home.confirmCartCount': 'items',
  'self.pages.home.order': 'Order',
  'self.pages.home.orderSnackbar.ordered': 'Ordered {name}',
  'self.pages.launchScreen.checkIn': 'Checking in...',
  'self.pages.order_history_page.backToHome': 'Back Home',
  'self.pages.order_history_page.changeTheNumberOfpeople': 'Change the Number',
  'self.pages.search.app_bar.search': 'Search',
  'self.pages.search.menu.noResultsFound': 'No results found.',
  'self.pages.signIn.signIn': 'Sign in',
  'self.pages.signUp.alreadySignInText': 'for those who have already signed in',
  'self.pages.signUp.liffSignIn': 'Sign in with LINE',
  'self.pages.signUp.passwordLimitation': 'Your password must contain\n at least 6 characters',
  'self.pages.signUp.signIn': 'Sign in',
  'self.pages.signUp.signUp': 'Sign up',
  'self.paymentProcessing': 'Processing...',
  'self.receipt.download': 'Download receipt',
  'self.receipt.none': 'No receipt',
  'self.receipt.rawPriceLabel': 'Sub Total',
  'self.receipt.subTitle': 'As',
  'self.receipt.taxPriceLabel': 'Sales Tax',
  'self.receipt.title': 'Receipt',
  'self.registeringCard': 'Adding Card...',
  'self.reorder': 'Reorder',
  'self.signIn.title': 'Login with email',
  'self.signUp.title': 'Sign up with email',
  'self.updating': 'updating...',
  'self.word.soldout': 'Sold out',
};

export default en_US;
