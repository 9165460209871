import { useDispatch, useSelector } from 'react-redux';
import { HomeAction, HomeActionsComponent, HomeActionType } from './component';
import { Routes } from '../../../routes';
import { updateCurrentErrorAction, updateModalAction } from '../../../../store/app/actions';
import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import {
  selectPaymentType,
  selectTableInfo,
  selectTableUser,
  selectOrders,
  selectShop,
  InformativeShop,
} from '../../../../store/selecter';
import { ordersRef } from '../../../../libs/firebaseApp';
import { Errors } from '../../../../errors';
import { Action } from '../../../../store/action';
import * as H from 'history';
import { useHistory } from 'react-router';
import { Modal } from '../../../../store/app/state';
import { TableUser } from '../../../../data/entity/TableUser';
import { TableUserStateValue } from '../../../../data/entity/TableUserState';
import { PaymentType } from '../../../../data/entity/PaymentType';

const onClickHomeActions = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  tableUser: TableUser,
  action: HomeAction,
  orderNum: number,
  shop: InformativeShop,
) => {
  switch (action.type) {
    case HomeActionType.History:
      history.push(Routes.orderHistory);
      break;
    case HomeActionType.Account:
      if (action.canClick) {
        if (orderNum === 0) {
          dispatch(updateCurrentErrorAction(Errors.NO_ORDER));
        } else if (shop.disableOnlinePayment && shop.disableOfflinePayment) {
          dispatch(updateModalAction(Modal.PleaseCallStaff));
        } else {
          dispatch(updateModalAction(Modal.ConfirmPayment));
        }
      } else {
        if (tableUser.tableUserState === TableUserStateValue.Inactive) {
          dispatch(updateCurrentErrorAction(Errors.ALREADY_ACCOUNTED));
        } else {
          dispatch(updateCurrentErrorAction(Errors.NO_ORDER));
        }
      }
      break;
    case HomeActionType.CallStaff:
      dispatch(updateModalAction(Modal.ConfirmCallStaff));
      break;
  }
};

const HomeActions: React.FC = () => {
  const tableUser = useSelector(selectTableUser);
  const paymentType = useSelector(selectPaymentType);
  const [orderNum, setOrderNum] = useState(0);

  const tableInfo = useSelector(selectTableInfo);

  const orders = useSelector(selectOrders);

  const { disableCallStaff } = useSelector(selectShop);

  useEffect(() => {
    if (tableInfo && tableUser && tableUser.tableUserId) {
      return ordersRef(tableInfo.shopId, tableUser.tableUserId).onSnapshot(snapshot =>
        setOrderNum(snapshot.docs.length),
      );
    }
  }, [tableInfo, tableUser]);

  const homeActions = useMemo<HomeAction[]>(() => {
    const commonActions: HomeAction[] = [
      {
        type: HomeActionType.History,
        canClick: true,
      },
    ];

    if (paymentType === PaymentType.All) {
      if (!disableCallStaff) {
        commonActions.push({
          type: HomeActionType.CallStaff,
          canClick: true,
        });
      }

      const requirePayment = orders.some(order => order.priceAll > 0);

      if (requirePayment && tableUser.tableUserState === TableUserStateValue.Active) {
        commonActions.push({
          type: HomeActionType.Account,
          canClick: true,
        });
      }
    }

    return commonActions;
  }, [disableCallStaff, orders, paymentType, tableUser.tableUserState]);

  const history = useHistory();

  const dispatcher = useDispatch();
  const shop = useSelector(selectShop);

  return (
    <HomeActionsComponent
      homeActions={homeActions}
      onClickHomeAction={action =>
        onClickHomeActions(dispatcher, history, tableUser, action, orderNum, shop)
      }
    />
  );
};

export default HomeActions;
