import React from 'react';
import styled from 'styled-components';
import { ZIndexSettings } from '../config/ZIndexSettings';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner';

export default class DiniiLoader extends React.Component<IProps> {
  render() {
    return this.props.visible ? (
      <Wrapper>
        <Loader type="TailSpin" color="#fcfcfc" height={34} width={34} />
        <div>
          <br />
          {this.props.text || <FormattedMessage id="self.loading" />}
          <br />
        </div>
      </Wrapper>
    ) : (
      <div />
    );
  }
}

interface IProps {
  visible: boolean;
  text: string | null;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${ZIndexSettings.LOADER};
  height: 100%;
  width: 100%;
  display: -moz-box;
  display: -moz-flex;
  display: -webkit-box; //この辺が
  display: -webkit-flex; //Android2.3~とか
  display: -ms-flexbox; //IE9~用
  display: flex; //最新のブラウザのみであればflexだけでもよいかも
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
`;
