import { useDispatch, useSelector } from 'react-redux';
import React, { Dispatch } from 'react';
import {
  updateCurrentErrorAction,
  updateIsLoading,
  updatePopupAction,
} from '../../../store/app/actions';
import { FormattedMessage } from 'react-intl';
import { injector } from '../../../data/injector';
import { userActions } from '../../../store/ducks/user';
import { Popup } from '../../../store/app/state';
import { Errors, handleError } from '../../../errors';
import { useHistory } from 'react-router';
import {
  selectCards,
  selectLocale,
  selectPaymentType,
  selectTableInfo,
} from '../../../store/selecter';
import { AddCardComponent } from './component';
import { Action } from '../../../store/action';
import * as H from 'history';
import { TableInfo } from '../../../data/entity/TableInfo';
import { Locale } from '../../../i18n';

const addCardToken = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  cardToken: string,
  tableInfo: TableInfo | null,
  locale: string,
) => {
  if (!tableInfo) return;
  try {
    dispatch(updateIsLoading(<FormattedMessage id="self.cardAuthentication" />));
    const idToken = await injector.firebaseClient.getIdToken();
    const newCards = await injector.apiClient.sendCardToken(idToken, tableInfo, locale, cardToken);
    dispatch(userActions.updateCards({ cards: newCards }));
    dispatch(updateIsLoading(null));
    history.goBack();
    dispatch(updatePopupAction(Popup.CardRegistorComplete));
  } catch (e) {
    await handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

const handleCardRegisterError = (locale: string, dispatch: Dispatch<Action>) => {
  dispatch(updateCurrentErrorAction(Errors.CARD_REGISTER_ERROR));
};

const AddCardPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableInfo = useSelector(selectTableInfo);
  const paymentType = useSelector(selectPaymentType);
  const locale = useSelector(selectLocale);
  const cards = useSelector(selectCards);

  return (
    <AddCardComponent
      cards={cards}
      handleCardToken={token => addCardToken(dispatch, history, token, tableInfo, locale)}
      addCommunication={() =>
        dispatch(updateIsLoading(locale === Locale.En ? 'Registering..' : 'カード確認中…'))
      }
      deleteCommunication={() => dispatch(updateIsLoading(null))}
      handleCardTokenError={() => handleCardRegisterError(locale, dispatch)}
      paymentType={paymentType}
    />
  );
};

export default AddCardPage;
