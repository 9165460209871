import React from 'react';
import styled from 'styled-components';
import Popup from '../popup/container';
import { Popup as PopupType } from '../../../../store/app/state';
import DoneIcon from '@material-ui/icons/Done';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface Props {
  name: PopupType;
  text: string | JSX.Element;
  closePopup: () => void;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    icon: {
      width: '45px',
      height: '45px',
      marginTop: '26px',
      color: '#ffffff',
    },
  }),
);

export const CompletePopupComponent: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <Popup name={props.name}>
      <Wrapper onClick={props.closePopup}>
        <Toast>
          <DoneIcon className={classes.icon} />
          <Text>{props.text}</Text>
        </Toast>
      </Wrapper>
    </Popup>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Toast = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: black;
  opacity: 0.85;
  width: 120px;
  height: 120px;
`;

const Text = styled.div`
  text-align: center;
  color: white;
  font-weight: bold;
  line-height: 20px;
  margin-top: 3px;
`;
