import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale, selectTableInfo } from '../../../../store/selecter';
import { updateIsLoading } from '../../../../store/app/actions';
import { userActions } from '../../../../store/ducks/user';
import { handleError } from '../../../../errors';
import { injector } from '../../../../data/injector';
import { LocaleSelectComponent } from './component';
import { TableInfo } from '../../../../data/entity/TableInfo';
import { menuActions } from '../../../../store/ducks/menu';
import { Locale } from '../../../../i18n';

const updateLocale = async (dispatch: Dispatch<any>, tableInfo: TableInfo, locale: Locale) => {
  dispatch(userActions.updateLocale({ locale }));
  if (locale === Locale.Ja) {
    dispatch(updateIsLoading('日本語設定に切り替え中'));
  } else if (locale === Locale.En) {
    dispatch(updateIsLoading('Switching language'));
  } else {
    dispatch(updateIsLoading('Switching language'));
  }
  try {
    const { categoryMenus, categoryPlans } = await injector.apiClient.getUserAppInfo(
      await injector.firebaseClient.getIdToken(),
      tableInfo,
      locale,
    );
    dispatch(menuActions.updateAllMenus({ categoryMenus: categoryMenus }));
    dispatch(menuActions.updateAllCategoryPlans({ categoryPlans: categoryPlans }));
  } catch (e) {
    await handleError(e, dispatch);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

export const LocaleSelect: React.FC = () => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const tableInfo = useSelector(selectTableInfo);

  const localeList = [
    { text: '日本語', value: Locale.Ja },
    { text: 'English', value: Locale.En },
  ];

  return (
    <LocaleSelectComponent
      localeList={localeList}
      locale={locale}
      updateLocale={locale => updateLocale(dispatch, tableInfo, locale)}
    />
  );
};
