import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

export default class PaymentTotal extends React.Component<IProps> {
  render() {
    return (
      <div style={{ width: this.props.width }}>
        <Wrapper>
          <Text>
            <FormattedMessage id="self.molecules.payment_total.subTotal" />
          </Text>
          <Price>¥{this.props.subtotalPrice}</Price>
        </Wrapper>
        <Wrapper>
          <Text>
            <FormattedMessage id="self.molecules.payment_total.tax" />
          </Text>
          <Price>¥{this.props.taxPrice}</Price>
        </Wrapper>
        {this.props.servicePrice !== 0 && (
          <Wrapper>
            <Text>
              <FormattedMessage id="self.molecules.payment_total.service" />
            </Text>
            <Price>{`¥${this.props.servicePrice}`}</Price>
          </Wrapper>
        )}
        <Wrapper>
          <Total>
            <FormattedMessage id="self.molecules.payment_total.total" />
          </Total>
          <TotalPrice>{`¥${this.props.totalPrice}`}</TotalPrice>
        </Wrapper>
        {this.props.isPresentOnePersonPrice && (
          <Wrapper>
            <OnePersonPrice>
              <FormattedMessage
                id="self.molecules.payment_total.perPerson"
                values={{ name: this.props.onePersonPrice }}
              />
            </OnePersonPrice>
          </Wrapper>
        )}
      </div>
    );
  }
}
interface IProps {
  isPresentOnePersonPrice: boolean;
  subtotal: string;
  service: string;
  tax: string;
  total: string;
  subtotalPrice: number;
  servicePrice: number;
  taxPrice: number;
  totalPrice: number;
  onePersonPrice: number;
  width: string;
}

const Wrapper = styled.div`
  display: flex;
  margin: auto;
`;
const Text = styled.div`
  width: 50%;
  text-align: left;
  font-size: 16px;
`;
const Price = styled.div`
  width: 50%;
  text-align: right;
  font-size: 16px;
`;
const Total = styled.div`
  width: 50%;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  padding-top: 6px;
`;
const TotalPrice = styled.div`
  width: 50%;
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  padding-top: 6px;
`;
const OnePersonPrice = styled.div`
  width: 100%;
  text-align: right;
  font-size: 16px;
  padding-top: 6px;
`;
