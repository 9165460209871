import React, { Dispatch, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCategoryPlans,
  selectLocale,
  selectMenus,
  selectTableInfo,
  selectShop,
} from '../../../store/selecter';
import { MenuDetailComponent } from './component';
import { Menu } from '../../../data/entity/menu';
import { updateIsLoading } from '../../../store/app/actions';
import { injector } from '../../../data/injector';
import { handleError } from '../../../errors';
import { TableInfo } from '../../../data/entity/TableInfo';
import { Action } from '../../../store/action';
import * as H from 'history';
import { createErrorRoute, ErrorDisplayType } from '../error_page/container';
import { menuActions } from '../../../store/ducks/menu';
import { Locale } from '../../../i18n';
import { Routes } from '../../routes';

const handleClickAddCartItem = async (
  dispatch: Dispatch<Action>,
  history: H.History,
  cartInfo: MenuCartInfo,
  locale: string,
  tableInfo: TableInfo | null,
) => {
  if (!tableInfo) return;
  try {
    if (locale === Locale.En) {
      dispatch(updateIsLoading('Updating Cart'));
    } else {
      dispatch(updateIsLoading('カートを更新しています'));
    }

    await injector.apiClient.addCart(
      await injector.firebaseClient.getIdToken(),
      tableInfo,
      locale,
      {
        menuId: cartInfo.menuId,
        quantity: cartInfo.quantity,
        description: cartInfo.description,
        options: cartInfo.options.map(o => {
          return {
            optionId: o.optionId,
            choices: o.choices.map(c => {
              // FIXME: apply multi quantity choice
              return { choiceId: c.choiceId, quantity: 1 };
            }),
          };
        }),
      },
    );
    const params = new URLSearchParams(history.location.search);
    if (params.get('backToHome')) {
      history.push(Routes.home);
    } else {
      history.goBack();
    }
  } catch (e) {
    try {
      const response = await injector.apiClient.getUserAppInfo(
        await injector.firebaseClient.getIdToken(),
        tableInfo,
        locale,
      );
      dispatch(menuActions.updateAllMenus({ categoryMenus: response.categoryMenus }));
      dispatch(menuActions.updateAllCategoryPlans({ categoryPlans: response.categoryPlans }));
    } catch (e) {
      console.log(e);
    }
    await handleError(e, dispatch, history);
  } finally {
    dispatch(updateIsLoading(null));
  }
};

export interface MenuCartInfo {
  menuId: number;
  quantity: number;
  description: string;
  options: CurrentSelectedOptions[];
}

export interface CurrentSelectedOptions {
  minChoiceNum: number;
  maxChoiceNum: number;
  isRequired: boolean;
  optionId: number;
  choices: ChoiceSummary[];
}

interface ChoiceSummary {
  choiceId: number;
  price: number;
}

const MenuDetailPage: React.FC = () => {
  const history = useHistory();
  const { categoryId, menuId } = useParams();
  const menuCategories = useSelector(selectMenus);
  const planMenuCategories = useSelector(selectCategoryPlans);
  const locale = useSelector(selectLocale);
  const tableInfo = useSelector(selectTableInfo);
  const shop = useSelector(selectShop);
  const dispatch = useDispatch();

  const menu = useMemo<Menu | undefined>(() => {
    let ret: Menu | undefined = undefined;
    menuCategories.forEach(c => {
      c.menus.forEach(m => {
        if (c.menuCategoryId === Number(categoryId) && m.menuId === Number(menuId)) {
          ret = m;
        }
      });
    });
    planMenuCategories.forEach(pmc => {
      pmc.plans.forEach(p => {
        p.categoryMenus.forEach(cm => {
          cm.menus.forEach(m => {
            if (cm.menuCategoryId === Number(categoryId) && m.menuId === Number(menuId)) {
              ret = m;
            }
          });
        });
      });
    });
    if (!ret) {
      history.replace(createErrorRoute(ErrorDisplayType.NO_MENU));
    }
    return ret;
  }, [history, categoryId, menuId, menuCategories, planMenuCategories]);

  const [cart, setCart] = useState<MenuCartInfo>({
    menuId: Number(menuId) || -1,
    quantity: 1,
    description: '',
    options:
      menu && menu.options
        ? menu.options.map(o => {
            return {
              isRequired: o.isRequired,
              minChoiceNum: o.minChoiceNum,
              maxChoiceNum: o.maxChoiceNum,
              optionId: o.optionId,
              choices: o.choices.filter((_c, index) => o.defaultIndexes.includes(index)),
            };
          })
        : [],
  });

  const setChoice = (optionId: number, choiceId: number, choicePrice: number) => {
    const filterChoiceNumber = (list: ChoiceSummary[], max: number): ChoiceSummary[] => {
      return list.slice(Math.max(0, list.length - max), list.length);
    };
    setCart({
      ...cart,
      options: cart.options.map(o => {
        return o.optionId === optionId
          ? {
              optionId: optionId,
              minChoiceNum: o.minChoiceNum,
              maxChoiceNum: o.maxChoiceNum,
              isRequired: o.isRequired,
              choices: o.choices.map(c => c.choiceId).includes(choiceId)
                ? o.choices.length > o.minChoiceNum
                  ? o.choices.filter(c => c.choiceId !== choiceId)
                  : o.choices
                : filterChoiceNumber(
                    o.choices.concat([{ choiceId, price: choicePrice }]),
                    o.maxChoiceNum,
                  ),
            }
          : o;
      }),
    });
  };

  const price = useMemo(() => {
    if (!menu) return 0;
    let price = menu.price;
    cart.options.forEach(option => {
      option.choices.forEach(choice => {
        price += choice.price;
      });
    });
    return cart.quantity * price;
  }, [cart, menu]);

  return (
    <div>
      {categoryId && menuId && menu && (
        <MenuDetailComponent
          menu={menu}
          clickCloseButton={() => {
            history.goBack();
          }}
          remark={cart.description}
          onChangeRemark={remark =>
            setCart({
              ...cart,
              description: remark,
            })
          }
          quantity={cart.quantity}
          onClickPlus={() => {
            setCart({
              ...cart,
              quantity: cart.quantity + 1,
            });
          }}
          onClickMinus={() => {
            setCart({
              ...cart,
              quantity: Math.max(1, cart.quantity - 1),
            });
          }}
          clickAddCartItem={() =>
            handleClickAddCartItem(dispatch, history, cart, locale, tableInfo)
          }
          price={price}
          onClickBack={history.goBack}
          locale={locale}
          activeOptions={cart.options}
          clickChoice={setChoice}
          showRemark={Boolean(shop.enableRemark)}
          taxMethod={menu.taxMethod || shop.taxMethod}
        />
      )}
    </div>
  );
};

export default MenuDetailPage;
