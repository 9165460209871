import React from 'react';
import { ScrollRestoration } from '../ScrollRestoration';
import styled from 'styled-components';

export const Page: React.FC = ({ children }) => (
  <ScrollRestoration>
    <NotchMargin>{children}</NotchMargin>
  </ScrollRestoration>
);

const NotchMargin = styled.div`
  margin: 0 env(safe-area-inset-right, 20px) 0 env(safe-area-inset-left, 20px);
`;
