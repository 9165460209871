import React from 'react';
import styled from 'styled-components';
import CardCVC from '../../../assets/images/card_cvc.jpg';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { CustomError, Errors } from '../../../errors';

const uuidv4 = require('uuid/v4');

class CardInfo extends React.Component<IProps & ReactStripeElements.InjectedStripeProps, IState> {
  constructor(props: IProps & ReactStripeElements.InjectedStripeProps) {
    super(props);
    this.state = {
      completeCardNumber: false,
      completeCardExp: false,
      completeCardCvc: false,
      isStripeLoading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCardNumber = this.handleCardNumber.bind(this);
    this.handleCardExp = this.handleCardExp.bind(this);
    this.handleCardCvc = this.handleCardCvc.bind(this);
  }

  render() {
    return (
      <div>
        <div onSubmit={this.handleSubmit}>
          <ItemName>
            <FormattedMessage id="self.molecules.card_info.cardNumber" />
          </ItemName>
          <InputContainer>
            <CardNumberElement style={{ base: inputStyle }} onChange={this.handleCardNumber} />
          </InputContainer>
          <ItemName>
            <FormattedMessage id="self.molecules.card_info.expirationDate" />
          </ItemName>
          <InputContainer>
            <CardExpiryElement
              id="stp-card-exp"
              style={{ base: inputStyle }}
              onChange={this.handleCardExp}
            />
          </InputContainer>
          <ItemName>
            <FormattedMessage id="self.molecules.card_info.securityCode" />
          </ItemName>
          <CVCsection>
            <InputCvcContainer>
              <CardCVCElement
                id="stp-card-cvc"
                style={{ base: inputStyle }}
                onChange={this.handleCardCvc}
              />
            </InputCvcContainer>
            <Image
              src={CardCVC}
              style={{ margin: '0 0 10px 10px', width: '80px', height: '40px' }}
            />
          </CVCsection>
        </div>
        <SmallText>
          <FormattedMessage id="self.molecules.card_info.description" />
        </SmallText>
        <Button
          disabled={
            !(
              this.state.completeCardCvc &&
              this.state.completeCardExp &&
              this.state.completeCardNumber
            )
          }
          color={'primary'}
          variant={'contained'}
          onClick={this.handleSubmit}
          style={{ width: '100%', margin: '15px auto 0' }}
        >
          {this.props.paymentType === 'each' ? (
            <div>
              <FormattedMessage id="self.molecules.card_info.buttonText.each" />
            </div>
          ) : (
            <div>
              <FormattedMessage id="self.molecules.card_info.buttonText.all" />
            </div>
          )}
        </Button>
      </div>
    );
  }

  handleCardNumber(e: any) {
    if (e['complete']) {
      this.setState({ completeCardNumber: true });
      const next = document.getElementById('stp-card-exp');
      next!.getElementsByTagName('input')[0].focus();
    } else {
      this.setState({ completeCardNumber: false });
    }
  }

  handleCardExp(e: any) {
    if (e['complete']) {
      this.setState({ completeCardExp: true });
      const next = document.getElementById('stp-card-cvc');
      next!.getElementsByTagName('input')[0].focus();
    } else {
      this.setState({ completeCardExp: false });
    }
  }

  handleCardCvc(e: any) {
    if (e['complete']) {
      this.setState({ completeCardCvc: true });
    } else {
      this.setState({ completeCardCvc: false });
    }
  }

  handleSubmit() {
    if (
      !this.state.completeCardCvc ||
      !this.state.completeCardExp ||
      !this.state.completeCardNumber
    ) {
      return;
    }
    const communicationID = uuidv4();
    this.props.addCommunication(communicationID);
    this.props
      .stripe!.createToken()
      .then(result => {
        if (result.error) {
          this.props.deleteCommunication(communicationID);
          if (result.error.decline_code === 'card_not_supported') {
            this.props.handleCardTokenError(new CustomError(Errors.CARD_REGISTER_ERROR));
          } else {
            this.props.handleCardTokenError(result.error);
          }
        } else {
          this.props.handleCardToken(result.token!.id);
        }
      })
      .catch(e => {
        this.props.deleteCommunication(communicationID);
        this.props.handleCardTokenError(e);
      });
    return false;
  }
}

interface IProps {
  width: string;
  paymentType: string;
  handleCardToken: (token: string) => void;
  handleCardTokenError: (error: any) => void;
  addCommunication: (id: string) => void;
  deleteCommunication: (id: string) => void;
}

interface IState {
  completeCardNumber: boolean;
  completeCardExp: boolean;
  completeCardCvc: boolean;
  isStripeLoading: boolean;
}

const inputStyle = {
  color: '#32325d',
  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  fontSmoothing: 'antialiased',
  lineHeight: '25px',
  fontSize: '16px',
  '::placeholder': {
    color: '#aab7c4',
  },
};

const InputContainer = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  // width: 275px;
  padding: 8px 13px;
  margin-bottom: 12px;
`;

const InputCvcContainer = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  width: 195px;
  padding: 8px 13px;
  margin-bottom: 12px;
`;

const ItemName = styled.div`
  margin: 0 auto 5px;
`;

const SmallText = styled.div`
  font-size: 12px;
  color: #adadad;
  margin: 0 10px;
`;
const CVCsection = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  padding: 0 0 10px 20px;
`;

export default injectStripe(CardInfo);
